<template>
    <div class="row">

        <template v-if="$route.path === '/admin/articles'">
        <div class="row">
            <div class="col-xs-3 col-xs-offset-7">
                <router-link class="btn btn-primary" :to="'/admin/articles/new'"><i class="ti-plus"></i> Додати</router-link>
            </div>
        </div>
        <div class="col-md-6" v-for="article in articles" :key="article.id">
            <time-line type="simple">
                <time-line-item  class="timeline-inverted" :badge-type="article.tags===1 ? 'success' : 'danger'" badgeIcon="ti-gallery" >
                    <span slot="header" class="label label-success">{{ article.number }}</span>
                    <span slot="header" class="label label-danger">{{ getType(article.tags) }}</span>
                    <span slot="header" class="label label-default">{{ article.title }}</span>
                    <p slot="body" v-html="article.short">

                    </p>

                    <div slot="footer">
                        <div class="row">
<!--                        <div class="col-xs-12  col-md-2">-->
<!--                            <router-link :to="`/admin/reports/` + $route.params.report_id + `/chapters/`+ $route.params.chapter_id +'/questions/' + question.id + '/answeroptions'"  class="btn btn-default">-->
<!--                                <i class="ti-check-box"></i> Перегляд-->
<!--                            </router-link>-->
<!--                        </div>-->


                        <div class="col-xs-12 col-md-6">
                            <router-link :to="'/admin/articles/' + article.id" class="btn btn-wd btn-success btn-fill btn-rotate">
                                <i class="ti-pencil"></i> Редагувати
                            </router-link>
                        </div>
                        <div class="col-xs-12 col-md-6">
                          <label class="col-sm-1 control-label">Публікація</label>
                          <div class="col-sm-10">
                            <p class="category"> </p>
                            <p-switch v-model="article.public" @input="changePublic(article.id,  article.public)" type="primary"></p-switch>
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <button class="btn btn-wd btn-danger" @click="removeArticle(article.id)">
                                <i class="fa fa-times"></i> Видалити
                            </button>
                        </div>
                    </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    import TimeLine from './TimeLine.vue'
    import TimeLineItem from './TimeLineItem.vue'
    import PSwitch from '../../../../../components/UIComponents/Switch.vue'

    export default {
        name: "Articles",
        components: {
            TimeLine,
            TimeLineItem,
            PSwitch
        },
        data () {
            return {
                articles: null,
                loading: true,
                errored: false
            }
        },
        created() {

            this.getArticles()
        },
        watch: {
            $route: 'getArticles'
        },
        methods: {
            changePublic(id, publick){
              console.log(publick)
              this.axios
                  .patch('/api/v1/articles/'+ id , {article: {public: publick}} )
                  // .then(response => ( return null))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
            },
            getType(type){
               const types = [
                    {value: 1, label: 'Інформація'},
                   {value: 2, label: 'Безпека'}
                ]
                 if (types.filter( t => t.value === type )[0]) {
                   return  types.filter( t => t.value === type )[0].label}
                 else {
                   return  ''
                 }
            },
            getArticles(){
               if (this.$route.path === '/admin/articles') {
                   this.axios
                       .get('/api/v1/articles/')
                       .then(response => (this.articles = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
               }
            },
            removeArticle(id) {

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити матеріал?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.axios
                            .delete('/api/v1/articles/' + id )
                            .then(this.articles = this.articles.filter( r => r.id !== id ))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Матнріал успішно видалено.',
                                    'success'
                                )

                            ));
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Матеріал збережено :)',
                            'error'
                        )
                    }
                })
            }
        }
    }
</script>
<style>

</style>