<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
<!--                <div class="card-header">-->
<!--                    <h4 class="card-title">{{ report.name }}</h4>-->
<!--                </div>-->
                <div class="card-content">
                    <form class="form-horizontal"  @submit.prevent="toSave">
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Назва</label>
                                <div class="col-sm-10">
                                    <input type="text" v-model="dashboard.name" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Опис</label>
                                <div class="col-sm-10">
                                    <ckeditor v-model="dashboard.description" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </fieldset>
                        <div class="card-footer">
                            <div class="form-group">
                                <div class="col-md-3">
                                    <router-link :to="'/admin/incident_type/'+ $route.params.incident_type +'/sub_type'" class="btn btn-default">Назад</router-link>
                                </div>
                                <div class="col-md-9">
                                    <button type="submit" class="btn btn-fill btn-info">
                                        Зберегти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    // import PSwitch from '../../../../../components/UIComponents/Switch.vue'
    // import {Select, Option} from 'element-ui'
    export default {
        name: "IncidentSubType",
        components: {
            // PSwitch,
            [Option.name]: Option,
            // [Select.name]: Select,
        },
        created() {
            if (this.$route.params.id){
                this.getEvenType(this.$route.params.id)
            }
        },
        methods:{
          getEvenType(id){
                this.axios
                    .get('/api/v1/incidet_sub_types/'+ id )
                    .then(response => (this.dashboard = response.data))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            toSave(){

                    if (this.$route.params.id) {
                        this.axios
                            .patch('/api/v1/incidet_sub_types/'+ this.$route.params.id , {incidet_sub_type: this.dashboard} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: '/admin/incident_type/'+ this.$route.params.incident_type +'/sub_type' })
                            ));


                    } else {
                        this.axios
                            .post('/api/v1/incidet_sub_types/', {incidet_sub_type: this.dashboard} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: '/admin/incident_type/'+ this.$route.params.incident_type +'/sub_type' })
                            ));
                    }

            }
        } ,
        data () {
            return {

                dashboard: {
                    name: '',
                    description: '',
                    insident_type_id: this.$route.params.incident_type
                },
                loading: true,
                errored: false,
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        }
    }
</script>
<style>

</style>