<template>
    <div class="container-fluid" >
        <div class="col-md-12">
            <h1>Звіт: {{ report }}</h1>
        </div>
        <template v-if=" $route.name === 'Розділи'">
        <div class="row">
            <div class="col-xs-3 col-xs-offset-7">
                <router-link class="btn btn-primary" :to="`/admin/reports/`+ $route.params.report_id + `/chapters/new`"><i class="ti-plus"></i> Додати</router-link>
            </div>
        </div>
        <div class="col-md-6" v-for="chapter in chapters" :key="chapter.id">
            <time-line type="simple">
                <time-line-item class="timeline-inverted" badge-type="success" badgeIcon="ti-gallery">
                    <span slot="header" class="label label-success">{{ chapter.chapter_name }}</span>
                    <span slot="header" class="label label-danger">{{ chapter.number }}</span>
                    <p slot="body">
                          Максимальна Оцінка:  {{ chapter.max_rating }}
                    </p>

                    <div slot="footer">
                        <div class="row">
                        <div class="ol-xs-12 col-md-6 col-lg-4">
                            <router-link :to="`/admin/reports/` + $route.params.report_id + `/chapters/`+ chapter.id + `/questions`"  class="btn btn-sm btn-default">
                                <i class="ti-check-box"></i> Питання
                            </router-link>
                        </div>

<!--                        <div class="col-xs-12 col-md-2">-->
<!--                            <button class="btn btn-wd btn-warning btn-fill btn-magnify">-->
<!--                                <i class="ti-camera"></i> Копіювати-->
<!--                            </button>-->
<!--                        </div>-->
                        <div class="ol-xs-12 col-md-6 col-lg-4">
                            <router-link :to="`/admin/reports/` + $route.params.report_id + '/chapters/' + chapter.id" class="btn btn-sm btn-success btn-fill btn-rotate">
                                <i class="ti-pencil"></i> Редагувати
                            </router-link>
                        </div>
                        <div class="ol-xs-12 col-md-6 col-lg-4">
                            <button class="btn btn-sm btn-danger" @click="removeChapter(chapter.id)">
                                <i class="fa fa-times"></i> Видалити
                            </button>
                        </div>
                    </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    import TimeLine from './TimeLine.vue'
    import TimeLineItem from './TimeLineItem.vue'
    export default {
        name: "Chapters",
        components: {
            TimeLine,
            TimeLineItem },
        data () {
            return {
                chapters: null,
                report: null,
                loading: true,
                errored: false
            }
        },
        created() {
            this.getReport()
            this.getChapters()
        },
        watch: {
            $route: ['getReport', 'getChapters']
        },
        methods: {
            getReport(){
                this.axios
                    .get("/api/v1/reports/" + this.$route.params.report_id )
                    .then(response => (this.report = response.data.name))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch("logout")
                            } else {
                                console.log(error);
                                this.errored = true;
                            }
                        }
                    })
                    .finally(() => (this.loading = false));
                console.log('Call Api getReport')
            },
            getChapters(){
               if (this.$route.name === 'Розділи') {
                   this.axios
                       .get('/api/v1/reports/'+ this.$route.params.report_id + "/chapters")
                       .then(response => (this.chapters = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
                   console.log('Call Api getChapters')
               }
            },
            removeChapter(id) {

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити розділ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.axios
                            .delete('/api/v1/reports/'+ this.$route.params.report_id + "/chapters/" + id )
                            .then(this.chapters = this.chapters.filter( r => r.id !== id ))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    if (error.response && error.response.status === 401) {
                                        this.$store.dispatch("logout")
                                    } else {
                                        if (error.response && error.response.status === 401) {
                                            this.$store.dispatch("logout")
                                        } else {
                                            console.log(error);
                                            this.errored = true;
                                        }
                                    }
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Розділ успішно видалено.',
                                    'success'
                                )

                            ));
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Розділ збережено :)',
                            'error'
                        )
                    }
                })
            }
        }
    }
</script>
<style>

</style>