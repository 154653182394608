<template>
  <div>
    <nav class="navbar navbar-transparent navbar-absolute">
      <div class="container">
        <div class="navbar-header">
          <button type="button"
                  class="navbar-toggle navbar-toggle-black"
                  @click="toggleNavbar"
                  data-toggle="collapse"
                  data-target="#navigation-example-2">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar "></span>
            <span class="icon-bar "></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li>
              <router-link to="/login" class="btn">
                Вхід
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="wrapper wrapper-full-page">
      <div class="register-page">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div class="header-text">
                  <h2>Змінити пароль</h2>
                  <h4>{{ $route.query.uid }}</h4>
                  <hr>
                </div>
              </div>

              <div class="col-md-4 col-md-offset-4">
                <form @submit.prevent="ResetPassword()" >
                  <div class="card card-plain">
                    <div class="content">

                      <div class="form-group">
                        <input required type="password" v-model="password" placeholder="Пароль" class="form-control">
                      </div>
                      <div class="form-group">
                        <input required type="password" v-model="password_confirmation" placeholder="Пароль ще раз" class="form-control">
                      </div>
                    </div>
                    <div class="footer text-center">
                      <button type="submit" class="btn btn-fill btn-danger btn-wd">Зберегти</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="copyright text-center">
              &copy; Coded with
              <i class="fa fa-heart heart"></i> by
              <a href="https://github.com/cristijora" target="_blank">Cristi Jora</a>.
              Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" target="_blank">Creative Tim</a>.
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div class="collapse navbar-collapse off-canvas-sidebar">
      <ul class="nav nav-mobile-menu">
        <li>
          <router-link to="/login">
            Вхід
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        errors: [],
        password: "",
        password_confirmation: "",

      }
    },
    methods: {
      notifyVue () {
        this.$notify(
                {
                  title: this.errors.join(', '),
                })
      },
      checkForm(){

        if (this.password.length >= 6 && this.password_confirmation === this.password ) {
          return true;
        }

        this.errors = [];

        if (this.password.length < 6) {
          this.errors.push('Пароль повинен бути більше 6 символів');
        }
        if (this.password_confirmation !== this.password) {
          this.errors.push('Паролі не співпадають');
        }
        this.notifyVue('top', 'center')
      },
      ResetPassword(){
        if (this.checkForm()){
          let uid = this.$route.query.uid
          let client = this.$route.query.client
          let token = this.$route.query.token
          let password = this.password
          let password_confirmation = this.password_confirmation
          this.$store.dispatch('ResetPassword', {  password, password_confirmation, uid, client, token})
                  .then(() => this.$router.push('/'))  //
                  .catch(err => console.log(err))
        }

      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .card .alert {
    position: relative !important;
    width: 100%
  }
</style>
