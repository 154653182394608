<template>
    <div class="container-fluid" >
        <div class="col-md-12">
            <p>Колонка: <span v-html="table_column"> </span></p>
        </div>
        <template v-if=" $route.name === 'Опції колонки'">
        <div class="row">
            <div class="col-xs-3 col-xs-offset-7">
                <router-link class="btn btn-primary" :to="`/admin/reports/`+ $route.params.report_id + `/chapters/`+ $route.params.chapter_id + '/questions/' + $route.params.question_id +'/table_columns/'+ $route.params.table_column_id + '/options/new'"><i class="ti-plus"></i> Додати</router-link>
            </div>
        </div>
            <div class="card-content">
                <div class="table-full-width table-tasks col-md-6">
                    <table class="table">
                        <tbody>
                        <tr v-for="option in options" :key="option.id">
                            <td>
                                <label class="checkbox">
                                    <input type="checkbox" value="" data-toggle="checkbox">
                                </label>
                            </td>
                            <td>{{ option.name }}</td>
                            <td class="td-actions text-right">
                                <div class="table-icons">
                                    <router-link :to="`/admin/reports/`+ $route.params.report_id + `/chapters/`+ $route.params.chapter_id + '/questions/' + $route.params.question_id +'/table_columns/'+ $route.params.table_column_id + '/options/' + option.id"
                                                 type="button"
                                                 rel="tooltip"
                                                 title="Редагувати"
                                                 class="btn btn-info btn-simple btn-xs">
                                        <i class="ti-pencil-alt"></i>
                                    </router-link>

                                    <button type="button"
                                            rel="tooltip"
                                            title="Remove"
                                            class="btn btn-danger btn-simple btn-xs"
                                            @click="removeOption(option.id)">
                                        <i class="ti-close"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    export default {
        name: "OptionsTableColumn",
        components: {
          },
        data () {
            return {
                options: null,
                table_column: null,
                loading: true,
                errored: false
            }
        },
        created() {
            this.getTableColumn()
            this.getOptions()
        },
        watch: {
            $route: ['getTableColumn', 'getOptions']
        },
        methods: {
            getTableColumn(){
                this.axios
                    .get("/api/v1/reports/" + this.$route.params.report_id + '/chapters/'+ this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id + '/table_columns/'+ this.$route.params.table_column_id )
                    .then(response => (this.table_column = response.data.name))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            getOptions(){
               if (this.$route.name === 'Опції колонки') {
                   this.axios
                       .get('/api/v1/reports/'+ this.$route.params.report_id + "/chapters/"+ this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id + '/table_columns/'+ this.$route.params.table_column_id + '/options/')
                       .then(response => (this.options = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
               }
            },
            removeOption(id) {

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити опцію?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.axios
                            .delete('/api/v1/reports/'+ this.$route.params.report_id + "/chapters/"+ this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id + '/table_columns/'+ this.$route.params.table_column_id + '/options/' + id )
                            .then(this.options = this.options.filter( r => r.id !== id ))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Опція успішно видалена.',
                                    'success'
                                )

                            ));
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Опцію збережено :)',
                            'error'
                        )
                    }
                })
            }
        }
    }
</script>
<style>

</style>