<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
<!--                <div class="card-header">-->
<!--                    <h4 class="card-title">{{ report.name }}</h4>-->
<!--                </div>-->
                <div class="card-content">
                    <form class="form-horizontal"  @submit.prevent="toSave">
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Заголовок</label>
                                <div class="col-sm-10">
                                    <input type="text" v-model="article.title" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Тип</label>
                                <div class="col-sm-6">
                                    <el-select class="select-primary"
                                               size="large"
                                               placeholder="Виберіть тип"
                                               v-model="article.tags">
                                        <el-option v-for="option in selects.type"
                                                   class="select-danger"
                                                   :value="option.value"
                                                   :label="option.label"
                                                   :key="option.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Номер</label>
                                <div class="col-sm-10">
                                    <input type="number" v-model="article.number" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Короткий текст</label>
                                <div class="col-sm-10">
                                    <ckeditor v-model="article.short" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Основний текст</label>
                                <div class="col-sm-10">
                                    <ckeditor v-model="article.body" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </fieldset>
                      <fieldset>
                        <div class="form-group">
                          <label class="col-sm-1 control-label">Публікація</label>
                          <div class="col-sm-10">
                            <p class="category"> </p>
                            <p-switch v-model="article.public" type="primary"></p-switch>
                          </div>
                        </div>
                      </fieldset>
                        <div class="card-footer">
                            <div class="form-group">
                                <div class="col-md-3">
                                    <router-link to="/admin/articles" class="btn btn-default">Назад</router-link>
                                </div>
                                <div class="col-md-9">
                                    <button type="submit" class="btn btn-fill btn-info">
                                        Зберегти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import PSwitch from '../../../../../components/UIComponents/Switch.vue'
    import {Select, Option} from 'element-ui'
    export default {
        name: "Article",
        components: {
             PSwitch,
            [Option.name]: Option,
            [Select.name]: Select,
        },
        created() {
            if (this.$route.params.id){
                this.getArticle(this.$route.params.id)
            }
        },
        methods:{
            getArticle(id){
                this.axios
                    .get('/api/v1/articles/'+ id )
                    .then(response => (this.article = response.data))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            toSave(){

                    if (this.$route.params.id) {
                        this.axios
                            .patch('/api/v1/articles/'+ this.$route.params.id , {article: this.article} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: '/admin/articles' })
                            ));


                    } else {
                        this.axios
                            .post('/api/v1/articles/', {article: this.article} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: '/admin/articles' })
                            ));
                    }

            }
        } ,
        data () {
            return {
                selects: {
                    type: [
                       // [['Numeric', 0], ['String', 1], ['Text', 2], ['Date', 3], ['Selects', 4], ['Radio-buttons', 5],
                        // ['Check-boxes', 6], ['Regions', 7], ['OVK', 8], ['OVKs', 9], ['Description', 10], ['Select-nested', 11],  ['Table', 12], ['Party', 13]]
                        {value: 1, label: 'Інформація'},
                        {value: 2, label: 'Безпека'}
                    ]
                },
                article: {
                    title: null,
                    number: null,
                    tags: null,
                    short: null,
                    body: null,
                    public: false
                },
                loading: true,
                errored: false,
                editorConfig: {
                    // The configuration of the editor.
                  allowedContent: 'iframe[*]'
                }
            }
        }
    }
</script>
<style>

</style>