<template>
<div class="card-content row">
    <template v-if="$route.path === '/incidents'">
    <div class="col-sm-12">
        <router-link to="/incidents/new" class="btn btn-danger btn-fill btn-wd">
            Додати порушення
        </router-link>
      <button @click="exportIncidents()" v-if="$store.state.role==='Admin' || ($store.state.role==='lawyer')" class="btn btn-sm btn-info btn-fill btn-rotate">
        <i class="ti-export"></i> Експорт
      </button>
      <button @click="exportIncidentsPoints()" v-if="$store.state.role==='Admin' || ($store.state.role==='lawyer')" class="btn btn-sm btn-info btn-fill btn-rotate">
        <i class="ti-export"></i> Експорт координат
      </button>
    </div>

      <div class="col-sm-6">
        <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
            <el-option
                    class="select-default"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
        </el-select>
    </div>
    <div class="col-sm-6">
        <div class="pull-right">
            <label>
<!--                <input type="search" class="form-control input-sm" placeholder="Search records" v-model="searchQuery" aria-controls="datatables">-->
            </label>
        </div>
    </div>
      <br>
    <div class="col-sm-12">
<!--    <el-button @click="resetDateFilter">Скинути фільтр дати</el-button>-->
    <el-button @click="clearFilter">Скинути фільтри</el-button>
      <label>Видаленні</label>
        <p-switch  v-model="remove"  @input="getIncidents()" type="primary"></p-switch>
      <label>Важкі</label>
      <p-switch  v-model="hard"  @input="getIncidents()" type="primary"></p-switch>
      <label>Доповнені</label>
      <p-switch  v-model="fixed"  @input="getIncidents()" type="primary"></p-switch>
    <el-table
            ref="filterTable"
            :data="tableData"
            @sort-change="sortChange"
            @filter-change="filterChange"
            :default-sort="{prop: 'id', order: 'descending'}"
            style="width: 100%">
      <el-table-column
          prop="id"
          sortable
          label="id"
          width="100">
        <template slot-scope="scope">
          <router-link :to="{path:`/incidents/` + scope.row.id}" >
            <el-tooltip class="item" effect="dark" :content="scope.row.title" placement="top-start">
              <el-button>
                <i class="ti-check-box"></i> {{scope.row.id}}
              </el-button>
            </el-tooltip>

          </router-link>
        </template>
      </el-table-column>
        <el-table-column
                prop="date"
                label="Дата"
                sortable
                width="180"
                column-key="date"
                >
      <template slot-scope="scope">
        {{ scope.row.date |  formatDate }}
      </template>

        </el-table-column>
        <el-table-column
                v-if="regions.length>1"
                :class-name="'region_id'"
                prop="region_id"
                column-key="region_id"
                sortable
                label="Область"
                width="180"
                :filters="regionFilters"
                :filter-method="filterRegionTag"
                :filtered-value="$route.query.region_id ? [Number($route.query.region_id) ] : null"
                filter-placement="bottom-end">
          <template slot-scope="scope">
            {{ scope.row.region}}
          </template>
        </el-table-column>
      <el-table-column
          prop="election_type_id"
          column-key="election_type_id"
          sortable
          label="Тип виборів"
          width="100">
        <template slot-scope="scope">
          {{    scope.row.election    }}
        </template>
      </el-table-column>
        <el-table-column
                prop="district_id"
                column-key="district_id"
                sortable
                label="Район"
                width="180"
                :filters="disrtrictFilters"
                :filter-method="filterDistrictTag"
                filter-placement="bottom-end">
          <template slot-scope="scope">
            {{ scope.row.district}}
          </template>
        </el-table-column>
        <el-table-column
                prop="incident_type"
                column-key="incident_type"
                sortable
                label="Тип"
                width="100"
                :filters="incidentTypeFilter"
                :filter-method="filterIncidentTag"
                filter-placement="bottom-end">
            <template slot-scope="scope" v-if="scope.row.incident_type && incident_type.length>0">
              {{
                incident_type[incident_type.findIndex(e => e.id === scope.row.incident_type)].name
              }}
            </template>
        </el-table-column>
        <el-table-column
                prop="incident_sub_type"
                column-key="incident_sub_type"
                sortable
                label="Підтип"
                width="180"
                :filters="incidentSubTypeFilter"
                :filter-method="filterIncidentSubTag"
                filter-placement="bottom-end">
            <template slot-scope="scope" v-if="scope.row.incident_type && incident_type.length>0 && scope.row.incident_sub_type">
             {{
                  incident_type[incident_type.findIndex(e => e.id === scope.row.incident_type)]
                    .sub_types[incident_type[incident_type.findIndex(e => e.id === scope.row.incident_type)]
                    .sub_types.findIndex(s => s.id === scope.row.incident_sub_type)] ?
                      incident_type[incident_type.findIndex(e => e.id === scope.row.incident_type)]
                          .sub_types[incident_type[incident_type.findIndex(e => e.id === scope.row.incident_type)]
                          .sub_types.findIndex(s => s.id === scope.row.incident_sub_type)].name : ""

              }}

            </template>
        </el-table-column>
      <el-table-column
          prop="user_id"
          sortable
          label="Автор"
          width="150">
        <template slot-scope="scope">
          {{ scope.row.user}}
        </template>
      </el-table-column>
        <el-table-column
            v-if="$store.state.role==='b'"
                prop="tag"
                label="Адмінресурс"
                width="100"
                :filters="[{ text: 'Home', value: 'Home' }, { text: 'Office', value: 'Office' }]"
                :filter-method="filterTag"
                filter-placement="bottom-end">
            <template slot-scope="scope">
                <el-tag
                        :type="scope.row.tag === 'Home' ? 'primary' : 'success'"
                        disable-transitions>{{scope.row.tag}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column
            v-if="$store.state.role==='b'"
                prop="tag"
                label="Суд"
                width="100"
                :filters="[{ text: 'Home', value: 'Home' }, { text: 'Office', value: 'Office' }]"
                :filter-method="filterTag"
                filter-placement="bottom-end">
            <template slot-scope="scope">
                <el-tag
                        :type="scope.row.tag === 'Home' ? 'primary' : 'success'"
                        disable-transitions>{{scope.row.tag}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column
                v-if="$store.state.role==='b'"
                prop="tag"
                label="Напрям"
                width="100"
                :filters="[{ text: 'Home', value: 'Home' }, { text: 'Office', value: 'Office' }]"
                :filter-method="filterTag"
                filter-placement="bottom-end">
            <template slot-scope="scope">
                <el-tag
                        :type="scope.row.tag === 'Home' ? 'primary' : 'success'"
                        disable-transitions>{{scope.row.tag}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column
            v-if="$store.state.admin==='true' || ($store.state.role==='lawyer') "
                prop="tk"
                sortable
                label="TK"
                width="100"
                >
            <template slot-scope="scope">
                <el-tag
                        :type="scope.row.tk === true ? 'danger' : 'success'"
                        disable-transitions></el-tag>
            </template>
        </el-table-column>
      <el-table-column
          column-key="incident_verification"
          prop="incident_verification"
          label="Верифікація"
          width="130"
          :filters="filter_ver()"
          :filter-method="filterVerification"
          :filter-multiple="false"
          :filtered-value="this.incident_verification ? [Number( this.incident_verification) ] :[]"
          filter-placement="bottom-end">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="type_verefication(scope.row.incident_verification)" placement="top-start">
            <el-button>{{scope.row.incident_verification}}</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="Редагувати"
          width="180">
        <template slot-scope="scope">
          <router-link :to="{path:`/incidents/` + scope.row.id}" class="btn btn-default">
            <i class="ti-check-box"></i> Редагувати
          </router-link>
          <button class="btn btn-danger pull-right" v-if="($store.state.admin || $store.state.role==='Regional' || $store.state.role==='lawyer')" @click="removeIncident(scope.row.id,scope.row.remove )">
            {{ scope.row.remove ? "Відновити" : "Видалити"}}
          </button>

        </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
        <p class="category">Показано від {{from + 1}} до {{to}} з {{total}}</p>
    </div>
    <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total">
        </p-pagination>
    </div>
    </template>
    <router-view></router-view>
</div>
</template>
<script>
    import Vue from 'vue'
    import {Table, TableColumn, Select, Option, Button, Tag} from 'element-ui'
    import PPagination from "../../../../UIComponents/Pagination.vue"
    import PSwitch from "../../../../UIComponents/Switch"
    import moment from "moment";
    import swal from "sweetalert2";
    //import incident_type from "./incident_type";
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Button)
    Vue.use(Tag)
    export default {
        name: 'Incidents',
        components: {
            PPagination,
            PSwitch
        },
        computed: {
            incidentTypeFilter(){
              let list=[];
              this.incident_type.forEach(function (r){
                list.push({text: r.name, value: r.id })
              })
              return list
            },
            incidentSubTypeFilter(){
              let list=[];
              if (this.incident_id) {
                this.incident_type.filter(r =>  (this.incident_id.split(',')).includes(r.id.toString())).forEach(function (i){
                  i.sub_types.forEach(function (s){
                    list.push({text: s.name, value: s.id })
                  })
                })
              }
              return list
            },
            regionFilters(){
              let list=[];
              this.regions.forEach(function (r){
                list.push({text: r.name, value: r.id })
              })
              return list
            },
            disrtrictFilters(){
              let list=[];
              if (this.region_id) {
                this.regions.filter(r =>  (this.region_id.toString().split(',')).includes(r.id.toString())).forEach(function (r){
                      r.districts.forEach(function (d){
                             list.push({text: d.name, value: d.id })
                           })
                    })
              }
              return list
            },
            currentPage(){
              return this.pagination.currentPage
            },
            perPage(){
              return this.pagination.perPage
            },
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total () {
                return this.pagination.total
            },
        },
      filters: {
        formatDate(value){
          if (value) {
            return moment(String(value)).format('DD.MM.YYYY')
          }
        }
      },
        created() {
          this.getIncidents()
        },
        watch:{
          $route: 'getIncidents',
          perPage: 'getIncidents',
          currentPage:  'getIncidents',
        },

        mounted() {
          this.getRegions()
          this.getIncidentTypes()
        },

        methods: {
          exportIncidents(){
            this.axios
                .get('/api/v1/export/incident.csv', {responseType: 'blob'})
                .then(( {data} ) => {
                  const downloadUrl = window.URL.createObjectURL(new Blob([data]));

                  const link = document.createElement('a');

                  link.href = downloadUrl;

                  link.setAttribute('download',  "incidents-"+ moment().format('D-MM-YYYY h:mm:ss')+ '.csv'); //any other extension
                  document.body.appendChild(link);

                  link.click();

                  link.remove();

                })
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          exportIncidentsPoints(){
            this.axios
                .get('/api/v1/export/incident_points.csv', {responseType: 'blob'})
                .then(( {data} ) => {
                  const downloadUrl = window.URL.createObjectURL(new Blob([data]));

                  const link = document.createElement('a');

                  link.href = downloadUrl;

                  link.setAttribute('download',  "points_incidents-"+ moment().format('D-MM-YYYY h:mm:ss')+ '.csv'); //any other extension
                  document.body.appendChild(link);

                  link.click();

                  link.remove();

                })
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          filter_ver(){
            if (this.$store.state.role==='Public'){
              return [   { text: 'Юрифіковано', value: 5 },  { text: 'Опубліковано', value: 7 } ]
            } else {
              return [{ text: 'Не перевірено', value: 0 },{ text: 'Підтверджено', value: 1 }, { text: 'Не підтверджено', value: 2 },
               // { text: 'Верифіковано', value: 3 }, { text: 'Не верифіковано', value: 4 },
                { text: 'Юрифіковано', value: 5 }, { text: 'Доопрацювати', value: 6 }, { text: 'Опубліковано', value: 7 } ]
            }
          },
          type_verefication(type){
            if (type===0){
              return "Не перевірено"
            } else if (type===1){
              return "Підтверджено"
            } else if (type===2){
              return "Не підтверджено"
            //}
            // else if (type===3){
            //   return "Верифіковано"
            //  }else if (type===4){
            //   return "Не верифіковано"
            } else if (type===5){
              return "Юрифіковано"
            } else if (type===6){
            return "Доопрацювати"
           } else if (type===7){
            return "Опубліковано"
            }
          },
          removeIncident(id, remove){
            remove = !remove
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете видалити/відновити проушення?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .patch('/api/v1/incidents/' +  id  , { incident: { remove: remove} })
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Видалено!',
                            'Дія виконана успішно.',
                            'success'
                        ),
                            this.getIncidents()

                    ));
              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'збережено :)',
                    'error'
                )
              }
            })
          },
          getIncidentTypes(){
            this.axios
                .get('/api/v1/get_incident_types')
                .then(response => (
                    this.incident_type = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          getRegions(){
            this.axios
                .get('/api/v1/regions')
                .then(response => (
                    this.regions = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(()=>(
                    this.setRegionId()
                ))
          },
          setRegionId(){
            if ( this.regions.length===1 ){
                 this.region_id = this.regions[0].id
            }
          },
          filterChange(states){
           let property = Object.keys(states)[0]
           let values = Object.values(states).join(',')
            if (property==='region_id') {
              this.region_id = values
            } else if (property==='district_id'){
              this.district_id = values
            }  else if (property==='incident_type'){
               this.incident_id = values
            }  else if (property==='incident_verification'){
              this.incident_verification = values
            } else {
              this.incident_sub_id = values
            }
            this.getIncidents()
          },
            sortChange(sortProps){
              this.sort = sortProps.prop
              if (sortProps.order ==="ascending")
              {
                this.order= 'asc'
              } else {
                this.order= 'desc'
              }
              this.getIncidents()
            },
            getIncidents(){
              if (this.$route.path === '/incidents') {
                this.axios
                    .get('/api/v1/incidents',  {params: {user_id: this.$store.state.user_id, limit: this.pagination.perPage, offset: this.from, sort: this.sort, order: this.order,
                        dts_id: this.dts_id,
                        hard: this.hard,
                        fixed: this.fixed,
                        region: this.region_id,
                        district: this.district_id,
                        incident: this.incident_id,
                        incident_sub: this.incident_sub_id,
                        incident_verification: this.incident_verification,
                        remove: this.remove
                      }})
                    .then(response => (this.tableData = response.data.incidents,
                            this.pagination.total = response.data.total
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (this.loading = false));
              }
            },
            queriedData () {
                if (!this.searchQuery) {
                    this.pagination.total = this.tableData.length
                    return this.pagedData
                }
                let result = this.tableData
                    .filter((row) => {
                        let isIncluded = false
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString()
                            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                                isIncluded = true
                            }
                        }
                        return isIncluded
                    })
                this.pagination.total = result.length
                return result.slice(this.from, this.to)
            },
            resetDateFilter() {
                this.$refs.filterTable.clearFilter('date');
            },
            clearFilter() {
                this.$refs.filterTable.clearFilter();
                 if  (this.regions.length > 1 ){
                   this.region_id = ''
                 }
                  this.district_id = ''
                  this.incident_id = ''
                  this.incident_sub_id = ''
                  this.incident_verification = ''
                 this.getIncidents()
            },
            formatter(row) {
                return row.address;
            },
            filterTag(value, row) {
              return row === value;
            },
            filterRegionTag(value, row) {
              return row.region_id === value;
            },
            filterVerification(value, row) {
              return row.incident_verification === value;
            },
            filterDistrictTag(value, row) {
              return row.district_id === value;
            },
            filterIncidentTag(value, row) {
              return row.incident_type === value;
            },
            filterIncidentSubTag(value, row) {
              return row.incident_sub_type === value;
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            }
        },
        data(){
            return {
                order: 'desc',
                sort: 'id',
                region_id: this.$route.query.region_id || '',
                dts_id: this.$route.query.dts_id || '',
                district_id: '',
                incident_id: '',
                incident_sub_id: '',
                incident_verification: this.$route.query.incident_verification || null,
                hard: (this.$route.query.hard==="hard" ? true : false) || false,
                fixed: (this.$route.query.hard==="fixed" ? true : false) || false,
                remove: false,

                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 50
                },
                regions: [],
                searchQuery: "",
                propsToSearch: ['name', 'email', 'age'],
                tableData: [],
                incident_type: []
            }
        }
    }
</script>
<style>

</style>