<template>
    <div class="container-fluid">
        <h1>{{report.name}} ({{report.election}})</h1>
        <div v-html="report.description"></div>
        <fieldset v-if="$route.query.answer" >
            <div class="form-group">
                <label class="col-sm-1 control-label">Закінчити заповнення</label>
                <div class="col-sm-10">
                    <p class="category"> </p>
                    <p-switch v-model="report.answer.finish" @input="changeAnswer(report.answer.finish)" type="primary"></p-switch>
                </div>
            </div>
        </fieldset>
        <button class="btn btn-danger pull-right" v-if="$route.query.answer && ($store.state.admin || $store.state.role==='Regional')" @click="removeAnswer()">Видалити звіт</button>
        <form-wizard ref="wizard"
                     id="form"
                     stepSize="xs"
                     @on-complete="wizardComplete"
                     @on-change="scrollToTop()"
                     shape="square"
                     layout="vertical"
                     error-color="#EB5E28"
                     color="#66615B"
                     title=""
                     :start-index="0"
                     subtitle="" >

            <tab-content v-for="chapter in report.chapter" :key="chapter.id" title="">
                 <h1>{{ chapter.chapter_name}}</h1>
                <div class="jumbotron" v-for="(q, i) in chapter.questions" :key="q.id">
                    <div class="row">
                        <div class="col-xs-12" v-html="`<p>` + (i + 1) + ' Питання:</p>' + q.question"></div>
                    </div>

                    <fieldset>
                        <div class="form-group">
<!--                            <label class="col-sm-2 control-label"></label>-->
                            <div class="col-md-3" v-if="q.type_field===0">
                                <div class="form-group">
                                    <input type="number" @wheel="$event.target.blur()" class="form-control" @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])" v-model.lazy.number="answers[answers.findIndex(a => a.question_id === q.id)].value">
                                </div>
                            </div>
                            <div class="col-sm-12"  v-if="q.type_field===1">
                                <input type="text" class="form-control" @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])" v-model.lazy.trim="answers[answers.findIndex(a => a.question_id === q.id)].value">
<!--                                <span class="help-block">A block of help text that breaks onto a new line.</span>-->
                            </div>
                            <div class="col-sm-12" v-if="q.type_field===2">
<!--                                <textarea class="form-control" placeholder="Ведіть текст" rows="5" -->
<!--                                          @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"  -->
<!--                                          v-model.lazy.trim="answers[answers.findIndex(a => a.question_id === q.id)].value"-->
<!--                                ></textarea>-->
                              <el-input
                                  type="textarea"
                                  :autosize="{minRows: 5}"
                                  placeholder="Ведіть текст"
                                  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"
                                  v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].value"
                              >
                              </el-input>
                            </div>
                            <div class="col-md-4"  v-if="q.type_field===3">
                                <div class="form-group">
                                    <el-date-picker @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"  v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].value" type="date" placeholder="Виберіть дату"
                                                    :picker-options="pickerOptions1">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="col-sm-12"  v-if="q.type_field===4">
                                <el-select class="select-default"
                                           size="large"
                                           placeholder="Виберіть опцію"
                                           v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].value"  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])">
                                    <el-option v-for="option in q.answeroptions"
                                               class="select-default"
                                               :value="option.id"
                                               :label="option.name"
                                               :key="option.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-sm-5" v-if="q.type_field===5">
                                <p-radio  v-for="option in q.answeroptions"
                                         :label="option.id"
                                          :value="option.id"
                                         :key="option.id" @input="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])" v-model="answers[answers.findIndex(a => a.question_id === q.id)].value">{{option.name}}</p-radio>

                            </div>
                            <div class="col-sm-4 col-sm-offset-1" v-if="q.type_field===6">
                                <div class="checkbox checkbox-inline" v-for="option in q.answeroptions" :key="option.id">
                                    <input type="checkbox" id="jack" :value="option.id"  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])" v-model="answers[answers.findIndex(a => a.question_id === q.id)].value">
                                    <label for="jack">{{option.name}}</label>
                                </div>
                            </div>
                            <div class="col-sm-6"  v-if="q.type_field===7">
                                <el-select class="select-default"
                                           size="large"
                                           placeholder="Виберіть регіон"
                                           v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].value"  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)]); changeDistrict(answers[answers.findIndex(a => a.question_id === q.id)].value)">
                                    <el-option v-for="option in regions"
                                               class="select-default"
                                               :value="option.id"
                                               :label="option.name"
                                               :key="option.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-sm-6"  v-if="q.type_field===8">
                                <el-select class="select-default"
                                           size="large"
                                           placeholder="Виберіть ТВК"
                                           v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].value"  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])">
                                    <el-option v-for="option in tvks"
                                               class="select-default"
                                               :value="option.id"
                                               :label="option.name"
                                               :key="option.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-sm-6"  v-if="q.type_field===9">
                                <el-select class="select-default"
                                           size="large"
                                           placeholder="Single Select"
                                           v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].value"  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])">
                                    <el-option v-for="option in regions"
                                               class="select-default"
                                               :value="option.id"
                                               :label="option.name"
                                               :key="option.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-sm-10" v-if="q.type_field===10">
                                <p class="form-control-static"></p>
                            </div>
                            <div class="col-sm-12" v-if="q.type_field===11">
                                <el-select multiple class="select-primary"
                                           size="large"
                                           v-model="answers[answers.findIndex(a => a.question_id === q.id)].value"  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"
                                           placeholder="Виберіть опції">
                                    <el-option v-for="option in q.answeroptions"
                                               class="select-primary"
                                               :value="option.id"
                                               :label="option.name"
                                               :key="option.name">
                                    </el-option>
                                </el-select>
                            </div>
                          <div class="col-sm-12" v-if="q.type_field===15">
                            <el-select class="select-primary"
                                       size="large"
                                       v-model="answers[answers.findIndex(a => a.question_id === q.id)].value"  @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"
                                       placeholder="Виберіть Район">
                              <el-option v-for="option in districts"
                                         class="select-primary"
                                         :value="option.id"
                                         :label="option.name"
                                         :key="option.name">
                              </el-option>
                            </el-select>
                          </div>
                            <div v-if="q.type_field===12">
                                <div class="row">
                                        <div class="flex-table">
                                          <div class="flex-row" v-for="(row, i) in answers[answers.findIndex(a => a.question_id === q.id)].tables" :key="i">
                                            <div class="flex-column" v-for="(column, c) in q.table_columns" :key="column.id">
                                              <div style="margin: 15px">{{q.table_columns[c].name}}</div>
                                              <div v-if="column.type_column===0">
                                                <div class="form-group">
                                                  <input type="number" @wheel="$event.target.blur()" class="form-control" @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i )" v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value">
                                                </div>
                                              </div>
                                              <div v-if="column.type_column===1">
                                                <input type="text" class="form-control" @change="changeValueTable(answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i)" v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value">
                                              </div>
                                              <div  v-if="column.type_column===2">
<!--                                                <textarea class="form-control" placeholder="Ведіть текст" -->
<!--                                                          rows="5" -->
<!--                                                          @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i )"  -->
<!--                                                          v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value"-->
<!--                                                ></textarea>-->
                                                <el-input
                                                    type="textarea"
                                                    :autosize="{minRows: 5}"
                                                    placeholder="Ведіть текст"
                                                    @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i )"
                                                    v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value"
                                                >
                                                </el-input>
                                              </div>
                                              <div v-if="column.type_column===3">
                                                <el-select class="select-default"
                                                           size="large"
                                                           placeholder="Виберіть опцію"
                                                           v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value"
                                                           @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i)">
                                                  <el-option v-for="option in column.table_options"
                                                             class="select-default"
                                                             :value="option.id"
                                                             :label="option.name"
                                                             :key="option.id">
                                                    <p style="word-wrap: break-word;white-space: -moz-pre-wrap;white-space: pre-wrap;
  width:250px; display:inline-block;float: left">{{ option.name }}</p>
                                                  </el-option>
                                                </el-select>
                                              </div>
                                              <div v-if="column.type_column===4">
                                                <Select2
                                                    v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value"
                                                    :options="parties"
                                                    :settings="{ width: '100%'}"
                                                    @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i)"
                                                />
                                              </div>
                                            </div>
                                            <div class="flex-column">
                                              <button @click="handleDelete(answers.findIndex(a => a.question_id === q.id), i)" style="margin: auto" class="btn btn-icon btn-danger"> <i class="ti-trash"></i></button>
                                            </div>
                                          </div>
                                          <div>
                                            <div :colspan="q.table_columns.length + 1"><button @click="addItem(  q, answers.findIndex(a => a.question_id === q.id) )" class="btn btn-icon"> <i class="ti-plus"></i></button></div>
                                          </div>

                                        </div>
                                        <!--<table class="table table-striped table-full-width table-responsive">
                                            <thead>
                                            <tr>
                                                <td v-for="column in q.table_columns" :key="column.id">{{column.name}}</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                  <tr v-for="(row, i) in answers[answers.findIndex(a => a.question_id === q.id)].tables" :key="i">
                                                      <td v-for="column in q.table_columns" :key="column.id">
                                                          <div class="col-xs-12" v-if="column.type_column===0">
                                                              <div class="form-group">
                                                                  <input type="number" class="form-control" @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i )" v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value">
                                                              </div>
                                                          </div>
                                                          <div class="col-xs-12"  v-if="column.type_column===1">
                                                              <input type="text" class="form-control" @change="changeValueTable(answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i)" v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value">
                                                          </div>
                                                          <div class="col-xs-12" v-if="column.type_column===2">
                                                              <textarea class="form-control" placeholder="Ведіть текст" rows="5" @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i )"  v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value"></textarea>
                                                          </div>
                                                          <div class="col-xs-12" v-if="column.type_column===3">
                                                              <el-select class="select-default"
                                                                         size="large"
                                                                         placeholder="Single Select"
                                                                         v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value"
                                                                         @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i)">
                                                                  <el-option v-for="option in column.table_options"
                                                                             class="select-default"
                                                                             :value="option.id"
                                                                             :label="option.name"
                                                                             :key="option.id">
                                                                  </el-option>
                                                              </el-select>
                                                          </div>
                                                          <div class="col-xs-12" v-if="column.type_column===4">
                                                              <Select2
                                                                      v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)].value"
                                                                       :options="parties"
                                                                      :settings="{ width: '100%'}"
                                                                       @change="changeValueTable( answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns[answers[answers.findIndex(a => a.question_id === q.id)].tables[i].columns.findIndex(c => c.table_column_id === column.id)], i)"
                                                                  />
                                                          </div>
                                                      </td>
                                                      <td>
                                                          <button @click="handleDelete(answers.findIndex(a => a.question_id === q.id), i)" class="btn btn-icon btn-danger"> <i class="ti-trash"></i></button>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td :colspan="q.table_columns.length + 1"><button @click="addItem(  q, answers.findIndex(a => a.question_id === q.id) )" class="btn btn-icon"> <i class="ti-plus"></i></button></td>
                                                  </tr>
                                            </tbody>
                                        </table>-->
                                    </div>
                                </div>
                            <div class="col-sm-6"  v-if="q.type_field===13">
                                <Select2 v-model.number="answers[answers.findIndex(a => a.question_id === q.id)].value"
                                         :options="parties"
                                         :settings="{ width: '100%', selectionCssClass: 'form-control'}"
                                         @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"
                                />

                            </div>
                            <div class="col-md-1" v-if="q.type_field===14">
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">З</label>
                                    <el-date-picker @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"  v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].value.begin" type="date" placeholder="Виберіть дату"
                                                    :picker-options="pickerOptions1">
                                    </el-date-picker>
                                    <label class="col-sm-2 control-label">До</label>
                                    <el-date-picker @change="changeValue(answers[answers.findIndex(a => a.question_id === q.id)])"  v-model.lazy="answers[answers.findIndex(a => a.question_id === q.id)].value.end" type="date" placeholder="Виберіть дату"
                                                    :picker-options="pickerOptions1">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </tab-content>
            <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Назад</button>
            <button slot="next" class="btn btn-info btn-fill btn-wd btn-next">Далі</button>
            <button slot="finish" class="btn btn-info btn-fill btn-wd">Закрити</button>
        </form-wizard>
        <div class="col-md-5" v-if="$route.query.answer">
            <el-collapse class="panel-group" v-model="showChat">
                <el-collapse-item title="Коментарі"  name="chat"  >
                    <div class="card card-chat ">
                        <div class="card-header">
<!--                            <h4 class="card-title">Коментарі</h4>-->
<!--                            <p class="category">відносно звіту</p>-->
                        </div>
                        <div class="card-content">
                            <ol class="chat">
                                <li :class="comment.user_id === $store.state.user_id ? 'self' : 'other'" v-for="comment in report.comments" :key="comment.id">
                                    <div class="avatar">
                                        <img v-if="comment.user_id === $store.state.user_id"  src="/static/img/default-avatar.png" alt="crash"/>
                                        <img v-else src="/static/img/faces/face-2.jpg" alt="crash"/>
                                    </div>
                                    <div class="msg">
                                        <p>
                                            {{ comment.message }}
                                        </p>
                                        <div class="card-footer">
                                            <i class="ti-check"></i>
                                            <h6>{{ comment.created_at | formatDate}}</h6>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            <hr>
                            <form   @submit.prevent="toSend">
                            <div class="send-message">
                                <div class="avatar">
                                    <img src="/static/img/default-avatar.png" alt="crash"/>
                                </div>

                                <input v-model.trim="message" class="form-control textarea" type="text" placeholder="Друкуй тут!"/>
                                <div class="send-button">
                                    <button class="btn btn-xs btn-primary btn-fill">Зберегти</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

    </div>
</template>
<script>

    import Vue from 'vue'
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import PSwitch from "../../../../UIComponents/Switch"
    import {DatePicker, Select, Option, Collapse, CollapseItem,Input} from 'element-ui'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import swal from "sweetalert2";
    import moment from 'moment';
    import Select2 from 'v-select2-component';
     Vue.use(Collapse)
     Vue.use(CollapseItem)
     // Vue.use(ElInput)

    export default {
        name: "Form",
        components: {
            FormWizard,
            TabContent,
            [DatePicker.name]: DatePicker,
            [Option.name]: Option,
            [Select.name]: Select,
            Select2,
            PSwitch,
            [Input.name]: Input

        },
        watch: {
            // $route: ''
        },
        filters: {
            formatDate(value){
                if (value) {
                    return moment(String(value)).format('DD.MM.YYYY hh:mm')
                }
            }
        },
        created() {
            if (this.$route.params.id){
                this.getReport(this.$route.params.id, this.$route.query.answer)
            }
            this.getRegions()
            this.getParties()
        },
        mounted() {

        },
        methods:{
          scrollToTop(){
            document.getElementById('form').scrollIntoView();
          },
          removeAnswer(){
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете видалити звіт?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так, видалити його!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                  this.axios
                      .delete('/api/v1/answers/' + this.$route.query.answer )
                      //.then( this.answers[item].tables.splice(index,1))
                      .catch(error => {
                        if (error.response && error.response.status === 401) {
                          this.$store.dispatch("logout")
                        } else {
                          console.log(error);
                          this.errored = true;
                        }
                      })
                      .finally(() => (
                          swalWithBootstrapButtons.fire(
                              'Видалено!',
                              'Звіт успішно видалено.',
                              'success'
                          ),
                              this.closeForm()
                      ));

              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'звіт збережено :)',
                    'error'
                )
              }
            })
          },
          changeDistrict(value){
            if(this.regions.length>0){
              this.districts = this.regions[ this.regions.findIndex(r => r.id === value)].districts
              this.tvks = this.regions[ this.regions.findIndex(r => r.id === value)].tvks
            }
          },
            getParties(){
                this.axios
                    .get('/api/v1/parties')
                    .then(response => (
                        this.parties = response.data
                    ))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
            },
            getRegions(){
                this.axios
                    .get('/api/v1/regions')
                    .then(response => (
                        this.regions = response.data
                    ))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
            },
            changeAnswer(value){
                this.axios
                    .patch('/api/v1/answers/' + this.$route.query.answer, { answer: {report_id:  this.$route.params.id,  finish: value }})
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
            },
            toSend(){
                this.axios
                    .post('/api/v1/comment_reports', {comment_report: {answer_id: this.$route.query.answer, user_id: this.$store.state.user_id, message: this.message}} )
                    .then(response => ( this.report.comments.push(response.data) ))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (
                        this.message = null)
                    );
            },
            addItem(items, index){
                let table = [];
                items.table_columns.map(function (t) {
                    table.push({ question_id: items.id, table_column_id: t.id, value: null })
                })
                this.answers[index].tables.push({ id: null, columns: table })
            },
            handleDelete (item, index) {

                let row_id = this.answers[item].tables[index].id

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити запитання?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        if (row_id){
                        this.axios
                            .delete('/api/v1/answer_rows/' + row_id )
                            .then( this.answers[item].tables.splice(index,1))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Запитання успішно видалено.',
                                    'success'
                                )

                            ));
                        } else {
                            this.answers[item].tables.splice(index,1)
                            swalWithBootstrapButtons.fire(
                                'Видалено!',
                                'Запитання успішно видалено.',
                                'success'
                            )
                        }
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Запитання збережено :)',
                            'error'
                        )
                    }
                })
            },
            wizardComplete(){
              if (  !this.$route.query.answer || this.report.answer.finish) {
                 this.closeForm()
              } else {
                const swalWithBootstrapButtons = swal.mixin({
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                  },
                  buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                  title: 'Ви закінчили заповнення?',
                  text: "Оберіть опцію:",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Відправити на перевірку',
                  cancelButtonText: 'Залишити в роботі',
                  reverseButtons: true
                }).then((result) => {
                  if (result.value) {
                    this.report.answer.finish = true
                    this.changeAnswer(this.report.answer.finish)
                  }
                })
                    .finally(() => (
                        this.closeForm()
                    ));
              }
            },
           closeForm(){
             if (this.$route.query.admin === 1){
               this.$router.push({ path: `/admin_forms` })
             }
             else {
               this.$router.push({ path: `/forms` })
             }
           },
            setReport(response){
                this.report = response.data
                if (this.report.comments.length>0) {this.showChat = "chat"}
                this.setAnswer()
            },
            changeValue(item){
                // console.log(item)
                if (item.value < 0) {
                    item.value = 0
                }
                if (!this.$route.query.answer){
                    this.saveAnswer(item, 'value')
                } else {
                    this.saveAnswersquestions(item)
                }

            },
            saveAnswersquestions(item, answer){
                if (!answer) {
                    this.answer = this.$route.query.answer
                } else {
                    this.answer = answer
                    }
                if (item.id) {
                    this.axios
                        .patch('/api/v1/answersquestions/' + item.id  , { answersquestion: {answer_id: this.answer, question_id: item.question_id, value: item.value }})
                        // .then(response => (
                        //     //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                        //     Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value })
                        // ))
                        .catch(error => {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch("logout")
                            } else {
                                console.log(error);
                                this.errored = true;
                            }
                        })
                } else {
                    this.axios
                        .post('/api/v1/answersquestions', { answersquestion: {answer_id: this.answer, question_id: item.question_id, value: item.value }})
                        .then(response => (
                            //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                            Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
                        ))
                        .catch(error => {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch("logout")
                            } else {
                                console.log(error);
                                this.errored = true;
                            }
                        })
                }
            },
            changeValueTable(item, row){
                if (item.value < 0) {
                    item.value = 0
                }
                if (!this.$route.query.answer){
                    this.saveAnswer({item: item, row: row}, 'table')
                } else {
                    this.createRow(item, row)
                }
            },
            createRow(item, row, answer){
                if (!answer) {
                    this.answer = this.$route.query.answer
                } else {
                    this.answer = answer
                }
                let row_id = this.answers[this.answers.findIndex(a => a.question_id === item.question_id)].tables[row].id
                if (!row_id){
                    this.axios
                        .post('/api/v1/answer_rows', { answer_row: { answer_id: this.answer, question_id:  item.question_id }})
                        .then(response => ( this.setRow(item, row, response.data.id )))
                        .catch(error => {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch("logout")
                            } else {
                                console.log(error);
                                this.errored = true;
                            }
                        })
                } else {
                    this.createColumn(item, row, row_id)
                }
            },
            setRow(item, row, row_id){
                let columns = this.answers[this.answers.findIndex(a => a.question_id === item.question_id)].tables[row].columns
                Vue.set(this.answers[this.answers.findIndex(a => a.question_id === item.question_id)].tables, row, {id: row_id, columns: columns})
                this.createColumn(item, row, row_id)
            },
            createColumn(item, row, idrow){

                if (item.id) {
                    this.axios
                        .patch('/api/v1/answer_tables/' + item.id  , { answer_table: {answer_row_id: idrow, table_column_id: item.table_column_id, value: item.value }})
                        .catch(error => {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch("logout")
                            } else {
                                console.log(error);
                                this.errored = true;
                            }
                        })
                } else {
                    this.axios
                        .post('/api/v1/answer_tables', { answer_table: {answer_row_id: idrow, table_column_id: item.table_column_id, value: item.value }})
                        .then(response => (
                            //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                            Vue.set(
                                this.answers[this.answers.findIndex(a => a.question_id === item.question_id)].tables[row].columns,
                                this.answers[this.answers.findIndex(a => a.question_id === item.question_id)].tables[row].columns.findIndex(a => a.table_column_id === item.table_column_id),
                                {id: response.data.id, table_column_id: item.table_column_id, question_id: item.question_id, value: item.value }
                            )
                        ))
                        .catch(error => {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch("logout")
                            } else {
                                console.log(error);
                                this.errored = true;
                            }
                        })
                }

            },
            saveAnswer(item, type) {

                this.axios
                        .post('/api/v1/answers', { answer: {report_id:  this.$route.params.id, user_id: this.$store.state.user_id, finish: false, verification: false }})
                            .then(response => ( this.preperSaveAnswer(item, response.data, type )))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })

            },
            preperSaveAnswer(item, answer, type ){
                this.$set(this.report, 'answer', answer)
                if (type === 'value') {
                    this.saveAnswersquestions(item, answer.id)
                } else if (type === 'table') {
                    this.createRow(item.item, item.row, answer.id)
                }
                this.$router.push({ path: '/forms/' + this.$route.params.id , query: { answer: answer.id } })
            },
            setAnswer(){
                let list =[];
                let answer = this.report.answersquestions
                let answer_rows = this.report.answer_rows
                let districts = this.changeDistrict
                this.report.chapter.map(function (r) {
                    r.questions.map(function (q) {
                        if (q.type_field===7){
                          if (answer && answer.findIndex(a => a.question_id === q.id) !== -1){
                            districts(Number(answer[answer.findIndex(a => a.question_id === q.id)].value))
                          }
                        }
                        if (q.type_field===7 || q.type_field===4 ||  q.type_field===8 ||  q.type_field===15) {
                            if (!answer || answer.findIndex(a => a.question_id === q.id) === -1) {
                                list.push({question_id: q.id, value: null})
                            } else {
                                list.push({question_id: q.id, id: answer[answer.findIndex(a => a.question_id === q.id)].id, value: Number(answer[answer.findIndex(a => a.question_id === q.id)].value)})
                            }
                        } else  if (q.type_field===11 || q.type_field===6 || q.type_field=== 9) {
                            if (!answer || answer.findIndex(a => a.question_id === q.id) === -1) {
                                list.push({question_id: q.id, value: []})
                            } else {
                                if ( answer[answer.findIndex(a => a.question_id === q.id)].value === '[]' ){
                                  list.push({question_id: q.id,  id: answer[answer.findIndex(a => a.question_id === q.id)].id,  value: []})
                                }
                                else {
                                  list.push({question_id: q.id, id: answer[answer.findIndex(a => a.question_id === q.id)].id, value: answer[answer.findIndex(a => a.question_id === q.id)].value.replace('[','').replace(']','').split(',').map(n =>  Number(n) )})
                                }
                            }
                        } else  if (q.type_field===14) {
                            if (!answer || answer.findIndex(a => a.question_id === q.id) === -1) {
                                list.push({question_id: q.id, value: {begin: null, end: null}})
                            } else {
                                let value = answer[answer.findIndex(a => a.question_id === q.id)].value.replace('{"begin"=>"','').replace(' "end"=>"','').replace('"}','').replace('"','').split(',')
                                list.push({question_id: q.id, id: answer[answer.findIndex(a => a.question_id === q.id)].id, value: {begin: value[0], end: value[1]} })
                            }


                        } else  if (q.type_field===12) {
                            let tables;
                            let table = [];
                            let value_col
                            // let row = this.report.answer_rows[this.report.answer_rows.findIndex(r => r.question_id === q.id)]
                             if (!answer_rows || answer_rows.findIndex(a => a.question_id === q.id) === -1   ) {
                                 q.table_columns.map(function (t) {
                                     table.push({ question_id: q.id, table_column_id: t.id, value: null })
                                 })
                                 tables = [{ id: null, columns: table }]
                             } else {
                                 tables =[];
                                 answer_rows.filter(a => a.question_id === q.id).map(function (r) {
                                     table = []
                                     q.table_columns.map(function (t) {
                                         value_col = r.answer_tables[r.answer_tables.findIndex(i => i.table_column_id === t.id)]

                                         if (value_col) {
                                             if (t.type_column===3 || t.type_column===4) {
                                                table.push({ question_id: q.id, table_column_id: t.id, value:  Number(value_col.value), id:value_col.id})
                                             }
                                               else {
                                                table.push({ question_id: q.id, table_column_id: t.id, value: value_col.value, id: value_col.id})
                                              }
                                         } else {
                                             table.push({ question_id: q.id, table_column_id: t.id, value: null })
                                         }
                                     })
                                     tables.push({id: r.id, columns: table})
                                 })
                             }
                            list.push({question_id: q.id, value: null, tables: tables })
                        } else {
                            if (!answer || answer.findIndex(a => a.question_id === q.id) === -1) {
                                list.push({question_id: q.id, value: null})
                            } else {
                                list.push({question_id: q.id, id: answer[answer.findIndex(a => a.question_id === q.id)].id, value: answer[answer.findIndex(a => a.question_id === q.id)].value})
                            }
                        }

                    })

                })
                this.answers = list

            },
            getReport(id,answer){
                let url;
                if (answer){
                    url = "/api/v1/get_report/" + id + '?answer=' + answer
                }else{
                    url = "/api/v1/get_report/" + id
                }
                this.axios
                    .get(url)
                    .then(response => (
                        this.setReport(response)
                    ))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false))
                    .finally(() => (this.$refs.wizard.activateAll()));
            }
        } ,
        data () {
            return {
                report: {name: null, comments: [], answer: {finish: null} },
                showChat: '',
                message: null,
                regions: [],
                districts: [],
                tvks: [],
                parties: [],
                table_rows: [],
                answers: [],
                answer: null,
                loading: true,
                errored: false,
                pickerOptions1: {
                    shortcuts: [{
                        text: 'Сьогодні',
                        onClick (picker) {
                            picker.$emit('pick', new Date())
                        }
                    },
                        {
                            text: 'Вчора',
                            onClick (picker) {
                                const date = new Date()
                                date.setTime(date.getTime() - 3600 * 1000 * 24)
                                picker.$emit('pick', date)
                            }
                        },
                        {
                            text: 'Тиждень тому',
                            onClick (picker) {
                                const date = new Date()
                                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                                picker.$emit('pick', date)
                            }
                        }]
                },
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        }
    }
</script>
<style>
    el-select {
        width: 100%;
        margin-bottom: 30px;
    }
</style>