<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
<!--                <div class="card-header">-->
<!--                    <h4 class="card-title">{{ report.name }}</h4>-->
<!--                </div>-->
                <div class="card-content">
                    <form class="form-horizontal"  @submit.prevent="toSave">
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Номер звіту</label>
                                <div class="col-sm-10">
                                    <input type="number" v-model="report.number" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Назва звіту</label>
                                <div class="col-sm-10">
                                    <input type="text" v-model="report.name" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                      <div class="form-group">
                        <label class="control-label">Тип Виборів</label>
                        <el-select class="select-default"
                                   size="large"
                                   clearable
                                   placeholder="Single Select"
                                   v-model="report.election_type_id"
                        >
                          <el-option v-for="option in election_type"
                                     class="select-default"
                                     :value="option.id"
                                     :label="option.name"
                                     :key="option.id">
                          </el-option>
                        </el-select>
                      </div>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Опис</label>
                                <div class="col-sm-10">
                                    <ckeditor v-model="report.description" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Хто буде подавати звіт</label>
                                <div class="col-sm-10">
                                    <p class="category">ДТС</p>
                                    <p-switch v-model="report.observ" type="primary" on-text="Так" off-text="Ні"></p-switch>
                                    <p class="category">Кординатор</p>
                                    <p-switch v-model="report.coordinator" on-text="Так" off-text="Ні"></p-switch>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Можливість подачі звіту декілька разів</label>
                                <div class="col-sm-10">
                                    <p class="category"> </p>
                                    <p-switch v-model="report.many" type="primary" on-text="Так" off-text="Ні"></p-switch>
                                </div>
                            </div>
                        </fieldset>
                        <div class="card-footer">
                            <div class="form-group">
                                <div class="col-md-3">
                                    <router-link to="/admin/reports" class="btn btn-default">Назад</router-link>
                                </div>
                                <div class="col-md-9">
                                    <button type="submit" class="btn btn-fill btn-info">
                                        Зберегти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import PSwitch from '../../../../../components/UIComponents/Switch.vue'
    export default {
        name: "Report",
        components: {
            PSwitch
        },
        created() {
            if (this.$route.params.id){
                this.getReport(this.$route.params.id)
            }
            this.getElect()
        },
        methods:{
            getElect(){
              this.axios
                  .get("/api/v1/election_types/" )
                  .then(response => (this.election_type = response.data))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(() => (this.loading = false));
            },
            getReport(id){
                this.axios
                    .get("/api/v1/reports/" + id )
                    .then(response => (this.report = response.data))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            toSave(){

                    if (this.$route.params.id) {
                        this.axios
                            .patch('/api/v1/reports/' + this.$route.params.id , {report: this.report} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: `/admin/reports` })
                            ));


                    } else {
                        this.axios
                            .post('/api/v1/reports', {report: this.report} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: `/admin/reports` })
                            ));
                    }

            }
        } ,
        data () {
            return {
                report: {
                    name: null,
                    number: null,
                    description: null,
                    observ: true,
                    coordinator: false,
                    many: false
                },
                election_type: [],
                loading: true,
                errored: false,
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        }
    }
</script>
<style>

</style>