<template>
    <div class="content-fluid">
        <div class="row">
            <div class="col-md-12">
                <h1>{{article.title}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-html="article.body"></div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <router-link to="/doc/materials" class="btn btn-default">Назад</router-link>
            </div>
        </div>
    </div>
</template>
<script>


    export default {
        name: "Material",
        components: {

        },
        created() {
            if (this.$route.params.id){
                this.getMaterial(this.$route.params.id)
            }
        },
        methods:{
            getMaterial(id){
                this.axios
                    .get('/api/v1/articles/'+ id )
                    .then(response => (this.article = response.data))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            }
        } ,
        data () {
            return {
                article: {
                    title: null,
                    number: null,
                    tags: null,
                    short: null,
                    body: null
                },
                loading: true,
                errored: false,
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        }
    }
</script>
<style>

</style>