import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import Home from '../components/Dashboard/Views/Dashboard/Home.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
import GoogleForms  from '../components/GeneralViews/GoogleForms'

// Pages
import Login from '../components/Dashboard/Views/Pages/Login.vue'
import Register from '../components/Dashboard/Views/Pages/Register.vue'

import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// Dashboard pages
import Overview from '../components/Dashboard/Views/Dashboard/Overview.vue'
import Stats from '../components/Dashboard/Views/Dashboard/Stats.vue'
import Reports from '../components/Dashboard/Views/Dashboard/Reports/Reports.vue'
import Report from '../components/Dashboard/Views/Dashboard/Reports/Report.vue'
import Chapters from '../components/Dashboard/Views/Dashboard/Chapters/Chapters.vue'
import Chapter from '../components/Dashboard/Views/Dashboard/Chapters/Chapter.vue'
import Questions from '../components/Dashboard/Views/Dashboard/Questions/Questions.vue'
import Question from "../components/Dashboard/Views/Dashboard/Questions/Question.vue"
import Answeroptions from "../components/Dashboard/Views/Dashboard/Answeroptions/Answeroptions.vue"
import Answeroption from "../components/Dashboard/Views/Dashboard/Answeroptions/Answeroption.vue"
import TableColumns from "../components/Dashboard/Views/Dashboard/TableColumns/TableColumns.vue"
import TableColumn from "../components/Dashboard/Views/Dashboard/TableColumns/TableColumn.vue"
import Options from "../components/Dashboard/Views/Dashboard/Options/Options.vue"
import Option from "../components/Dashboard/Views/Dashboard/Options/Option.vue"
import Articles from "../components/Dashboard/Views/Dashboard/Articles/Articles.vue"
import Article from "../components/Dashboard/Views/Dashboard/Articles/Article.vue"
import Informs from "../components/Dashboard/Views/Dashboard/Informs/Informs.vue"
import Inform from "../components/Dashboard/Views/Dashboard/Informs/Inform.vue"

import Materials from "../components/Dashboard/Views/Dashboard/Materials/Materials.vue"
import Material from "../components/Dashboard/Views/Dashboard/Materials/Material.vue"

import Parties from "../components/Dashboard/Views/Dashboard/Parties/Parties.vue"
import Party from "../components/Dashboard/Views/Dashboard/Parties/Party.vue"

import Securities from "../components/Dashboard/Views/Dashboard/Securities/Securities.vue"
import Security from "../components/Dashboard/Views/Dashboard/Securities/Security.vue"
import Forms from "../components/Dashboard/Views/Dashboard/Forms/Forms.vue"
import Form from "../components/Dashboard/Views/Dashboard/Forms/Form.vue"
import AdminForms from "@/components/Dashboard/Views/Dashboard/Forms/AdminForms";


import Incidents from "../components/Dashboard/Views/Dashboard/Incidents/Incidents.vue"
import Incident from "../components/Dashboard/Views/Dashboard/Incidents/Incident.vue"
import IncidentTypes from "@/components/Dashboard/Views/Dashboard/IncidentTypes/IncidentTypes";
import IncidentType from "@/components/Dashboard/Views/Dashboard/IncidentTypes/IncidentType";
import IncidentSubTypes from "@/components/Dashboard/Views/Dashboard/IncidentSubTypes/IncidentSubTypes";
import IncidentSubType from "@/components/Dashboard/Views/Dashboard/IncidentSubTypes/IncidentSubType";
import Users from "../components/Dashboard/Views/Dashboard/Users/Users.vue"
import User from "@/components/Dashboard/Views/Dashboard/Users/User";

import Observers from "../components/Dashboard/Views/Dashboard/Observers/Observers.vue"
import Events from "../components/Dashboard/Views/Dashboard/Events/Events"
import Event from "../components/Dashboard/Views/Dashboard/Events/Event"
import EventTypes from "@/components/Dashboard/Views/Dashboard/EventTypes/EventTypes";
import EventType from "@/components/Dashboard/Views/Dashboard/EventTypes/EventType";
import ElectionTypes from "@/components/Dashboard/Views/Dashboard/ElectionTypes/ElectionTypes";
import ElectionType from "@/components/Dashboard/Views/Dashboard/ElectionTypes/ElectionType";
import ListPolice from "../components/Dashboard/Views/Dashboard/Police/ListPolice"
import Police from "../components/Dashboard/Views/Dashboard/Police/Police";

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    component: DashboardLayout,
    meta: {
    breadcrumb: 'Головна',
      requiresAuth: true
    },
    redirect: '/home',
    children: [
      {
        path: 'update',
        component: GoogleForms,
        name: 'Пкращити',
        breadcrumb: 'Пкращити',
      },
      {
        path: 'admin_forms',
        name: 'модернація Звітів',
        meta: {
          breadcrumb: 'модернація Звітів',
          requiresAuth: true},
        children: [
          {
            path: ':id',
            name: 'Перегляд звіту',
            meta: {
              breadcrumb: 'Перегляд звіту'
            },
            component: Form
          }
        ],
        component: AdminForms
      },
      {
        path: 'home',
        name: 'Головна',
        breadcrumb: 'Головна',
        component: Home
      },
      {
        path: 'forms',
        name: 'Звіти',
        meta: {
        breadcrumb: 'Звіти',
          requiresAuth: true},
        children: [
          {
            path: ':id',
            name: 'Заповнити звіт',
            meta: {
              breadcrumb: 'Заповнити звіт'
            },
            component: Form
          }
        ],
        component: Forms
      },
      {
        path: 'incidents',
        name: 'Порушеня',
        meta: {
          breadcrumb: 'Порушеня',
          requiresAuth: true},
        children: [
          {
            path: 'new',
            name: 'Додати нове порушеня',
            meta: {
              breadcrumb: 'Додати нове порушеня'
            },
            component: Incident
          },
          {
            path: ':id',
            name: 'Редагувати порушеня',
            meta: {
              breadcrumb: 'Редагувати порушеня'
            },
            component: Incident
          },
        ],
        component: Incidents
      },
      {
        path: 'procedurs',
        name: 'Заяви до плоіції',
        meta: {
          breadcrumb: 'Заяви до плоіції',
          requiresAuth: true},
        children: [
          {
            path: 'new',
            name: 'Додати нову заяву',
            meta: {
              breadcrumb: 'Додати нову заяву до поліції'
            },
            component: Police
          },
          {
            path: ':id',
            name: 'Редагувати зявву',
            meta: {
              breadcrumb: 'Редагувати заяву до поліції'
            },
            component: Police
          },
        ],
        component: ListPolice
      },
      {
        path: 'eventreports',
        name: 'Події',
        meta: {
          breadcrumb: 'Події',
          requiresAuth: true},
        children: [
          {
            path: 'new',
            name: 'Додати нову подію',
            meta: {
              breadcrumb: 'Додати нову подію'
            },
            component: Event
          },
          {
            path: ':id',
            name: 'Редагувати подію',
            meta: {
              breadcrumb: 'Редагувати подію'
            },
            component: Event
          },
        ],
        component: Events
      },
    ]
  },
  {
    path: '/doc',
    component: DashboardLayout,
    redirect: '/doc/materials',
    meta: {
      breadcrumb: 'Документи',
      requiresAuth: true
    },
    children: [
      {
        path: 'materials',
        name: 'Матеріали',
        meta: {
          breadcrumb: 'Матеріали'},
        component: Materials,
        children: [
          {
            path: ':id',
            name: 'Перегляд Матеріалу',
            meta: {
              breadcrumb: 'Перегляд Матеріалу'},
            component: Material,
          }
        ]
      },
      {
        path: 'security',
        name: 'Безпкеові питання',
        meta: {
          breadcrumb: 'Безпкеові питання',
          requiresAuth: true
        },
        children: [
          {
            path: ':id',
            name: 'Перегляд Безпкового питання',
            meta: {
              breadcrumb: 'Перегляд'},
            component: Security,
          }
        ],
        component: Securities
      }
    ]
  },
  {
    path: '/admin',
    component: DashboardLayout,
    meta: {breadcrumb: 'Адміністрування', requiresAuth: true},
    redirect: '/admin/reports',
    children: [
      {
        path: 'reports',
        meta: {
          breadcrumb: 'Адміністрування Звіти'
        },
        name: 'Адміністрування Звіти',
        component: Reports,
        children: [
          {
            path: 'new',
            name: 'Додати Новий Звіт',
            meta: {
              breadcrumb: 'Додати Новий Звіт'
            },
            component: Report
          },
          {
            path: ':id',
            name: 'Редагувати Звіт',
            meta: {
              breadcrumb: 'Редагувати Звіт'
                },
            component: Report,
          },
          {
            path: ":report_id/chapters",
            name: "Розділи",
            meta: {
              breadcrumb: 'Розділи'
            },
            children: [
              {
                path: 'new',
                name: 'Додати Новий Розділ',
                meta: {
                  breadcrumb: 'Додати Новий Розділ'
                },
                component: Chapter
              },
              {
                path: ':id',
                name: 'Редагувати Розділ',
                meta: {
                  breadcrumb: 'Редагувати Розділ'
                },
                component: Chapter,
              },
              {
                path: ":chapter_id/questions",
                name: "Запитання",
                meta: {
                  breadcrumb: 'Запитання'
                },
                children: [
                  {
                    path: 'new',
                    name: 'Додати Нове Запитання',
                    meta: {
                      breadcrumb: 'Додати Нове Запитання'
                    },
                    component: Question
                  },
                  {
                    path: ':id',
                    name: 'Редагувати Запитання',
                    meta: {
                      breadcrumb: 'Редагувати Запитання'
                    },
                    component: Question
                  },
                  {
                    path: ":question_id/answeroptions",
                    name: "Опції",
                    meta: {
                      breadcrumb: 'Опції'
                    },
                    children: [{
                      path: 'new',
                      name: 'Додати Нову Опцію',
                      meta: {
                        breadcrumb: 'Додати Нову Опцію'
                      },
                      component: Answeroption
                    },
                    {
                    path: ':id',
                    name: 'Редагувати Опцію',
                    meta: {
                      breadcrumb: 'Редагувати Опцію'
                    },
                    component: Answeroption
                  },],
                  component: Answeroptions
                  },
                  {
                    path: ":question_id/table_columns",
                    name: "Колонки",
                    meta: {
                      breadcrumb: 'Колонки'
                    },
                    children: [{
                      path: 'new',
                      name: 'Додати Нову Колонку',
                      meta: {
                        breadcrumb: 'Додати Нову Колонку'
                      },
                      component: TableColumn
                    },
                    {
                      path: ':id',
                      name: 'Редагувати Опцію Колонки',
                      meta: {
                        breadcrumb: 'Редагувати Опцію Колонки'
                      },
                      component: TableColumn
                    },
                    {
                      path: ":table_column_id/options",
                      name: "Опції колонки",
                      meta: {
                        breadcrumb: 'Опції колонки'
                      },
                        children: [{
                          path: 'new',
                          name: 'Додати Нову Опцію колонки',
                          meta: {
                            breadcrumb: 'Додати Нову Опцію колонки'
                          },
                          component: Option
                        },
                          {
                            path: ':id',
                            name: 'Редагувати Опцію колонки',
                            meta: {
                              breadcrumb: 'Редагувати Опцію колонки'
                            },
                            component: Option
                          },],
                        component: Options
                      },
                    ],
                    component: TableColumns
                  },
                ],
                component: Questions
              }
            ],
            component: Chapters
          }
        ]
      },
      {
        path: 'incident_type',
        name: 'Типи порушень',
        meta: {breadcrumb: 'Типи порушень'},
        children: [
          {
            path: 'new',
            name: 'Додати Тип порушеня',
            meta: {breadcrumb: 'Додати Тип порушеня'},
            component: IncidentType
          },
          {
            path: ':id',
            name: 'Редагувати Тип порушеня',
            meta: {breadcrumb: 'Редагувати Тип порушеня'},
            component: IncidentType
          },
          {
            path: ':incident_type/sub_type',
            name: 'Підтипи порушеня',
            meta: {breadcrumb: 'Підтипи порушеня'},
            children: [
              {
                path: 'new',
                name: 'Додати Підтипи порушеня',
                meta: {breadcrumb: 'Додати Підтипи порушеня'},
                component: IncidentSubType
              },
              {
                path: ':id',
                name: 'Редагувати Підтипи порушеня',
                meta: {breadcrumb: 'Редагувати Підтипи порушеня'},
                component: IncidentSubType
              }
            ],
            component: IncidentSubTypes
          }
        ],
        component: IncidentTypes
      },
      {
        path: 'event_type',
        name: 'Адміністрування Типи подій',
        meta: {breadcrumb: 'Типи подій'},
        children: [
          {
            path: 'new',
            name: 'Додати Тип події',
            meta: {breadcrumb: 'Додати Тип події'},
            component: EventType
          },
          {
            path: ':id',
            name: 'Редагувати Тип події',
            meta: {breadcrumb: 'Редагувати Тип події'},
            component: EventType
          }
        ],
        component: EventTypes
      },
      {
        path: 'election_type',
        name: 'Адміністрування Типи Виборів',
        meta: {breadcrumb: 'Типи виборів'},
        children: [
          {
            path: 'new',
            name: 'Додати Тип виборів',
            meta: {breadcrumb: 'Додати Тип виборів'},
            component: ElectionType
          },
          {
            path: ':id',
            name: 'Редагувати Тип виборів',
            meta: {breadcrumb: 'Редагувати Тип виборів'},
            component: ElectionType
          }
        ],
        component: ElectionTypes
      },
      {
        path: 'parties',
        name: 'Адміністрування Партії',
        meta: {breadcrumb: 'Партії'},
        children: [
          {
            path: 'new',
            name: 'Додати Партію',
            meta: {breadcrumb: 'Додати Партію'},
            component: Party
          },
          {
            path: ':id',
            name: 'Редагувати Партію',
            meta: {breadcrumb: 'Редагувати Партію'},
            component: Party
          }
        ],
        component: Parties
      },
      {
        path: 'dashboard',
        name: 'Адміністрування Матеріали',
        meta: {breadcrumb: 'Інф. повідомлення'},
        children: [
          {
            path: 'new',
            name: 'Додати матеріал',
            meta: {breadcrumb: 'Додати Матеріал'},
            component: Inform
          },
          {
            path: ':id',
            name: 'Редагувати матеріал',
            meta: {breadcrumb: 'Редагувати Матеріал'},
            component: Inform
          }
        ],
        component: Informs
      },
      {
        path: 'articles',
        name: 'Адміністрування Статті',
        meta: {breadcrumb: 'Статті'},
        children: [
          {
            path: 'new',
            name: 'Додати Нову статью',
            meta: {
              breadcrumb: 'Додати Нову статью'
            },
            component: Article
          },
          {
            path: ':id',
            name: 'Редагувати статью',
            meta: {
              breadcrumb: 'Редагувати статью'
            },
            component: Article
          },
        ],
        component: Articles
      },
      {
        path: 'observers',
        name: 'Спостерігачі',
        meta: {breadcrumb: 'Спостерігачі'},
        component: Observers
      },
      {
        path: 'users',
        name: 'Корситувачі',
        meta: {breadcrumb: 'Корситувачі'},
        children: [
          {
            path: 'new',
            name: 'Створити користувача',
            meta: {
              breadcrumb: 'Створити користувача'},
            component: User,
          },
          {
            path: ':id',
            name: 'Редагувати користувача',
            meta: {
              breadcrumb: 'Редагувати користувача'},
            component: User,
          }
        ],
        component: Users
      },
    ]
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'stats',
        name: 'Stats',
        component: Stats
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset_password',
    name: 'Register',
    component: Register
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active'
})
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})
export default router
