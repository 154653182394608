<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
<!--                <div class="card-header">-->
<!--                    <h4 class="card-title">{{ report.name }}</h4>-->
<!--                </div>-->
                <div class="card-content">
                    <form class="form-horizontal"  @submit.prevent="toSave">
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Номер Колонки</label>
                                <div class="col-sm-10">
                                    <input type="number" v-model="table_column.number" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Назва Колонки</label>
                                <div class="col-sm-10">
                                    <input type="text" v-model="table_column.name" class="form-control">
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Тип питання</label>
                                <div class="col-sm-6">
                                    <el-select class="select-primary"
                                               size="large"
                                               placeholder="Виберіть тип питання"
                                               v-model="table_column.type_column">
                                        <el-option v-for="option in selects.type"
                                                   class="select-danger"
                                                   :value="option.value"
                                                   :label="option.label"
                                                   :key="option.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </fieldset>

                        <div class="card-footer">
                            <div class="form-group">
                                <div class="col-md-3">
                                    <router-link :to="`/admin/reports/`+ $route.params.report_id + `/chapters/`+ $route.params.chapter_id + '/questions/'+ $route.params.question_id +'/table_columns'" class="btn btn-default">Назад</router-link>
                                </div>
                                <div class="col-md-9">
                                    <button type="submit" class="btn btn-fill btn-info">
                                        Зберегти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import {Select, Option} from 'element-ui'
    export default {
        name: "TableColumn",
        components: {
            [Option.name]: Option,
            [Select.name]: Select,
        },
        created() {
            if (this.$route.params.id){
                this.getTableColumn(this.$route.params.id)
            }
        },
        methods:{
            getTableColumn(id){
                this.axios
                    .get("/api/v1/reports/" + this.$route.params.report_id  + "/chapters/" + this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id +'/table_columns/' + id)
                    .then(response => (this.table_column = response.data))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
                console.log('Call Api')
            },
            toSave(){

                    if (this.$route.params.id) {
                        this.axios
                            .patch("/api/v1/reports/" + this.$route.params.report_id  + "/chapters/" + this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id +'/table_columns/' + this.$route.params.id , {table_column: this.table_column} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: `/admin/reports/` + this.$route.params.report_id  + '/chapters/'})
                            ));


                    } else {
                        this.axios
                            .post("/api/v1/reports/" + this.$route.params.report_id  + "/chapters/" + this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id +'/table_columns', {table_column: this.table_column} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: `/admin/reports/` + this.$route.params.report_id  + '/chapters/' + this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id +'/table_columns/'})
                            ));
                    }

            }
        } ,
        data () {
            return {
                selects: {
                    type: [
                        // [['Numeric', 0], ['String', 1], ['Text', 2], ['Date', 3], ['Selects', 4], ['Radio-buttons', 5],
                        // ['Check-boxes', 6], ['Regions', 7], ['OVK', 8], ['OVKs', 9], ['Description', 10], ['Select-nested', 11],  ['Table', 12], ['Party', 13]]
                        {value: 0, label: 'Цифра'},
                        {value: 1, label: 'Строка'},
                        {value: 2, label: 'Техт'},
                        {value: 3, label: 'Список'},
                        {value: 4, label: 'Партії'}
                    ]
                },
                table_column: {
                    name: null,
                    number: null,
                    type_column: null,
                    question_id: this.$route.params.question_id
                },
                loading: true,
                errored: false,
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        }
    }
</script>
<style>

</style>