<template>
  <div class="row">

      <div class="card">
        <div class="col-md-6">
          <div class="card">
            <form  @submit.prevent="toSave">
              <div class="card-header">
                <h4 class="card-title"></h4>
              </div>
              <div class="card-content">
                <div class="form-group">
                  <label>Прізвище Ім'я Побатькові</label>
                  <input type="text" v-model="user.name" required placeholder="Введіть прізвище ім'я побатькові" class="form-control">
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input type="email" v-model="user.email" required placeholder="Введіть  email" class="form-control">
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input type="password" v-model="password" v-if="$route.params.id"  placeholder="Пароль" class="form-control">
                  <input type="password" v-model="password"  required v-if="!$route.params.id"  placeholder="Пароль" class="form-control">
                </div>
                <div class="form-group">
                  <label>Роль</label>
                  <el-select class="select-primary"
                             size="large"
                             placeholder="Виберіть тип"
                             required
                             v-model="user.role">
                    <el-option v-for="option in roles"
                               class="select-danger"
                               :value="option.value"
                               :label="option.text"
                               :key="option.value">
                    </el-option>
                  </el-select>
                </div>
                <button @click.stop.prevent="addRegion()" class="btn btn-primary"><i class="ti-plus"></i> Додати область</button>
                <div class="form-group well" v-for="reg in user.user_regions" :key="reg.id">
                  <label>Область</label>
                  <el-select class="select-primary"
                             size="large"
                             placeholder="Виберіть тип"
                             required
                             v-model="reg.region_id">
                    <el-option v-for="option in regions"
                               class="select-danger"
                               :value="option.id"
                               :label="option.name"
                               :key="option.id">
                    </el-option>
                  </el-select>
                </div>
                <div class="form-group">
                  <div class="col-sm-4 col-sm-offset-1">
                    <label>Адміністратор</label>
                    <p-switch v-model="user.admin" type="primary" on-text="Так" off-text="Ні"></p-switch>
                  </div>
                  <label>Модератор</label>
                  <p-switch v-model="user.moderator" type="primary" on-text="Так" off-text="Ні"></p-switch>
                </div>

                <div class="form-group">
                    <router-link to="/admin/users" class="btn btn-default">Назад</router-link>
                    <button type="submit" class="btn btn-fill btn-info pull-right">
                      Зберегти
                    </button>
                </div>
              </div>
            </form>
          </div> <!-- end card -->
        </div> <!--  end col-md-6  -->
      </div>
    </div>
</template>
<script>
import Vue from  'vue'
import PSwitch from '../../../../../components/UIComponents/Switch.vue'
import {Select, Option} from 'element-ui'
export default {
  name: "User",
  components: {
    PSwitch,
    [Option.name]: Option,
    [Select.name]: Select,

  },
  created() {
    if (this.$route.params.id){
      this.getUser(this.$route.params.id)
    }

  },
  mounted() {
    this.getRegions()
  },
  watch: {
    password: 'setChangePassword'
  },
  methods:{
    addRegion(){
      this.user.user_regions.push({user_id: this.$route.params.id, id: null, region_id: null })
    },
    setChangePassword(){
      Vue.set(this.user, 'password', this.password )
      Vue.set(this.user, 'password_confirmation', this.password )
    },
    getUser(id){
      this.axios
          .get('/api/v1/user/'+ id )
          .then(response => (this.user = response.data))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
          .finally(() => (this.loading = false));
    },
    toSave(){
      if (this.$route.params.id) {
        this.axios
            .patch('/api/v1/user/'+ this.$route.params.id , {user: this.user} )
            .then(response => ( this.user = response.data))
            .catch(error => {
              if (error.response && error.response.status === 401) {
                this.$store.dispatch("logout")
              } else {
                console.log(error);
                this.errored = true;
              }
            })
            .finally(() => (
                this.$router.push({ path: '/admin/users' })
            ));


      } else {
        this.axios
            .post('/api/v1/user/', { user: this.user} )
            .then(response => ( this.user = response.data))
            .catch(error => {
              if (error.response && error.response.status === 401) {
                this.$store.dispatch("logout")
              } else {
                console.log(error);
                this.errored = true;
              }
            })
            .finally(() => (
                this.$router.push({ path: '/admin/users' })
            ));
      }

    },
    getRegions(){
      this.axios
          .get('/api/v1/regions')
          .then(response => (
              this.regions = response.data
          ))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
    }
  } ,
  data () {
    return {
      user: {
        name: "",
        email: '',
        role: "",
        admin: false,
        moderator: false,
        user_regions: [
          {
            region_id: ''
          }
        ]
      },
      regions: [],
      roles: [
        {
          text: "Адміністартор",
          value: "Admin"
        },
        {
          text: "Пресслужба",
          value: "Public"
        },
        {
          text: "Аналітик",
          value: "analyst"
        },
        {
          text: "Юрист",
          value: "lawyer"
        },
        {
          text: "Регіональний",
          value: "Regional"
        },
        {
          text:"Обласний",
          value: "coordinator"
        },
        {
          text: "ДТС",
          value: "observ"
        }
      ],
      password: "",
      loading: true,
      errored: false,
      editorConfig: {
        // The configuration of the editor.
      }
    }
  }
}
</script>
<style>

</style>