<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button class="btn btn-fill btn-icon" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'ti-menu-alt' : 'ti-more-alt'"></i>
        </button>
      </div>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}" @click="toggleSidebar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
        <a class="navbar-brand">{{this.$route.name}}</a>
      </div>
      <div class="collapse navbar-collapse">
<!--        <form class="navbar-form navbar-left navbar-search-form" role="search">-->
<!--          <div class="input-group">-->
<!--            <input type="text" value="" class="form-control" placeholder="Search...">-->
<!--            <span class="input-group-addon"><i class="fa fa-search"></i></span>-->
<!--          </div>-->
<!--        </form>-->
        <ul class="nav navbar-nav navbar-right">
          <li class="open">

<!--            <router-link to="/admin/stats" class="dropdown-toggle btn-magnify" data-toggle="dropdown">-->
<!--              <i class="ti-panel"></i>-->
<!--              <p>Stats</p>-->
<!--            </router-link>-->
          </li>
            <li>
                <a>{{ $store.state.uid}}</a>
            </li>
<!--          <drop-down tag="li" title="5" icon="ti-bell">-->
<!--            <li><a href="#">Notification 1</a></li>-->
<!--            <li><a href="#">Notification 2</a></li>-->
<!--            <li><a href="#">Notification 3</a></li>-->
<!--            <li><a href="#">Notification 4</a></li>-->
<!--            <li><a href="#">Another notification</a></li>-->
<!--          </drop-down>-->
          <li>
              <a v-if="isLoggedIn()" @click="logout" class="btn-rotate"><i class="ti-power-off"></i> Вийти</a>
<!--            <router-link to="/admin/overview" class="btn-rotate">-->
<!--              <i class="ti-power-off"></i>-->
<!--            </router-link>-->
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  export default {
    data () {
      return {
        activeNotifications: false
      }
    },
    computed : {

      },
    methods: {
        isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login')
                })
        },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      }
    }
  }

</script>
<style>

</style>
