<template>
  <div class="row" v-if="$store.state.role !=='Public'">
  <div class="card-content row"  v-if="$route.path==='/admin_forms'">
    <div class="col-sm-6">
        <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
            <el-option
                    class="select-default"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
        </el-select>
    </div>
    <div class="col-sm-6">
        <div class="pull-right">
            <label>
<!--                <input type="search" class="form-control input-sm" placeholder="Search records" v-model="searchQuery" aria-controls="datatables">-->
            </label>
        </div>
    </div>
    <div class="col-sm-12">
<!--    <el-button @click="resetDateFilter">Скинути фільтр дати</el-button>-->
    <el-button @click="clearFilter">Скинути фільтри</el-button>
    <el-table
            ref="filterTable"
            :data="tableData"
            @filter-change="filterChange"
            @sort-change="sortChange"
            :default-sort="{prop: 'answers.id', order: 'descending'}"
            style="width: 100%">
      <el-table-column
          prop="answers.id"
          sortable
          label="Id"
          width="100">
        <template slot-scope="scope"><router-link :to="{path:`/admin_forms/` + scope.row.report_id , query: { answer: scope.row.id, admin: 1 }}" >
          <i class="ti-check-box"></i> {{scope.row.id }}
        </router-link></template>
      </el-table-column>
        <el-table-column
                v-if="regions.length>1"
                prop="region_id"
                column-key="region_id"
                sortable
                label="Область"
                width="130"
                :filters="regionFilters"
                :filter-method="filterRegionTag"
                filter-placement="bottom-end">
          <template slot-scope="scope">
            <p v-for="(reg, i) in scope.row.region" :key="i">{{ regions[regions.findIndex(r => r.id === reg)].name }}</p>
          </template>
        </el-table-column>
      <el-table-column
          prop="report_id"
          label="Звіт"
          sortable
          width="110"
          column-key="report"
          :filters="ReportsFilter"
          :filter-method="filterReport"
          filter-placement="bottom-end">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.report_name" placement="top-start">
            <el-button>{{ scope.row.report_number }}</el-button>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column
          prop="answers.election"
          label="Тип Виборів"
          width="180">
        <template slot-scope="scope">
          {{ scope.row.election }}
        </template>
      </el-table-column>
      <el-table-column
          prop="answers.updated_at"
          label="Дата час"
          sortable
          width="110"
          column-key="updated_at">
        <!--                :filters="[{text: '2016-05-01', value: '2016-05-01'}, {text: '2016-05-02', value: '2016-05-02'}, {text: '2016-05-03', value: '2016-05-03'}, {text: '2016-05-04', value: '2016-05-04'}]"-->
        <!--                :filter-method="filterHandler">-->

        <template slot-scope="scope">
          {{ scope.row.updated_at |  formatDate }}
        </template>
      </el-table-column>
      <el-table-column
          prop="answers.user_id"
          sortable
          label="Автор"
          width="180">
        <template slot-scope="scope">
          {{ scope.row.user }}
        </template>
      </el-table-column>
        <el-table-column
                prop="finish"
                column-key="finish"
                label="Закінчено"
                width="100"
                :filters="[{text: 'Так', value: true}, {text: 'Ні', value: false}]"
                :filter-method="filterFinish"
                filter-placement="bottom-end">
            <template slot-scope="scope">
              <el-tag
                  :type="scope.row.finish ? 'success' : 'info'"
                  disable-transitions>
              <i v-if="scope.row.finish" class="el-icon-circle-check"></i>
              <i v-else class="el-icon-upload"></i>
              </el-tag>
            </template>
        </el-table-column>
        <el-table-column
                prop="verification"
                column-key="verification"
                label="Верефіковано"
                width="150"
                :filters="[{text: 'Так', value: true}, {text: 'Ні', value: false}]"
                :filter-method="filterVerification"
                filter-placement="bottom-end"
                >
            <template slot-scope="scope" >
                <el-tag
                        :type="scope.row.verification === 'Home' ? 'primary' : 'info'"
                        disable-transitions>
                  <i class="el-icon-s-flag"></i>
                </el-tag>
            </template>
        </el-table-column>

      <el-table-column
          prop="id"
          label="Редагувати"
          width="100">
        <template slot-scope="scope">
          <router-link :to="{path:`/admin_forms/` + scope.row.report_id , query: { answer: scope.row.id, admin: 1 }}" class="btn btn-default">
            <i class="ti-check-box"></i>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="Видалення"
          v-if="$store.state.admin || $store.state.role==='Regional'"
          width="100">
        <template slot-scope="scope">
          <button @click="removeAnswer(scope.row.id)" class="btn btn-danger btn-fill">
            Видалити
          </button>
        </template>
      </el-table-column>

    </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
        <p class="category">Показано від {{from + 1}} до {{to}} з {{total}}</p>
    </div>
    <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total">
        </p-pagination>
    </div>

</div>
  <router-view></router-view>
</div>
</template>
<script>
    import Vue from 'vue'
    import {Table, TableColumn, Select, Option, Button, Tag, Tooltip} from 'element-ui'
    import PPagination from "../../../../UIComponents/Pagination.vue"
    import moment from "moment";
    import swal from "sweetalert2";
    //import event_types from "./event_types";
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Button)
    Vue.use(Tag)
    Vue.use(Tooltip)
    export default {
        name: 'AdminForms',
        components: {
            PPagination
        },

        computed: {
          ReportsFilter(){
            let list=[];
            this.reports.forEach(function (r){
              list.push({text: r.name, value: r.id })
            })
            return list
          },
          regionFilters(){
            let list=[];
            this.regions.forEach(function (r){
              list.push({text: r.name, value: r.id })
            })
            return list
          },
          currentPage(){
            return this.pagination.currentPage
          },
          perPage(){
            return this.pagination.perPage
          },
          to () {
            let highBound = this.from + this.pagination.perPage
            if (this.total < highBound) {
              highBound = this.total
            }
            return highBound
          },
          from () {
            return this.pagination.perPage * (this.pagination.currentPage - 1)
          },
          total () {
            return this.pagination.total
          },
        },
      filters: {
        formatDate(value) {
          if (value) {
            return moment(String(value)).format('DD.MM.YYYY HH:MM:ss')
          }
        }
      },
        created() {
          this.getForms()
        },
        mounted() {
          this.getRegions()
          this.getReports()
        },

      watch: {
          $route: 'getForms',
          perPage: 'getForms',
          currentPage:  'getForms',
      },
        methods: {
          removeAnswer(id){
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете видалити звіт?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так, видалити його!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .delete('/api/v1/answers/' + id )

                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Видалено!',
                            'Звіт успішно видалено.',
                            'success'
                        ),
                      this.getForms()
                    ));

              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'звіт збережено :)',
                    'error'
                )
              }
            })
          },
          getReports(){
            this.axios
                .get('/api/v1/get_reports')
                .then(response => (
                    this.reports = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          filterChange(states){
            let property = Object.keys(states)[0]
            let values = Object.values(states).join(',')
            if (property==='region_id') {
              this.region_id = values
            } else if (property==='finish'){
              this.finish = values
            } else if (property==='verification'){
                this.verification = values
            } else {
              this.report = values
            }
            this.getForms()
          },
          setRegionId(){
            if ( this.regions.length===1 ){
              this.region_id = this.regions[0].id
            }
          },
          getRegions(){
            this.axios
                .get('/api/v1/regions')
                .then(response => (
                    this.regions = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(()=>(
                    this.setRegionId()
                ))
          },
          sortChange(sortProps){
            this.sort = sortProps.prop
            if (sortProps.order ==="ascending")
            {
              this.order= 'asc'
            } else {
              this.order= 'desc'
            }
            this.getForms()
          },
          getForms(){
              this.axios
                  .get('/api/v1/get_answers',  {params: {user_id: this.$store.state.user_id, limit: this.pagination.perPage, offset: this.from, sort: this.sort, order: this.order,
                      region: this.region_id,
                      report: this.report,
                      finish: this.finish,
                      verification: this.verification
                    }})
                  .then(response => (this.tableData = response.data.answers,
                                     this.pagination.total = response.data.total
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(() => (this.loading = false));
            },
            queriedData () {
                if (!this.searchQuery) {
                    this.pagination.total = this.tableData.length
                    return this.pagedData
                }
                let result = this.tableData
                    .filter((row) => {
                        let isIncluded = false
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString()
                            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                                isIncluded = true
                            }
                        }
                        return isIncluded
                    })
                this.pagination.total = result.length
                return result.slice(this.from, this.to)
            },
            resetDateFilter() {
                this.$refs.filterTable.clearFilter('date');
            },
            clearFilter() {
                this.$refs.filterTable.clearFilter();
              if  (this.regions.length > 1 ){
                this.region_id = ''
              }
              this.report= ''
              this.finish= ''
              this.verification= ''
              this.getForms()
            },
            filterTag(value, row) {
                return row.tag === value;
            },
          filterRegionTag(value, row) {
            return row.region.includes(value)
          },
          filterReport(value, row) {
            return row.report_id === value;
          },
          filterFinish(value, row) {
            return row.finish === value;
          },
          filterVerification(value, row) {
            return row.verification === value;
          },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            }
        },
        data(){
            return {
              order: 'desc',
              sort: 'answers.id',
              regions: [],
              region_id: '' ,
              report: '',
              finish: '',
              verification: '',
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                reports: [],
                searchQuery: "",
                propsToSearch: ['name', 'email', 'age'],
                tableData: []
            }
        }
    }
</script>
<style>

</style>