<template>
<div class="card-content row">
    <div class="col-sm-6">
        <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
            <el-option
                    class="select-default"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
        </el-select>
    </div>
    <div class="col-sm-6">
        <div class="pull-right">
            <label>
                <input type="search" class="form-control input-sm" placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
            </label>
        </div>
    </div>
    <div class="col-sm-12">
    <el-button @click="resetDateFilter">reset date filter</el-button>
    <el-button @click="clearFilter">reset all filters</el-button>
    <el-table
            ref="filterTable"
            :data="tableData"
            style="width: 100%">
        <el-table-column
                prop="date"
                label="Date"
                sortable
                width="180"
                column-key="date"
                :filters="[{text: '2016-05-01', value: '2016-05-01'}, {text: '2016-05-02', value: '2016-05-02'}, {text: '2016-05-03', value: '2016-05-03'}, {text: '2016-05-04', value: '2016-05-04'}]"
                :filter-method="filterHandler"
        >
        </el-table-column>
        <el-table-column
                prop="name"
                sortable
                label="Name"
                width="180">
        </el-table-column>
        <el-table-column
                prop="address"
                label="Address"
                :formatter="formatter">
        </el-table-column>
        <el-table-column
                prop="tag"
                label="Tag"
                width="100"
                :filters="[{ text: 'Home', value: 'Home' }, { text: 'Office', value: 'Office' }]"
                :filter-method="filterTag"
                filter-placement="bottom-end">
            <template slot-scope="scope">
                <el-tag
                        :type="scope.row.tag === 'Home' ? 'primary' : 'success'"
                        disable-transitions>{{scope.row.tag}}</el-tag>
            </template>
        </el-table-column>
    </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
        <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
    </div>
    <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total">
        </p-pagination>
    </div>
</div>
</template>
<script>
    import Vue from 'vue'
    import {Table, TableColumn, Select, Option, Button, Tag} from 'element-ui'
    import PPagination from "../../../../UIComponents/Pagination.vue"
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Button)
    Vue.use(Tag)
    export default {
        name: 'Incident',
        components: {
            PPagination
        },
        computed: {

            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total () {
                //this.pagination.total = this.tableData.length
                return this.tableData.length
            },
        },
        created() {

        },
        mounted() {
        },
        methods: {

            queriedData () {
                if (!this.searchQuery) {
                    this.pagination.total = this.tableData.length
                    return this.pagedData
                }
                let result = this.tableData
                    .filter((row) => {
                        let isIncluded = false
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString()
                            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                                isIncluded = true
                            }
                        }
                        return isIncluded
                    })
                this.pagination.total = result.length
                return result.slice(this.from, this.to)
            },
            resetDateFilter() {
                this.$refs.filterTable.clearFilter('date');
            },
            clearFilter() {
                this.$refs.filterTable.clearFilter();
            },
            formatter(row) {
                return row.address;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            }
        },
        data(){
            return {
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: "",
                propsToSearch: ['name', 'email', 'age'],
                tableData: [{
                    date: '2016-05-03',
                    name: 'Tom',
                    address: 'No. 189, Grove St, Los Angeles',
                    tag: 'Home'
                }, {
                    date: '2016-05-02',
                    name: 'Tom',
                    address: 'No. 189, Grove St, Los Angeles',
                    tag: 'Office'
                }, {
                    date: '2016-05-04',
                    name: 'Tom',
                    address: 'No. 189, Grove St, Los Angeles',
                    tag: 'Home'
                }, {
                    date: '2016-05-01',
                    name: 'Iom',
                    address: 'No. 189, Grove St, Los Angeles',
                    tag: 'Office'
                }]
            }
        }
    }
</script>
<style>

</style>