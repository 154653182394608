<template>
    <div class="row">

        <template v-if="$route.path === '/doc/materials'">

        <div class="col-md-6" v-for="material in materials" :key="material.id">
            <time-line type="simple">
                <time-line-item  class="timeline-inverted" :badge-type="material.tags===1 ? 'success' : 'danger'" badgeIcon="ti-gallery" >
                    <span slot="header" class="label label-default">{{ material.title }}</span>
                    <p slot="body" v-html="material.short">

                    </p>

                    <div slot="footer">
                        <div class="row">
<!--                        <div class="col-xs-12  col-md-2">-->
<!--                            <router-link :to="`/admin/reports/` + $route.params.report_id + `/chapters/`+ $route.params.chapter_id +'/questions/' + question.id + '/answeroptions'"  class="btn btn-default">-->
<!--                                <i class="ti-check-box"></i> Перегляд-->
<!--                            </router-link>-->
<!--                        </div>-->


                        <div class="col-xs-12 col-md-2">
                            <router-link :to="'/doc/materials/' + material.id" class="btn btn-simple btn-wd">
                                <i class="ti-book"></i> Читати більше
                            </router-link>
                        </div>

                    </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import TimeLine from './TimeLine.vue'
    import TimeLineItem from './TimeLineItem.vue'
    export default {
        name: "Materials",
        components: {
            TimeLine,
            TimeLineItem },
        data () {
            return {
                materials: null,
                loading: true,
                errored: false
            }
        },
        created() {

            this.getMaterials()
        },
        watch: {
            $route: 'getMaterials'
        },
        methods: {
            getMaterials(){
               if (this.$route.path === '/doc/materials') {
                   this.axios
                       .get('/api/v1/show_article/materials')
                       .then(response => (this.materials = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
               }
            }
        }
    }
</script>
<style>

</style>