<template>
  <div>
    <nav class="navbar navbar-transparent navbar-absolute">
      <div class="container">
        <div class="navbar-header">
<!--          <button type="button" class="navbar-toggle"-->
<!--                  data-toggle="collapse"-->
<!--                  data-target="#navigation-example-2"-->
<!--                  @click="toggleNavbar">-->
<!--            <span class="sr-only">Toggle navigation</span>-->
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
<!--          </button>-->
<!--          <router-link class="navbar-brand" to="/admin">Paper Dashboard PRO</router-link>-->
        </div>
        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
<!--            <router-link to="/register" tag="li">-->
<!--              <a>Register</a>-->
<!--            </router-link>-->
<!--            <router-link to="/admin/overview" tag="li">-->
<!--              <a>Dashboard</a>-->
<!--            </router-link>-->
          </ul>
        </div>
      </div>
    </nav>

    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page" data-color=""
           data-image="static/img/background/background-2.jpg">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <form @submit.prevent="login()">
                  <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                      <h3 class="card-title">Авторизація</h3>
                    </div>
                    <div class="card-content">
                      <div class="form-group">
                        <label>Email address</label>
                        <input required v-model="email" type="email" placeholder="Введіть email" class="form-control input-no-border">
                      </div>
                      <div class="form-group">
                        <label>Пароль</label>
                        <input required v-model="password" type="password" placeholder="Пароль" class="form-control input-no-border">
                      </div>
                    </div>
                    <div class="card-footer text-center">
                      <button type="submit" class="btn btn-fill btn-wd ">Вхід</button>
                      <div class="forgot">
                        <button class="btn btn-simple btn-wd" @click="showSwal">Забули пароль?</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="copyright">
              &copy; Coded with
              <i class="fa fa-heart heart"></i> by
              <a href="https://github.com/cristijora" target="_blank">Cristi Jora</a>.
              Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" target="_blank">Creative Tim</a>.
            </div>
          </div>
        </footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
      </div>
    </div>
    <div class="collapse navbar-collapse off-canvas-sidebar">
      <ul class="nav nav-mobile-menu">
<!--        <router-link to="/register" tag="li">-->
<!--          <a>Register</a>-->
<!--        </router-link>-->
<!--        <router-link to="/admin/overview" tag="li">-->
<!--          <a>Dashboard</a>-->
<!--        </router-link>-->
      </ul>
    </div>
  </div>
</template>
<script>
  import swal from 'sweetalert2'

  export default {
    data(){
      return {
        email : "",
        password : ""
      }
    },
    methods: {
      login() {
        let email = this.email
        let password = this.password
            this.$store.dispatch('login', { email, password })
                .then(() => this.$router.push('/'))  //
                .catch(() => this.$notify(
                        {
                          title: "Первірте Ваш емейл та пароль, або спрбуйте Забути пароль",
                        }))

      },
      showSwal () {
        swal.fire({
          title: 'Введіть Ваш E-mail',
          input: 'email',
          inputAttributes: {
            autocapitalize: 'off'
          },
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Відміна',
          confirmButtonText: 'Відправити',
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            return this.axios
                    .post('/api/v1/auth/password', {email: login} )
                    .then(response => {
                      if (response.status !== 200) {
                        throw new Error(response.statusText)
                      }
                      return response.data
                    })
                    .catch(error => {
                      console.log(error)
                      if (error) {
                        swal.showValidationMessage(
                                `За даною адресою користувача не знайдено: `
                        )
                      }
                    })
          },
          allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
          if (result.value.success === true) {
            swal.fire({
              title: `На ваш емейл відправлено інструкції`,
              icon: 'success'
            })
          }
        })
        // swal.fire(
        //         'Good job!',
        //         'You clicked the button!',
        //         'success'
        // )
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
