export default [
  {
    name: 'Головна',
    icon: 'ti-panel',
    collapsed: false,
    children: [{
      name: 'Головна',
      path: '/home'
    },
      {
        name: 'Звіти',
        path: '/forms'
      },
      {
        name: 'Порушення',
        path: '/incidents'
      },
      {
        name: 'Заяви до поліції',
        path: '/procedurs'
      },
      {
        name: 'Події',
        path: '/eventreports'
      },
      {
        name: 'Покращити',
        path: '/update'
      }
    ]

  },
  {
    name: 'Адміністрування',
    icon: 'ti-view-list-alt',
    children: [{
      name: 'Звіти',
      path: '/admin/reports'
    },
      {
        name: 'Інф. повідомлення',
        path: '/admin/dashboard'
      },
      {
        name: 'Статті',
        path: '/admin/articles'
      },
      {
        name: 'Типи порушень',
        path: '/admin/incident_type'
      },
      {
        name: 'Типи подій',
        path: '/admin/event_type'
      },
      {
        name: 'Типи виборів',
        path: '/admin/election_type'
      },
      {
        name: 'Партії',
        path: '/admin/parties'
      },
      {
        name: 'Спостерігачі',
        path: '/admin/observers'
      },
      {
        name: 'Користувачі',
        path: '/admin/users'
      },
    ]
  },
  {
    name: 'Довідники',
    icon: 'ti-clipboard',
    children: [{
      name: 'Матеріали',
      path: '/doc/materials'
    },
      {
        name: 'Безпека',
        path: '/doc/security'
      }
    ]
  },
  // {
  //   name: 'Table List',
  //   icon: 'ti-view-list-alt',
  //   collapsed: true,
  //   children: [{
  //     name: 'Regular Tables',
  //     path: '/table-list/regular'
  //   },
  //   {
  //     name: 'Extended Tables',
  //     path: '/table-list/extended'
  //   },
  //   {
  //     name: 'Paginated Tables',
  //     path: '/table-list/paginated'
  //   }
  //   ]
  // },
  // {
  //   name: 'Maps',
  //   icon: 'ti-map',
  //   children: [{
  //     name: 'Google Maps',
  //     path: '/maps/google'
  //   },
  //   {
  //     name: 'Full Screen Maps',
  //     path: '/maps/full-screen'
  //   },
  //   {
  //     name: 'Vector Maps',
  //     path: '/maps/vector-map'
  //   }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   icon: 'ti-gift',
  //   path: '/charts'
  // },
  // {
  //   name: 'Calendar',
  //   icon: 'ti-calendar',
  //   path: '/calendar'
  // },
  // {
  //   name: 'Pages',
  //   icon: 'ti-gift',
  //   children: [{
  //     name: 'User Page',
  //     path: '/pages/user'
  //   },
  //   {
  //     name: 'Timeline Page',
  //     path: '/pages/timeline'
  //   },
  //   {
  //     name: 'Login Page',
  //     path: '/login'
  //   },
  //   {
  //     name: 'Register Page',
  //     path: '/register'
  //   },
  //   {
  //     name: 'Lock Page',
  //     path: '/lock'
  //   }
  //   ]
  // }
]
