<template>
    <div class="container-fluid" >
        <div class="col-md-12">
            <p>Запитання: <span v-html="question"> </span></p>
        </div>
        <template v-if=" $route.name === 'Колонки'">
        <div class="row">
            <div class="col-xs-3 col-xs-offset-7">
                <router-link class="btn btn-primary" :to="`/admin/reports/`+ $route.params.report_id + `/chapters/`+ $route.params.chapter_id + '/questions/'+ $route.params.question_id +'/table_columns/new'"><i class="ti-plus"></i> Додати</router-link>
            </div>
        </div>
        <div class="col-md-6" v-for="item in table_columns" :key="item.id">
            <time-line type="simple">
                <time-line-item class="timeline-inverted" badge-type="success" badgeIcon="ti-gallery">
                    <span slot="header" class="label label-danger">{{ item.number }}</span>
                    <span slot="header" class="label label-success">{{ getType(item.type_column) }}</span>
                    <p slot="body">
                        {{ item.name }}
                    </p>

                    <div slot="footer">
                        <div class="row">
                        <div class="col-xs-12  col-md-2" v-if="item.type_column === 3">
                            <router-link :to="`/admin/reports/` + $route.params.report_id + `/chapters/`+ $route.params.chapter_id + '/questions/'+ $route.params.question_id +'/table_columns/' + item.id + `/options`"  class="btn btn-default">
                                <i class="ti-check-box"></i> Опції
                            </router-link>
                        </div>

<!--                        <div class="col-xs-12 col-md-2">-->
<!--                            <button class="btn btn-wd btn-warning btn-fill btn-magnify">-->
<!--                                <i class="ti-camera"></i> Копіювати-->
<!--                            </button>-->
<!--                        </div>-->
                        <div class="col-xs-12 col-md-2">
                            <router-link :to="`/admin/reports/` + $route.params.report_id + '/chapters/' + $route.params.chapter_id + '/questions/'+ $route.params.question_id +'/table_columns/' + item.id" class="btn btn-wd btn-success btn-fill btn-rotate">
                                <i class="ti-pencil"></i> Редагувати
                            </router-link>
                        </div>
                        <div class="col-xs-12 col-md-2">
                            <button class="btn btn-wd btn-danger" @click="removeTableColumn(item.id)">
                                <i class="fa fa-times"></i> Видалити
                            </button>
                        </div>
                    </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    import TimeLine from './TimeLine.vue'
    import TimeLineItem from './TimeLineItem.vue'
    export default {
        name: "TableColumns",
        components: {
            TimeLine,
            TimeLineItem },
        data () {
            return {
                table_columns: null,
                question: null,
                loading: true,
                errored: false
            }
        },
        created() {
            this.getQuestion()
            this.getTableColumns()
        },
        watch: {
            $route: ['getQuestion', 'getTableColumns']
        },
        methods: {
            getType(type){
                const types = [
                    // [['Numeric', 0], ['String', 1], ['Text', 2], ['Date', 3], ['Selects', 4], ['Radio-buttons', 5],
                    // ['Check-boxes', 6], ['Regions', 7], ['OVK', 8], ['OVKs', 9], ['Description', 10], ['Select-nested', 11],  ['Table', 12], ['Party', 13]]
                    {value: 0, label: 'Цифра'},
                    {value: 1, label: 'Строка'},
                    {value: 2, label: 'Техт'},
                    {value: 3, label: 'Список'},
                    {value: 4, label: 'Партії'}
                ]
                return  types.filter( t => t.value === type )[0].label
            },
            getQuestion(){
                this.axios
                    .get("/api/v1/reports/" + this.$route.params.report_id + '/chapters/'+ this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id )
                    .then(response => (this.question = response.data.question))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
                console.log('Call Api getQuestion')
            },
            getTableColumns(){
               if (this.$route.name === 'Колонки') {
                   this.axios
                       .get("/api/v1/reports/" + this.$route.params.report_id  + "/chapters/" + this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id +'/table_columns')
                       .then(response => (this.table_columns = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
                   console.log('Call Api getTableColumns')
               }
            },
            removeTableColumn(id) {

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити розділ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.axios
                            .delete('/api/v1/reports/'+ this.$route.params.report_id + "/chapters/" +  this.$route.params.chapter_id + '/questions/' + this.$route.params.question_id +'/table_columns/' + id )
                            .then(this.table_columns = this.table_columns.filter( r => r.id !== id ))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Колонку успішно видалено.',
                                    'success'
                                )

                            ));
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Колонку збережено :)',
                            'error'
                        )
                    }
                })
            }
        }
    }
</script>
<style>

</style>