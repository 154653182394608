<template>
    <div class="row">
      <button class="btn btn-danger" v-if="$route.params.id && ($store.state.admin || $store.state.role==='lawyer')" @click="removeProcedur()">
      {{procedur.remove ? "Відновити": "Видалити" }}
      </button>
      <div class="card card-wizard" id="wizardCard">
            <div class="card form-horizontal">
              <div class="card-content">
                <div class="form-group">
                  <div class="card-content">
                  <label class="control-label">Дата подання</label>
                  <el-date-picker type="date" placeholder="Виберіть дату"
                                  :picker-options="pickerOptions1" v-model="procedur.date" @change="changeValue()">
                  </el-date-picker>
                  </div>
                </div>
                <div class="card-content">
                  <label class="control-label">Реєстраційний номер документа</label>
                  <input type="text" :disabled="disableEdit()" v-model="procedur.num_doc" @change="changeValue()" class="form-control">
                </div>
                <br>
                <div class="form-group">
                  <div class="card-content">
                  <label class="control-label">Область</label>
                  <el-select class="select-default"
                             :disabled="disableEdit()"
                             size="large"
                             placeholder="Single Select"
                             @change="changeValue()"
                             v-model="procedur.region_id"
                  >
                    <el-option v-for="option in regions"
                               class="select-default"
                               :value="option.id"
                               :label="option.name"
                               :key="option.id">
                    </el-option>
                  </el-select>
                  </div>
                </div>
                <div class="card form-horizontal">
                  <div class="card-header">
                    <h4 class="card-title">Район</h4>
                  </div>
                  <div class="card-content">
                    <div class="well" v-if="procedur.region_id && regions.length > 0">
                      <label class="btn btn-wd" :class="procedur.district_id===d.id ? 'active' : ''" v-for="(d, i) in regions[regions.findIndex(r => r.id === procedur.region_id )].districts" :key="d.id">
                        <input type="radio" :disabled="disableEdit()" :id="`option` + i" :value="d.id" v-model="procedur.district_id" autocomplete="off" @change="changeValue()"> {{d.name}}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Статус документу стосовно суб'єкта</h4>
                  </div>
                  <div class="card-content">
                    <label class="btn btn-wd" :class="procedur.status_doc===1 ? 'active' : ''">
                      <input type="radio" :value=1 v-model.number="procedur.status_doc" @change="changeValue()" autocomplete="off">Вхідний
                    </label>
                    <label class="btn btn-wd" :class="procedur.status_doc===2 ? 'active' : ''">
                      <input type="radio" :value=2 v-model.number="procedur.status_doc" @change="changeValue()" autocomplete="off">Вихідний
                    </label>
                  </div>
                </div>
                <div class="card-content">
                  <label class="control-label">Форма документу:</label>
                  <div class="well">
                    <label class="btn btn-wd" :class="procedur.form_doc===1 ? 'active' : '' " >
                      <input type="radio" :disabled="disableEdit()" value=1 id="option1" autocomplete="off"  v-model.number="procedur.form_doc"  @change="changeValue()">Скарга в виборчу комісію
                    </label>
                    <label class="btn btn-wd" :class="procedur.form_doc===2 ? 'active' : '' ">
                    <input type="radio" :disabled="disableEdit()" value=2 id="option2" autocomplete="off" v-model.number="procedur.form_doc"  @change="changeValue()" >Позовна заява до суду
                    </label>
                    <label class="btn btn-wd" :class="procedur.form_doc===3 ? 'active' : '' " >
                      <input type="radio" :disabled="disableEdit()" value=3 id="option3" autocomplete="off"  v-model.number="procedur.form_doc"  @change="changeValue()" >Повідомлення про адміністративне правопорушення (в поліцію)
                    </label>
                    <label class="btn btn-wd" :class="procedur.form_doc===4 ? 'active' : '' ">
                      <input type="radio" :disabled="disableEdit()" value=4 id="option4" autocomplete="off" v-model.number="procedur.form_doc"  @change="changeValue()" >Повідомлення про злочин (в поліцію)
                    </label>
                    <label class="btn btn-wd" :class="procedur.form_doc===5 ? 'active' : '' " >
                      <input type="radio" :disabled="disableEdit()" value=5 id="option5" autocomplete="off"  v-model.number="procedur.form_doc"  @change="changeValue()">Протокол про адміністративне правопорушедння (в су)
                    </label>
                    <label class="btn btn-wd" :class="procedur.form_doc===6 ? 'active' : '' ">
                      <input type="radio" :disabled="disableEdit()" value=6 id="option6" autocomplete="off" v-model.number="procedur.form_doc"  @change="changeValue()" >Рішення суду де ОПОРА не була стороною процесу
                    </label>
                    <label class="btn btn-wd" :class="procedur.form_doc===7 ? 'active' : '' ">
                      <input type="radio" :disabled="disableEdit()" value=7 id="option7" autocomplete="off" v-model.number="procedur.form_doc"  @change="changeValue()" >Рішення суду де ОПОРА була стороною
                    </label>
                    <label class="btn btn-wd" :class="procedur.form_doc===8 ? 'active' : '' ">
                      <input type="radio" :disabled="disableEdit()" value=8 id="option8" autocomplete="off" v-model.number="procedur.form_doc"  @change="changeValue()" >Інше
                    </label>
                    <input v-if="procedur.form_doc===8" :disabled="disableEdit()"  v-model="procedur.form_doc_other" @change="changeValue()" type="text" class="form-control">
                  </div>
                </div>
                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer'">
                  <div class="card-header">
                    <h4 class="card-title">Контактні дані ДТС, тел</h4>
                  </div>
                  <div class="card-content">

                  </div>
                </div>
                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Суб'єкт звернення</h4>
                  </div>
                  <div class="card-content">
                    <textarea class="form-control" v-model="procedur.sub_zver" @change="changeValue()" placeholder="Ведіть текст" rows="5"></textarea>
                  </div>
                </div>
                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Суб'єкт розгляду (орган відпов…..)</h4>
                  </div>
                  <div class="card-content">
                    <textarea class="form-control" v-model="procedur.sub_roz" @change="changeValue()" placeholder="Ведіть текст" rows="5"></textarea>
                  </div>
                </div>

                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Суб'єкт оскарження / вчинення правоп….</h4>
                  </div>
                  <div class="card-content">
                    <textarea class="form-control" v-model="procedur.sub_osk" @change="changeValue()" placeholder="Ведіть текст" rows="5"></textarea>
                  </div>
                </div>

                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Опис змісту звернення / рішення</h4>
                  </div>
                  <div class="card-content">
                    <textarea class="form-control" v-model="procedur.description" @change="changeValue()" placeholder="Ведіть текст" rows="5"></textarea>
                  </div>
                </div>
                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Чи прийнято кінцеве рішення</h4>
                  </div>
                  <div class="card-content">
                    <label class="btn btn-wd" :class="procedur.end_resolve===1 ? 'active' : ''">
                      <input type="radio" :value=1 v-model.number="procedur.end_resolve" @change="changeValue()" autocomplete="off">Так
                    </label>
                    <label class="btn btn-wd" :class="procedur.end_resolve===2 ? 'active' : ''">
                      <input type="radio" :value=2 v-model.number="procedur.end_resolve" @change="changeValue()" autocomplete="off">Ні
                    </label>
                    <label class="btn btn-wd" :class="procedur.end_resolve===3 ? 'active' : ''">
                      <input type="radio" :value=3 v-model.number="procedur.end_resolve" @change="changeValue()" autocomplete="off">Інше
                    </label>
                    <input v-if="procedur.end_resolve===3" type="text" v-model="procedur.end_resolve_other" @change="changeValue()" class="form-control">
                  </div>
                </div>

                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Стан розгляду</h4>
                  </div>
                  <div class="card-content">
                    <label class="btn btn-wd" :class="procedur.status_roz===1 ? 'active' : ''">
                      <input type="radio" :value=1 v-model.number="procedur.status_roz" autocomplete="off" @change="changeValue()">Відмовлено в розгляді
                    </label>
                    <label class="btn btn-wd" :class="procedur.status_roz===2 ? 'active' : ''">
                      <input type="radio" :value=2 v-model.number="procedur.status_roz" autocomplete="off" @change="changeValue()">Прийнято до розгляду
                    </label>
                    <label class="btn btn-wd" :class="procedur.status_roz===3 ? 'active' : ''">
                      <input type="radio" :value=3 v-model.number="procedur.status_roz" autocomplete="off" @change="changeValue()">Розгляд триває
                    </label>
                    <label class="btn btn-wd" :class="procedur.status_roz===4 ? 'active' : ''">
                      <input type="radio" :value=4 v-model.number="procedur.status_roz" autocomplete="off" @change="changeValue()">Розгяд закінчено, винесено рішення але рішення не виконано)
                    </label>
                    <label class="btn btn-wd" :class="procedur.status_roz===5 ? 'active' : ''">
                      <input type="radio" :value=5 v-model.number="procedur.status_roz" autocomplete="off" @change="changeValue()">Рішення виконано (наприклад особу притягнуто до відповідальності, спостерігачів зареєстровано інд)
                    </label>
                    <label class="btn btn-wd" :class="procedur.status_roz===6 ? 'active' : ''">
                      <input type="radio" :value=6 v-model.number="procedur.status_roz" autocomplete="off" @change="changeValue()">Інше
                    </label>
                  </div>
                  <input v-if="procedur.status_roz===6" type="text" v-model="procedur.other_status_roz" @change="changeValue()" class="form-control">
                </div>

                <div class="card form-horizontal well" v-if="$store.state.role==='lawyer' || $store.state.role==='Admin'">
                  <div class="card-header">
                    <h4 class="card-title">Дата прийняття кінцевого рішеня,якщо…</h4>
                  </div>
                  <div class="card-content">
                    <el-date-picker type="date" placeholder="Виберіть дату"
                                    :picker-options="pickerOptions1" v-model="procedur.date_end" @change="changeValue()">
                    </el-date-picker>
                  </div>
                </div>
                <label class="control-label">Коиентар</label>
                <textarea :disabled="disableEdit()" class="form-control" v-model="procedur.comment" @change="changeValue()" placeholder="Ведіть текст" rows="5"></textarea>
              </div>
            </div>

            <div class="well">
              <fieldset>
                <div class="form-group" v-if="procedur.police_files">
                  <div v-if="procedur.police_files.length>0">
                    <div class="demo-image__preview">
                        <el-image v-for="image in procedur.police_files" :key="image.id"
                            style="width: 100px; height: 100px"
                            :src="'..' + image.file.url"
                            :preview-src-list="[]">
                        </el-image>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="card-content">
                <div class="el-upload-list el-upload-list--text">
                  <div class="row">
                    <div v-for="(file, key) in files" :key="key" class="col-md-5"><i class="el-icon-document"></i> {{ file.name }}
                      <button class="btn btn-danger btn-simple" v-on:click="removeFile( key )"><i class="el-icon ti-trash"></i></button>
                    </div>
                  </div>
                </div>
                <label class="btn btn-success btn-fill btn-wd"> <i class="ti-upload">Вбрати файл</i>
                  <input :disabled="disableEdit()" type="file" id="police_files" ref="police_files" multiple v-on:change="handleFilesUpload()"/>
                </label>
                <br>
                <progress v-if="fileUpload" max="100" :value.prop="uploadPercentage"></progress>
                <br>
                <button class="btn btn-primary btn-fill btn-wd" v-if="files.length > 0" v-on:click="submitFiles()"><i class="ti-save">Завантажити</i></button>
              </div>
            </div>
            <div class="form-group" v-if="procedur.id">
              <div class="card-header">
                <h4 class="card-title">Порушення</h4>
              </div>
              <div class="card-content">
                <ul class="list-unstyled">
                  <li>id - Дата - Заголовок  - Відєднати</li>
                  <li v-for="incident in procedur.incident_polices" :key="incident.id">
                    <router-link :to="`/incidents/`+incident.incident.id" target="_blank">
                    {{incident.incident.id}}
                    - {{incident.incident.date | formatDate}} - {{incident.incident.title}}
                    </router-link>
                    <button class="btn btn-danger btn-simple" v-on:click="removeIncidentPolices( incident.id )"><i class="el-icon ti-trash"></i></button>
                  </li>
                </ul>
              <label class="control-label">Додати Порушення</label>
              <Select2
                  ref="select2"
                  @change="changeValue()"
                  v-model.number="incident_id"
                  :options="incidents"
                  :settings="{ placeholder: 'Виберіть порушення' }"
              />
              <br>
              <button v-if="incident_id" @click="addIncident(incident_id)"  class="btn btn-info">Додати</button>
              </div>
            </div>
            <div class="card-content">
              <button @click="addDocUpdate()" v-if="procedur.id" class="btn btn-info">Додати оновлення</button>
            </div>

            <div class="well"  v-for="(doc, i) in doc_updates" :key="i">
              <div class="col-xs-1 col-xs-offset-11">
                <a v-if="!doc.police_update_files || doc.police_update_files.length===0" @click="removeUpdate( i )"
                   class="btn btn-simple btn-danger btn-xs btn-icon remove"><i class="ti-close"></i></a>
              </div>
              <br>
              <div class="form-group">
                <label class="control-label">Дата</label>
                <el-date-picker type="date" placeholder="Виберіть дату"
                                :picker-options="pickerOptions1" @change="changeValueUpdate(i)" v-model="doc.date">
                </el-date-picker>
              </div>
              <div class="form-group">
                <label class="control-label">Коментар</label>
                <textarea class="form-control" v-model="doc.comment" placeholder="Ведіть текст" @change="changeValueUpdate(i)" rows="5"></textarea>
              </div>

              <div class="card-content">
                <fieldset>
                  <div class="form-group" v-if="doc.police_update_files">
                    <div v-if="doc.police_update_files.length>0">
                      <div class="demo-image__preview">
                        <el-image v-for="image in doc.police_update_files" :key="image.id"
                                  style="width: 100px; height: 100px"
                                  :src="'..' + image.file.url"
                                  :preview-src-list="[]">
                        </el-image>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div class="el-upload-list el-upload-list--text">
                  <div class="row">
                    <div v-for="(file, key) in doc.files" :key="key" class="col-md-5"><i class="el-icon-document"></i> {{ file.name }}
                      <button class="btn btn-danger btn-simple" v-on:click="removeUpdateFile( key, i )"><i class="el-icon ti-trash"></i></button>
                    </div>
                  </div>
                </div>
                <label class="btn btn-success btn-fill btn-wd"> <i class="ti-upload">Вибрати файл</i>
                  <input type="file" id="update_police_files" ref="event_files" multiple v-on:change="handleUpdateFilesUpload($event, i)"/>
                </label>
                <br>
                <progress v-if="doc.file_upload" max="100" :value.prop="doc.uploadPercentage"></progress>
                <br>
                <button class="btn btn-primary btn-fill btn-wd" v-if="doc.files.length > 0" v-on:click="submitFilesUpdate(i)"><i class="ti-save"> Завантажити</i></button>
              </div>
            </div>
        <div class="card-content">
          <button @click="close()" class="btn btn-info btn-fill btn-wd">Готово</button>
        </div>
      </div>


        </div>

</template>
<script>
    import Vue from 'vue'
    import {DatePicker, Select, Option, Image } from 'element-ui'
    import Select2 from 'v-select2-component';
    import swal from "sweetalert2";
    import moment from "moment";
    // import $ from 'jquery';


    export default {
        name: 'Police',
        components: {
          [DatePicker.name]: DatePicker,
          [Option.name]: Option,
          [Select.name]: Select,
          [Image.name]: Image,
          Select2
        },
      filters: {
        formatDate(value){
          if (value) {
            return moment(String(value)).format('DD.MM.YYYY')
          }
        }
      },
        created() {
            this.getRegions(),
            this.getIncidents(),
            this.getPoliceUpdates()
        },
        mounted() {
          this.getPolice()
        },
        props: [
          'dateIncident', 'regionIncident', 'districtIncident', 'idPolice', 'idIncident'
        ],
        watch: {
          regions: 'setRegions',
        },
        methods: {
          removeProcedur(){
            let police_id;
            if (this.$route.path.match('/procedurs/')) {
              police_id = this.$route.params.id
            } else {
              police_id = this.idPolice
            }
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете видалити/відновити заяву?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .patch('/api/v1/police/' +  police_id  , { police: { remove: !this.procedur.remove} })
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Видалено!',
                            'Дія виконана успішно.',
                            'success'
                        ),
                            this.getPolice()

                    ));
              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'збережено :)',
                    'error'
                )
              }
            })
          },
          disableEdit(){
            if (this.procedur.user_id && this.$store.state.role==="observ" ){
              if ( Number(this.procedur.user_id) !== Number(this.$store.state.user_id) ) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          },
          close(){
            if (this.$route.path.match('/procedurs/')) {
              this.$router.push({path: '/procedurs'})
            } else {
              this.$emit('closePolice')
            }
          },
          getPolice(){
            let police_id;
            if (this.$route.path.match('/procedurs/')) {
              police_id = this.$route.params.id
            } else {
              police_id = this.idPolice
            }
            if (police_id) {
              this.axios
                  .get('/api/v1/police/' + police_id )
                  .then(response => (
                      this.procedur = response.data
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
            }
          },
          addDocUpdate(){
            this.doc_updates.push({police_id: this.procedur.id, date: '', comment: '', files: []})
          },
          getPoliceUpdates(){
            let police_id;
            if (this.$route.path.match('/procedurs/')) {
              police_id = this.$route.params.id
            } else {
              police_id = this.idPolice
            }
            if (police_id) {
              this.axios
                  .get('/api/v1/police_updates?police_id=' + police_id )
                  .then(response => (
                      this.doc_updates = response.data
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
            }
          },
          addIncident(id){
            this.axios
                .post('/api/v1/incident_polices', { incident_police: { incident_id: id, police_id: this.procedur.id} })
                .then(response => (
                    this.procedur.incident_polices.push(response.data)
                    //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                    //Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(() => (
                    this.$root.$emit('createPolice')
                // this.getIncidents()
                 ))

            this.incident_id = null
          },
          removeIncidentPolices(id){
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете відєднати проушення?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .delete('/api/v1/incident_polices/' + id )
                    .then(this.procedur.incident_polices.splice( this.procedur.incident_polices.findIndex(r => r.id ===id ), 1 ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Відєднано!',
                            'Порушення успішно відєднано.',
                            'success'
                        )

                    ));
              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'збережено :)',
                    'error'
                )
              }
            })
          },
          changeValue(){
            if (this.procedur.id) {
              this.axios
                  .patch('/api/v1/police/' + this.procedur.id  , { police: this.procedur})
                  // .then(response => (
                  //     console.log(response)
                  // //     //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                  // //     Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value })
                  // ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
            } else {
              this.axios
                  .post('/api/v1/police', { police: this.procedur})
                  .then(response => (
                      this.procedur = response.data
                      //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                      //Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(()=>(this.afterCreate()) )
            }
          },
          afterCreate(){
            if ( this.$route.path.match('/procedurs/') ) {
              this.$router.push({ path: '/procedurs/' + this.procedur.id })
            } else {
              this.addIncident(this.idIncident)
            }
          },
          changeValueUpdate(key){
            if (this.doc_updates[key].id) {
              this.axios
                  .patch('/api/v1/police_updates/' + this.doc_updates[key].id  , { police_update: this.doc_updates[key]})
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
            } else {
              this.axios
                  .post('/api/v1/police_updates', { police_update: this.doc_updates[key]})
                  .then(response => (
                          Vue.set(this.doc_updates, key, response.data )
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
            }
          },
          removeUpdate( key ){
            if (this.doc_updates[key].id) {
              const swalWithBootstrapButtons = swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })

              swalWithBootstrapButtons.fire({
                title: 'Ви впевнені?',
                text: "Ви хочете видалити оновлення?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Так!',
                cancelButtonText: 'Ні, відміна!',
                reverseButtons: true
              }).then((result) => {
                if (result.value) {
                  this.axios
                      .delete('/api/v1/police_updates/' + this.doc_updates[key].id )
                      .then( this.doc_updates.splice( key, 1 ))
                      .catch(error => {
                        if (error.response && error.response.status === 401) {
                          this.$store.dispatch("logout")
                        } else {
                          console.log(error);
                          this.errored = true;
                        }
                      })
                      .finally(() => (
                          swalWithBootstrapButtons.fire(
                              'Видалено!',
                              'Оновлення успішно видалено.',
                              'success'
                          )

                      ));
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === swal.DismissReason.cancel
                ) {
                  swalWithBootstrapButtons.fire(
                      'Відміна',
                      'збережено :)',
                      'error'
                  )
                }
              })
            } else {
              this.doc_updates.splice( key, 1 );
            }
          },
          handleUpdateFilesUpload(event, key){
            let uploadedFiles = event.target.files;
            for( var i = 0; i < uploadedFiles.length; i++ ){
              this.doc_updates[key].files.push( uploadedFiles[i] );
            }  },
          removeUpdateFile( key,i ){
            this.doc_updates[i].files.splice( key, 1 );
          },
          submitFilesUpdate(key){
            let formData = new FormData();
            Vue.set(this.doc_updates[key],'uploadPercentage', 0 )
            Vue.set(this.doc_updates[key],'file_upload', true )
            formData.append('police_update[police_id]', this.procedur.id);
            for( var i = 0; i < this.doc_updates[key].files.length; i++ ){
              let file = this.doc_updates[key].files[i];
              formData.append('police_update[police_update_files_attributes[' + i + '][file]]', file);
            }
            if (this.doc_updates[key].id)  {
              this.axios.patch( '/api/v1/police_updates/'+ this.doc_updates[key].id,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                      this.doc_updates[key].uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))
                    }.bind(this)
                  }
              ).then((response)=>
                  (Vue.set(this.doc_updates, key, response.data ))
              )
                  .catch(function(){
                    console.log('FAILURE!!');
                  })

            } else {
              this.axios.post( '/api/v1/police_updates',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                      this.doc_updates[key].uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))
                    }.bind(this)
                  }
              ).then((response)=>
                  (Vue.set(this.doc_updates, key, response.data))
              )
                  .catch(function(){
                    console.log('FAILURE!!');
                  })
            }
          },
          handleFilesUpload(){
            let uploadedFiles = this.$refs.police_files.files;
            for( var i = 0; i < uploadedFiles.length; i++ ){
              this.files.push( uploadedFiles[i] );
            }  },
          removeFile( key ){
            this.files.splice( key, 1 );
          },
          submitFiles(){
            let formData = new FormData();
            this.fileUpload = true
            for( var i = 0; i < this.files.length; i++ ){
              let file = this.files[i];
              let fileExtension = file.name.split('.').pop()
              formData.append('police[police_files_attributes[' + i + '][file]]', file, 'police_' + Date.now() + '.' + fileExtension );
            }
            if ( this.procedur.id) {
              this.axios.patch( '/api/v1/police/'+ this.procedur.id,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                      this.uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))
                    }.bind(this)
                  }
              ).then((response)=>
                  (this.procedur = response.data, this.files=[])
              )
                  .catch(function(){
                    console.log('FAILURE!!');
                  })
              .finally(()=>(this.uploadPercentage = 0, this.fileUpload = false))

            } else {
              this.axios.post( '/api/v1/police',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                  onUploadProgress: function(progressEvent) {
                    this.uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))
                  }.bind(this)
                  }
              ).then((response)=>
                  (this.procedur = response.data, this.files=[])
              )
                  .catch(function(){
                    console.log('FAILURE!!');
                  })
                  .finally(()=>(this.afterCreate(), this.uploadPercentage = 0, this.fileUpload = false  ) )
            }

          },
          getRegions(){
            this.axios
                .get('/api/v1/regions')
                .then(response => (
                    this.regions = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          getIncidents(){
            this.axios
                .get('/api/v1/get_incidents')
                .then(response => (
                    this.incidents = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(
                    // console.log($(this.$refs.select2)),
                    // $(this.$refs.select2).trigger("change")
                )
          },
          setRegions(){
            if (this.regions.length===1){
              this.procedur.region_id = this.regions[0].id
            }
          },
          wizardComplete(){
            this.$router.push({ path: `/procedurs` })
          },
        },

        data(){
            return {
               files: [],
               fileUpload: false,
               uploadPercentage: 0,
               procedur: {
                    date: this.dateIncident || null,
                    user_id: this.$store.state.user_id,
                    region_id: this.regionIncident || null,
                    district_id: this.districtIncident || null,
                    num_doc: '',
                    form_doc: null,
                    form_doc_other: '',
                    sub_zver: '',
                    sub_roz: '',
                    sub_osk: '',
                    description: '',
                    end_resolve: '',
                    end_resolve_other: '',
                    status_roz: '',
                    date_end: null,
                    comment: '',
                    status_doc: '',
                    other_status_roz: ''
               },

                incident_id: null,
                doc_updates: [],

                // doc_update: {
                //   id: this.procedur.id,
                //   date: null,
                //   files: [],
                //   comment: ''
                // },

                incidents: [],
                button: '',
                regions: [],
                pickerOptions1: {
                    shortcuts: [{
                        text: 'Сьогодні',
                        onClick (picker) {
                            picker.$emit('pick', new Date())
                        }
                    },
                        {
                            text: 'Вчора',
                            onClick (picker) {
                                const date = new Date()
                                date.setTime(date.getTime() - 3600 * 1000 * 24)
                                picker.$emit('pick', date)
                            }
                        },
                        {
                            text: 'Тиждень тому',
                            onClick (picker) {
                                const date = new Date()
                                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                                picker.$emit('pick', date)
                            }
                        }]
                },
                editorConfig: {
// The configuration of the editor.
                }

            }
        }
    }
</script>
<style>

</style>