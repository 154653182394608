<template>
    <div class="row">

        <template v-if="$route.name === 'Підтипи порушеня'">
        <div class="row">
            <div class="col-xs-3 col-xs-offset-7">
                <router-link class="btn btn-primary" :to="'/admin/incident_type/'+ $route.params.incident_type +'/sub_type/new'"><i class="ti-plus"></i> Додати</router-link>
            </div>
        </div>
        <div class="col-md-6" v-for="dashboard in dashboards" :key="dashboard.id">
            <time-line type="simple">
                <time-line-item  class="timeline-inverted" badge-type="danger" badgeIcon="ti-gallery" >
                    <span slot="header" class="label label-success">{{ dashboard.id }}</span>
                    <p slot="body" v-html="dashboard.name">

                    </p>

                    <div slot="footer">
                        <div class="row">

                        <div class="col-xs-12 col-md-6">
                            <router-link :to="'/admin/incident_type/'+ $route.params.incident_type +'/sub_type/' + dashboard.id" class="btn btn-wd btn-success btn-fill btn-rotate">
                                <i class="ti-pencil"></i> Редагувати
                            </router-link>
                        </div>
                        <div class="col-xs-12 col-md-2">
<!--                            <button class="btn btn-wd btn-danger" @click="removeArticle(dashboard.id)">-->
<!--                                <i class="fa fa-times"></i> Видалити-->
<!--                            </button>-->
                        </div>
                    </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    import TimeLine from './TimeLine.vue'
    import TimeLineItem from './TimeLineItem.vue'
    export default {
        name: "IncidentSubTypes",
        components: {
            TimeLine,
            TimeLineItem },
        data () {
            return {
                dashboards: null,
                loading: true,
                errored: false
            }
        },
        created() {

            this.getIncidentSubTypes()
        },
        watch: {
            $route: 'getIncidentSubTypes'
        },
        methods: {

          getIncidentSubTypes(){
               if (this.$route.name === 'Підтипи порушеня') {
                   this.axios
                       .get('/api/v1/incidet_sub_types?incident_type='+ this.$route.params.incident_type)
                       .then(response => (this.dashboards = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
               }
            },
            removeArticle(id) {

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити матеріал?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.axios
                            .delete('/api/v1/dashboards/' + id )
                            .then(this.dashboards = this.dashboards.filter( r => r.id !== id ))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Матнріал успішно видалено.',
                                    'success'
                                )

                            ));
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Матеріал збережено :)',
                            'error'
                        )
                    }
                })
            }
        }
    }
</script>
<style>

</style>