<template>
<div class="card-content row" v-if="$store.state.role !=='Public'">
    <template v-if="$route.path === '/procedurs'">
    <div class="col-sm-12">
        <router-link to="/procedurs/new" class="btn btn-danger btn-fill btn-wd">
            Додати заяву
        </router-link>
      <button @click="exportPolice()" v-if="$store.state.role==='Admin' || ($store.state.role==='lawyer')" class="btn btn-sm btn-info btn-fill btn-rotate">
        <i class="ti-export"></i> Експорт
      </button>
    </div>
    <div class="col-sm-6">
        <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
            <el-option
                    class="select-default"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
        </el-select>
    </div>
    <div class="col-sm-6">
        <div class="pull-right">
            <label>
<!--                <input type="search" class="form-control input-sm" placeholder="Search records" v-model="searchQuery" aria-controls="datatables">-->
            </label>
        </div>
    </div>
    <div class="col-sm-12">
<!--    <el-button @click="resetDateFilter">Скинути фільтр дати</el-button>-->
    <el-button @click="clearFilter">Скинути фільтри</el-button>
      <label>Видаленні</label>
      <p-switch  v-model="remove"  @input="getProcedurs()" type="primary"></p-switch>
    <el-table
            ref="filterTable"
            :data="tableData"
            @sort-change="sortChange"
            :default-sort="{prop: 'id', order: 'descending'}"
            @filter-change="filterChange"
            style="width: 100%">
      <el-table-column
          prop="id"
          sortable
          label="Id"
          width="100">
        <template slot-scope="scope"><router-link :to="{path:`/procedurs/` + scope.row.id}" >
          <i class="ti-check-box"></i> {{scope.row.id }}
        </router-link></template>
      </el-table-column>
        <el-table-column
                prop="date"
                label="Дата подання"
                sortable
                width="180"
                column-key="date"
        >
          <template slot-scope="scope">
            {{ scope.row.date |  formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            v-if="regions.length>1"
                prop="region_id"
                column-key="region_id"
                sortable
                label="Область"
                width="180"
                :filters="regionFilters"
                :filter-method="filterRegionTag"
                filter-placement="bottom-end">
          <template slot-scope="scope">
            {{ scope.row.region }}
          </template>
        </el-table-column>
        <el-table-column
                prop="district_id"
                column-key="district_id"
                sortable
                label="Район"
                width="180"
                :filters="disrtrictFilters"
                :filter-method="filterDistrictTag"
                filter-placement="bottom-end">
          <template slot-scope="scope">
            {{ scope.row.district }}
          </template>
        </el-table-column>
        <el-table-column
                prop="num_doc"
                sortable
                label="Номер"
                width="100">
        </el-table-column>
      <el-table-column
          prop="user_id"
          sortable
          label="Автор"
          width="150">
        <template slot-scope="scope">
          {{ scope.row.user }}
        </template>
      </el-table-column>
        <el-table-column
                prop="incidents"
                label="Інциденти"
                width="100">
          <template slot-scope="scope">
            <router-link v-for="(incident, i) in scope.row.incidents" :key="i" :to="`/incidents/`+incident" target="_blank">
              {{incident}}
            </router-link>
          </template>
        </el-table-column>

      <el-table-column
          prop="id"
          label="Редагувати"
          width="180">
        <template slot-scope="scope">
          <router-link :to="{path:`/procedurs/` + scope.row.id}" class="btn btn-default">
            <i class="ti-check-box"></i> Редагувати
          </router-link>
          <button class="btn btn-danger pull-right" v-if="($store.state.admin || $store.state.role==='lawyer')" @click="removeProcedur(scope.row.id,scope.row.remove )">
            {{ scope.row.remove ? "Відновити" : "Видалити"}}
          </button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
        <p class="category">Показано {{from + 1}} до {{to}} з {{total}}</p>
    </div>
    <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total">
        </p-pagination>
    </div>
    </template>
    <router-view></router-view>
</div>
</template>
<script>
    import Vue from 'vue'
    import {Table, TableColumn, Select, Option, Button, Tag} from 'element-ui'
    import PPagination from "../../../../UIComponents/Pagination.vue"
    import PSwitch from "@/components/UIComponents/Switch";
    import moment from "moment";
    import swal from "sweetalert2";
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Button)
    Vue.use(Tag)
    export default {
        name: 'ListPolice',
        components: {
            PPagination,
            PSwitch
        },
        computed: {
          regionFilters(){
            let list=[];
            this.regions.forEach(function (r){
              list.push({text: r.name, value: r.id })
            })
            return list
          },
          disrtrictFilters(){
            let list=[];
            if (this.region_id) {
              this.regions.filter(r =>  (this.region_id.toString().split(',')).includes(r.id.toString())).forEach(function (r){
                r.districts.forEach(function (d){
                  list.push({text: d.name, value: d.id })
                })
              })
            }
            return list
          },
          currentPage(){
            return this.pagination.currentPage
          },
          perPage(){
            return this.pagination.perPage
          },
          to () {
            let highBound = this.from + this.pagination.perPage
            if (this.total < highBound) {
              highBound = this.total
            }
            return highBound
          },
          from () {
            return this.pagination.perPage * (this.pagination.currentPage - 1)
          },
          total () {
            return this.pagination.total
          },
        },
        created() {

        },
        mounted() {
          this.getProcedurs()
          this.getRegions()
        },
      filters: {
        formatDate(value) {
          if (value) {
            return moment(String(value)).format('DD.MM.YYYY')
          }
        }
      },
      watch: {
        $route: 'getProcedurs',
        perPage: 'getProcedurs',
        currentPage:  'getProcedurs',
      },
        methods: {
          exportPolice(){
            this.axios
                .get('/api/v1/export/police.csv', {responseType: 'blob'})
                .then(( {data} ) => {
                  const downloadUrl = window.URL.createObjectURL(new Blob([data]));

                  const link = document.createElement('a');

                  link.href = downloadUrl;

                  link.setAttribute('download',  "police-"+ moment().format('D-MM-YYYY h:mm:ss')+ '.csv'); //any other extension
                  document.body.appendChild(link);

                  link.click();

                  link.remove();

                })
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          removeProcedur(id, remove){
            remove = !remove
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете видалити/відновити заяву?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .patch('/api/v1/police/' +  id  , { police: { remove: remove} })
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Видалено!',
                            'Дія виконана успішно.',
                            'success'
                        ),
                            this.getProcedurs()

                    ));
              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'збережено :)',
                    'error'
                )
              }
            })
          },
          filterChange(states){
            let property = Object.keys(states)[0]
            let values = Object.values(states).join(',')
            if (property==='region_id') {
              this.region_id = values
            } else {
              this.district_id = values
            }
            this.getProcedurs()
          },
          sortChange(sortProps){
            this.sort = sortProps.prop
            if (sortProps.order ==="ascending")
            {
              this.order= 'asc'
            } else {
              this.order= 'desc'
            }
            this.getProcedurs()
          },
          getProcedurs(){
            if (this.$route.path === '/procedurs') {
              this.axios
                  .get('/api/v1/police', {params: {
                              user_id: this.$store.state.user_id,
                              limit: this.pagination.perPage,
                              offset: this.from,
                              sort: this.sort,
                              order: this.order,
                              region: this.region_id,
                              district: this.district_id,
                              remove: this.remove
                            }
                      })
                  .then(response => (this.tableData = response.data.polices,
                          this.pagination.total = response.data.total
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(() => (this.loading = false));
            }
          },
            queriedData () {
                if (!this.searchQuery) {
                    this.pagination.total = this.tableData.length
                    return this.pagedData
                }
                let result = this.tableData
                    .filter((row) => {
                        let isIncluded = false
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString()
                            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                                isIncluded = true
                            }
                        }
                        return isIncluded
                    })
                this.pagination.total = result.length
                return result.slice(this.from, this.to)
            },
            resetDateFilter() {
                this.$refs.filterTable.clearFilter('date');
            },
            clearFilter() {
                this.$refs.filterTable.clearFilter();
              if  (this.regions.length > 1 ){
                this.region_id = ''
              }
              this.district_id = ''
              this.getProcedurs()
            },
            formatter(row) {
                return row.address;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            filterRegionTag(value, row) {
              return row.region_id === value;
            },
            filterDistrictTag(value, row) {
              return row.district_id === value;
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            },
          setRegionId(){
            if ( this.regions.length===1 ){
              this.region_id = this.regions[0].id
            }
          },
          getRegions(){
            this.axios
                .get('/api/v1/regions')
                .then(response => (
                    this.regions = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(()=>(
                    this.setRegionId()
                ))
          },
        },
        data(){
            return {
              order: 'desc',
              sort: 'id',
              regions: [],
              region_id: '',
              district_id: '',
              remove: false,
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: "",
                propsToSearch: ['name', 'email', 'age'],
                tableData: []
            }
        }
    }
</script>
<style>

</style>