<!--<template>-->
<!--  <div id="app">-->
<!--    <div id="nav">-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </div>-->
<!--    <router-view/>-->
<!--  </div>-->
<!--</template>-->

<!--<style>-->
<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--}-->

<!--#nav {-->
<!--  padding: 30px;-->
<!--}-->

<!--#nav a {-->
<!--  font-weight: bold;-->
<!--  color: #2c3e50;-->
<!--}-->

<!--#nav a.router-link-exact-active {-->
<!--  color: #42b983;-->
<!--}-->
<!--</style>-->

<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications transition-name="notification-list" transition-mode="out-in">

    </notifications>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  // Loading some plugin css asynchronously
  import('sweetalert2/dist/sweetalert2.css')
  import('vue-notifyjs/themes/default.css')
  export default {}
</script>
<style lang="scss">
  .notifications.vue-notifyjs {
    .notification-list-move {
      transition: transform 0.3s, opacity 0.4s;
    }
    .notification-list-item {
      display: inline-block;
      margin-right: 10px;

    }
    .notification-list-enter-active, .notification-list-leave-active {
      transition: opacity 0.4s;
    }
    .notification-list-enter, .notification-list-leave-to /* .list-leave-active for <2.1.8 */
    {
      opacity: 0;
    }
  }

</style>
