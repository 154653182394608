import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './pollyfills'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/ua'
import locale from 'element-ui/lib/locale'
import App from './App.vue'
import router from './router'
import store from './store'
import CKEditor from 'ckeditor4-vue'
import VueBreadcrumbs from 'vue-2-breadcrumbs';

Vue.config.productionTip = false

// Plugins
import GlobalComponents from './gloablComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'

import sidebarLinks from './sidebarLinks'

import './assets/sass/paper-dashboard.scss'
import './assets/sass/element_variables.scss'
import './assets/sass/demo.scss'
import swal from "sweetalert2";


Vue.use(VueNotify)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)

Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use( CKEditor );
Vue.use(VueBreadcrumbs);
Vue.use(VueAxios, axios)
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://dts2021.oporaua.org';
} else {
  axios.defaults.baseURL = 'http://127.0.0.1:3000';
}

axios.interceptors.response.use(undefined, function (error) {

  return new Promise(function () {
    console.log('Error!!!!!')
    if (error.response && error.response.status === 401) {
      store.dispatch("logout")
    }
    else if (error) {
      swal.fire({
        title: 'Сталась серверна помилка! :-(',
        icon: 'warning',
        html: '<p>Будь ласка, перейдіть назад і спробуйте повторити дію ще 1 раз.</p>' +
            '<p>Якщо помилка залишиться, будь ласка, напишіть нам в слак канал <b>#непрацюєдтс2020_поправте-допоможіть</b></p>' +
            '<p>Також можете заповнити форму у розділі "<a href="/update">Покращити</a>"</p>' +
            '<p>Ми виправимо оперативно</p>',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
            '<i class="fa fa-close"></i> Закрити!',
      })
    }
    throw error;
  });
});
const token = localStorage.getItem('token')
const client = localStorage.getItem('client')
const uid = localStorage.getItem('uid')
if (token) {
  axios.defaults.headers.common['access-token'] = token
}
if (client) {
  axios.defaults.headers.common['client'] = client
}
if (uid) {
  axios.defaults.headers.common['uid'] = uid
}
// axios.defaults.port = 3000;
locale.use(lang)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
