<template>
    <div class="row" >
        <template v-if="$route.path === '/admin/reports'">
        <div class="row">
            <div class="col-xs-3 col-xs-offset-7">
                <router-link v-if="$store.state.admin==='true'" class="btn btn-primary" to="/admin/reports/new"><i class="ti-plus"></i> Додати</router-link>
            </div>
        </div>
        <div class="col-md-6" v-for="report in reports" :key="report.id">
            <time-line type="simple">
                <time-line-item class="timeline-inverted" badge-type="success" badgeIcon="ti-gallery">
                    <span slot="header" class="label label-success">{{ report.name }}</span>
                    <span slot="header" class="label label-danger">{{ report.number }}</span>
                    <span slot="header" class="label label-info">{{ election_type.findIndex(e => e.id ===  report.election_type_id ) === -1 ? '' : election_type[election_type.findIndex(e => e.id ===  report.election_type_id )].name  }}</span>
                    <p slot="body" v-html="report.description">

                    </p>

                    <div slot="footer">
                        <div class="row">
                        <div class="col-xs-12 col-md-8 col-lg-4">
                            <router-link  v-if="$store.state.admin==='true'" :to="`/admin/reports/` + report.id + `/chapters`"  class="btn btn-sm btn-default">
                                <i class="ti-check-box"></i> Розділи
                            </router-link>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <button  v-if="$store.state.admin==='true'" class="btn btn-sm btn-primary btn-rotate">
                                <i class="ti-settings"></i> Кількість
                            </button>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <button   v-if="$store.state.admin==='true'" class="btn btn-sm btn-warning btn-fill btn-magnify" @click="copyReport(report.id)">
                                <i class="ti-camera"></i> Копіювати
                            </button>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <router-link  v-if="$store.state.admin==='true'" :to="`/admin/reports/` + report.id" class="btn btn-sm btn-success btn-fill btn-rotate">
                                <i class="ti-pencil"></i> Редагувати
                            </router-link>
                        </div>
                          <div class="col-xs-12 col-md-6 col-lg-4">
                            <button @click="exportAnswer( report.id, report.name)" class="btn btn-sm btn-info btn-fill btn-rotate">
                              <i class="ti-export"></i> Експорт
                            </button>
                          </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <button  v-if="$store.state.admin==='true'"  class="btn btn-sm btn-danger" @click="removeReport(report.id)">
                                <i class="fa fa-times"></i> Видалити
                            </button>
                        </div>
                    </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    import TimeLine from './TimeLine.vue'
    import TimeLineItem from './TimeLineItem.vue'
    import moment from 'moment'
    export default {
        name: "Reports",
        components: {
            TimeLine,
            TimeLineItem },
        data () {
            return {
                reports: null,
                loading: true,
                errored: false,
              election_type: []
            }
        },
        created() {
            this.getReports(),
            this.getElect()
        },
        watch: {
            $route: 'getReports'
        },
        methods: {
          getElect(){
            this.axios
                .get("/api/v1/election_types/" )
                .then(response => (this.election_type = response.data))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(() => (this.loading = false));
          },
            exportAnswer(id, name){
              this.axios
                  .get('/api/v1/export/'+id+'/answer.xlsx', {responseType: 'blob'})
                  .then(( {data} ) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

                    const link = document.createElement('a');

                    link.href = downloadUrl;

                    link.setAttribute('download', name +"-"+ moment().format('D-MM-YYYY h:mm:ss')+ '.xls'); //any other extension
                    document.body.appendChild(link);

                    link.click();

                    link.remove();

                  })
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
            },
            getReports(){
               if (this.$route.path === '/admin/reports') {
                   this.axios
                       .get('/api/v1/reports')
                       .then(response => (this.reports = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
               }
            },
            copyReport(id) {
                this.axios
                    .get('/api/v1/reports/copy/' + id)
                    .then(response => (this.reports.unshift(response.data)))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            removeReport(id) {

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити звіт?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.axios
                            .delete("/api/v1/reports/" + id )
                            .then(this.reports = this.reports.filter( r => r.id !== id ))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Звіт успішно видалено.',
                                    'success'
                                )

                            ));
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Звіт збережено :)',
                            'error'
                        )
                    }
                })
            }
        }
    }
</script>
<style>

</style>