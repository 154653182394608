<template>
    <div>
        <!--Stats cards-->
        <div class="row">
          <div class="col-lg-3 col-sm-6" v-if="$store.state.role ==='Public'">
            <stats-card >
              <div class="icon-big text-center" :class="`icon-${statsCards[1].type}`" slot="header">
                <i :class="statsCards[1].icon"></i>
              </div>
              <div class="numbers" slot="content">
                <p>{{statsCards[1].title}}</p>
                <el-tooltip class="item" effect="dark" :content="statsCards[1].value_finish ? 'Закінчених' : 'Створених'" placement="bottom">
                  <b>{{statsCards[1].value}}</b>
                </el-tooltip>
                <el-tooltip v-if="statsCards[1].value_finish" class="item" effect="dark" content="Створених" placement="bottom">
                  <b>({{statsCards[1].value_finish}})</b>
                </el-tooltip>
                <el-tooltip v-if="statsCards[1].value_ver" class="item" effect="dark" content="Верифікованих" placement="bottom">
                  <b class="text-danger">({{statsCards[1].value_ver}})</b>
                </el-tooltip>
              </div>
              <div class="stats" slot="footer">
                <router-link :to="statsCards[1].path" class="btn-rotate">
                  <i :class="statsCards[1].footerIcon"></i> {{statsCards[1].footerText}}
                </router-link>
              </div>
            </stats-card>
          </div>
            <div class="col-lg-3 col-sm-6" v-for="stats in statsCards" v-bind:key="stats.type">
                <stats-card v-if="$store.state.role !=='Public'">
                    <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
                        <i :class="stats.icon"></i>
                    </div>
                    <div class="numbers" slot="content">
                        <p>{{stats.title}}</p>
                      <el-tooltip class="item" effect="dark" :content="stats.value_finish ? 'Закінчених' : 'Створених'" placement="bottom">
                        <b>{{stats.value}}</b>
                      </el-tooltip>
                      <el-tooltip v-if="stats.value_finish" class="item" effect="dark" content="Створених" placement="bottom">
                        <b>({{stats.value_finish}})</b>
                      </el-tooltip>
                      <el-tooltip v-if="stats.value_ver" class="item" effect="dark" content="Верифікованих" placement="bottom">
                        <b class="text-danger">({{stats.value_ver}})</b>
                      </el-tooltip>
                    </div>
                    <div class="stats" slot="footer">
                        <router-link :to="stats.path" class="btn-rotate">
                            <i :class="stats.footerIcon"></i> {{stats.footerText}}
                        </router-link>
                    </div>
                </stats-card>
            </div>
        </div>
<!--      <h4>Activity <br><small>Multiple Line Chart</small></h4>-->
      <div class="well" v-if="$store.state.role ==='Regional' || $store.state.role === 'Admin'">
        <div class="form-group">
          <label class="control-label">Область</label>
          <el-select class="select-default"
                     clearable
                     size="large"
                     placeholder="Single Select"
                     v-model="chartActivity.region_id"
                     @change="getDataChatr()"
          >
            <el-option v-for="option in regions"
                       class="select-default"
                       :value="option.id"
                       :label="option.name"
                       :key="option.id">
            </el-option>
          </el-select>

<!--        <label class="btn btn-wd pull-right" :class="chartActivity.order==='all' ? 'active' : '' " >-->
<!--          <input type="radio" value="all" id="option1" autocomplete="off"  @change="getDataChatr()" v-model.number="chartActivity.order">Всі-->
<!--        </label>-->
<!--        <label class="btn btn-wd pull-right" :class="chartActivity.order==='hard' ? 'active' : '' ">-->
<!--          <input type="radio" value="hard" id="option2" autocomplete="off"  @change="getDataChatr()" v-model.number="chartActivity.order" >Важкі-->
<!--        </label>-->
        </div>
        <div id="chartActivity" class="ct-chart"></div>

              <h6>Legend</h6>
              <span class="label label-info">кількість агітацій в області</span>
              <span class="label label-warning">кількість інших подій в області</span>
              <span class="label label-danger">кількість інший подій де "джерело" Спостерігач.</span>
      </div>
      <div class="well col-xs-12 col-sm-6" v-if="$store.state.role ==='Regional' || $store.state.role === 'Admin'">
        <div class="row">
          <div class="form-group">
            <el-select class="select-default"
                       size="large"
                       placeholder="Single Select"
                       v-model="statRegions.sort"
                       @change="getDataRegIncident()"
            >
              <el-option class="select-default" value="all" label="Усі"></el-option>
              <el-option class="select-default" value="ver" label="Агітації"></el-option>
              <el-option class="select-default" value="not_ver" label="Інші"></el-option>
              <el-option class="select-default" value="public" label="Інші Спостерігач"></el-option>

            </el-select>
            <label class="btn btn-wd pull-right" :class="statRegions.order==='all' ? 'active' : '' " >
              <input type="radio" value="all"  autocomplete="off"  @change="getDataRegIncident()" v-model.number="statRegions.order">Всі
            </label>
            <label class="btn btn-wd pull-right" :class="statRegions.order==='hard' ? 'active' : '' ">
              <input type="radio" value="hard"  autocomplete="off"  @change="getDataRegIncident()" v-model.number="statRegions.order" >Агітації
            </label>
          </div>
        </div>
        <ul class="list-group">
          <li style="height: 60px" class="list-group-item" v-for="(stat,i) in stat_regions_incident" :key="i">
            <div class="row" >
              <div class="col-xs-12">
                <div class="pull-left" style="min-width: 120px">
                  {{stat.region}}
                </div>
                <div class="col-xs-4">
                  <el-progress :show-text="false"	:width="50" :stroke-width="26" v-if="stat" :percentage="(stat.count_all/ (statRegions.sort==='all'? stat_regions_incident[0].count_all : stat_regions_incident[0].count_ver)  *100)"></el-progress>
                </div>
                <div>
                  <el-tooltip class="item" effect="dark" content="Агітації" placement="top">
                    <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { region_id: stat.region_id, hard: statRegions.order, event: 1 }}">
                      {{ stat.ver }}
                    </router-link>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Інші" placement="top">
                    <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { region_id: stat.region_id, hard: statRegions.order, event:  2 }}">
                      {{ stat.not_ver }}
                    </router-link>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Спостерігач" placement="top">
                    <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { region_id: stat.region_id, hard: statRegions.order, event:  3 }}">
                      {{ stat.public }}
                    </router-link>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Всього" placement="top">
                    <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { region_id: stat.region_id, hard: statRegions.order }}">
                      {{ stat.count_all }}
                    </router-link>
                  </el-tooltip>
                </div>
              </div>
              </div>
            </li>
        </ul>
      </div>
      <div class="well col-xs-12 col-sm-6" v-if="$store.state.role ==='Regional' || $store.state.role === 'Admin'">
        <div class="row">
          <div class="form-group">
<!--            <el-select class="select-default"-->
<!--                       size="large"-->
<!--                       placeholder="Single Select"-->
<!--                       v-model="top.sort"-->
<!--                       @change="getDataTop()"-->
<!--            >-->
<!--              <el-option class="select-default" value="all" label="Усі"></el-option>-->
<!--              <el-option class="select-default" value="ver" label="Агітації"></el-option>-->
<!--              <el-option class="select-default" value="not_ver" label="Інші"></el-option>-->
<!--              <el-option class="select-default" value="public" label="Состерігач"></el-option>-->

<!--            </el-select>-->
            <label class="btn btn-wd pull-right" :class="top.order==='all' ? 'active' : '' " >
              <input type="radio" value="all"  autocomplete="off"  @change="getDataTop()" v-model.number="top.order">Всі
            </label>
            <label class="btn btn-wd pull-right" :class="top.order==='hard' ? 'active' : '' ">
              <input type="radio" value="hard"  autocomplete="off"  @change="getDataTop()" v-model.number="top.order" >Агітації
            </label>
          </div>
        </div>

        <div class="card-content">
          <div class="table-full-width table-tasks col-md-7">
            <table class="table">
              <tbody>
              <tr v-for="(t,i) in top_observer" :key="i">
                <td>
                  <label class="checkbox">
                    <input type="checkbox" value="" data-toggle="checkbox">
                  </label>
                </td>
                <td>{{ t.region }}</td><td>{{t.name}}</td>
                <td class="td-actions text-right">
                  <div class="table-icons">
                    <el-tooltip class="item" effect="dark" content="Агітації" placement="top">
                      <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { dts_id: t.user_id, hard: top.order, event: 1 }}">
                        {{ t.count_ver }}
                      </router-link>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Інші" placement="top">
                      <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { dts_id: t.user_id, hard: top.order, event: 2 }}">
                        {{ t.count_not_ver }}
                      </router-link>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Спостерігач" placement="top">
                      <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { dts_id: t.user_id, hard: top.order, event: 3 }}">
                        {{ t.count_public }}
                      </router-link>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Всього" placement="top">
                      <router-link  class="btn btn-icon" :to="{ path: 'eventreports', query: { dts_id: t.user_id, hard: top.order }}">
                        {{ t.count_all }}
                      </router-link>
                    </el-tooltip>


                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import StatsCard from '../../../../components/UIComponents/Cards/StatsCard.vue'
    import Chartist from 'chartist'
    import moment from  'moment'
    import { Progress} from 'element-ui'
    Vue.use(Progress)
    export default {
        components: {
            StatsCard
            // Chartist

        },
        /**
         * Chart data used to render stats, charts. Should be replaced with server data
         */
        created() {
          this.getStats()
        },
      mounted () {
          if (this.$store.state.role!=='Public'){
            this.getDataChatr()
            this.getDataTop()
            this.getDataRegIncident()
            this.getRegions()
          }
      },
      methods: {
        getRegions(){
          this.axios
              .get('/api/v1/regions')
              .then(response => (
                  this.regions = response.data
              ))
              .catch(error => {
                if (error.response && error.response.status === 401) {
                  this.$store.dispatch("logout")
                } else {
                  console.log(error);
                  this.errored = true;
                }
              })
        },
        getDataRegIncident(){
          this.axios
              .get('/api/v1/stat_regions_incident', {params: {order: this.statRegions.order, sort: this.statRegions.sort}})
              .then(response => (
                  this.stat_regions_incident =  response.data

              ))
              .catch(error => {
                if (error.response && error.response.status === 401) {
                  this.$store.dispatch("logout")
                } else {
                  console.log(error);
                  this.errored = true;
                }
              })
              .finally()
        },
        getDataTop(){
          this.axios
              .get('/api/v1/top_observer', {params: {order: this.top.order, sort: this.top.sort}})
              .then(response => (
                 this.top_observer =  response.data

              ))
              .catch(error => {
                if (error.response && error.response.status === 401) {
                  this.$store.dispatch("logout")
                } else {
                  console.log(error);
                  this.errored = true;
                }
              })
              .finally()
        },
        getDataChatr(){
          this.chartList = { labels: [],
            series: [[], [], []]
          }
          this.axios
              .get('/api/v1/stat_incident', {params: {region_id: this.chartActivity.region_id, order:  this.chartActivity.order }})
              .then(response => (
                  response.data.forEach(
                      element =>  (this.chartList.labels.push(element.date),
                          this.chartList.series[0].push(element.all_size),
                          this.chartList.series[1].push(element.ver_size),
                          this.chartList.series[2].push(element.ver_size_not))

                  ),
                      this.initChart()
              ))
              .catch(error => {
                if (error.response && error.response.status === 401) {
                  this.$store.dispatch("logout")
                } else {
                  console.log(error);
                  this.errored = true;
                }
              })
          .finally()
        },
        initChart () {


          const options = {
            seriesBarDistance: 10,
            axisX: {
              showGrid: false,
              labelInterpolationFnc: function (value) {
                //return value;
               return moment(value).format('MMM D');
              }
            },
            height: "245px",

          };
          const responsiveOptions = [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  //return value[0];
                 return moment(value).format('MMM D');
                }
              }
            }]
          ];
          Chartist.Line('#chartActivity', this.chartList, options, responsiveOptions);
        },
          getStats(){
            this.axios
                .get('/api/v1/stat')
                .then(response => (
                    this.statsCards[0].value = response.data.report,
                    this.statsCards[0].value_finish = response.data.report_all,
                    this.statsCards[1].value = response.data.agit,
                    this.statsCards[2].value = response.data.incident,
                    this.statsCards[2].value_ver = response.data.incident_ver,
                    this.statsCards[3].value = response.data.police,
                    this.statsCards[4].value = response.data.event
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          }
        },
        data () {
            return {
              stat_regions_incident: [],
              top_observer: [],
              chartList: {},
                regions: [],
                stat: '',
                chartActivity: {region_id: '', order: 'all'},
                statRegions: {sort: 'all', order: 'all'},
                top: {sort: 'all', order: 'all'},
                statsCards: [
                  {
                    type: 'warning',
                    icon: 'ti-notepad',
                    title: 'Звіти',
                    value: '0',
                    value_finish : '0',
                    footerText: 'Перейти до звітів',
                    footerIcon: 'ti-reload',
                    path: this.$store.state.role==='observ' ? '/forms' : '/admin_forms'

        },
                    {
                        type: 'warning',
                        icon: 'ti-server',
                        title: 'Агітацій',
                        value: '0',
                        footerText: 'Перейти до агітацій',
                        footerIcon: 'ti-reload',
                        path: '/eventreports?event=1'
                    },
                    {
                        type: 'success',
                        icon: 'ti-wallet',
                        title: 'Внесено Порушень',
                        value: '0',
                        value_ver: '0',
                        footerText: 'Перейти до порушень',
                        footerIcon: 'ti-calendar',
                        path: '/incidents'
                    },
                    {
                        type: 'danger',
                        icon: 'ti-pulse',
                        title: 'Внесено Заяв до поліції',
                        value: '0',
                        footerText: 'Перейти до Заяв до поліції',
                        footerIcon: 'ti-timer',
                        path: '/procedurs'
                    },
                    {
                        type: 'info',
                        icon: 'ti-twitter-alt',
                        title: 'Внесено Подій',
                        value: '0',
                        footerText: 'Перейти до Подій',
                        footerIcon: 'ti-reload',
                        path: '/eventreports'
                    }
                ]

            }
        }
    }

</script>
<style>

</style>
