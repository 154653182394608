<template>
<div class="card-content row">
  <div class="well">
    <p>У цю форму необхідно вносити актуальні події, які стосуються політичних конфліктів та владних ротацій в регіонах,
      бізнес-конфліктів під час виборів, протистояння за участі ЗМІ, впливу безпекових та карантинних заходів на перебіг передвиборчої кампанії.</p>
    <p>Ці події формують контекст передвиборчої кампанії, які можуть бути формально не пов’язані із виборами, але на них впливають.</p>
  </div>
    <template v-if="$route.path === '/eventreports'">
    <div class="col-sm-12">
        <router-link to="/eventreports/new" class="btn btn-danger btn-fill btn-wd">
            Додати подію
        </router-link>
    </div>
    <div class="col-sm-6">
        <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
            <el-option
                    class="select-default"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
        </el-select>
    </div>
    <div class="col-sm-6">
        <div class="pull-right">
            <label>
<!--                <input type="search" class="form-control input-sm" placeholder="Search records" v-model="searchQuery" aria-controls="datatables">-->
            </label>
        </div>
    </div>
    <div class="col-sm-12">
<!--    <el-button @click="resetDateFilter">Скинути фільтр дати</el-button>-->
    <el-button @click="clearFilter">Скинути фільтри</el-button>
    <el-table
            ref="filterTable"
            :data="tableData"
            @filter-change="filterChange"
            @sort-change="sortChange"
            :default-sort="{prop: 'id', order: 'descending'}"
            style="width: 100%">
      <el-table-column
          prop="id"
          sortable
          label="Id"
          width="100">
        <template slot-scope="scope"><router-link :to="{path:`/eventreports/` + scope.row.id}" >
          <i class="ti-check-box"></i> {{scope.row.id }}
        </router-link></template>
      </el-table-column>
        <el-table-column
                prop="date"
                label="Дата"
                sortable
                width="110"
                column-key="date">
<!--                :filters="[{text: '2016-05-01', value: '2016-05-01'}, {text: '2016-05-02', value: '2016-05-02'}, {text: '2016-05-03', value: '2016-05-03'}, {text: '2016-05-04', value: '2016-05-04'}]"-->
<!--                :filter-method="filterHandler">-->

          <template slot-scope="scope">
            {{ scope.row.date |  formatDate }}
          </template>
        </el-table-column>
      <el-table-column
          prop="election_type_id"
          sortable
          label="Тип виборів"
          width="100">
        <template slot-scope="scope">
           {{  election_type.findIndex(e => e.id === scope.row.election_type_id ) === -1 ? '' : election_type[election_type.findIndex(e => e.id ===  scope.row.election_type_id )].name    }}
        </template>
      </el-table-column>
        <el-table-column
                v-if="regions.length>1"
                prop="region_id"
                column-key="region_id"
                sortable
                label="Область"
                width="130"
                :filters="regionFilters"
                :filtered-value="$route.query.region_id ? [Number($route.query.region_id) ] : null"
                :filter-method="filterRegionTag"
                filter-placement="bottom-end">
          <template slot-scope="scope">
            {{ scope.row.region }}
          </template>
        </el-table-column>
        <el-table-column
                prop="district_id"
                column-key="district_id"
                sortable
                label="Район"
                width="180"
                :filters="disrtrictFilters"
                :filter-method="filterDistrictTag"
                filter-placement="bottom-end">
          <template slot-scope="scope">
            {{ scope.row.district }}
          </template>
        </el-table-column>
        <el-table-column
                prop="event_type"
                column-key="event_type"
                label="Тип"
                width="100"
                sortable
                :filters="EventTypeFilter"
                :filter-method="filterEventTag"
                :filtered-value="$route.query.hard === 'hard' ? [5,6] :  $route.query.event === '1' ? [5,6] : $route.query.event === 1 ? [5,6] : null"
                filter-placement="bottom-end">
            <template slot-scope="scope" v-if="scope.row.event_type && event_types.length>0">
              {{ event_types[event_types.findIndex(e => e.id === scope.row.event_type )].name }}
            </template>
        </el-table-column>
        <el-table-column
                prop="picked"
                label="Джерело"
                width="180"
                sortable
                >
            <template slot-scope="scope" v-if="scope.row.picked">
                <el-tag
                        :type="scope.row.picked === 'Home' ? 'primary' : 'success'"
                        disable-transitions>{{source(scope.row.picked)}}</el-tag>
            </template>
        </el-table-column>
      <el-table-column
          prop="user_id"
          sortable
          label="Автор"
          width="180">
        <template slot-scope="scope">
          {{ scope.row.user }}
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="Редагувати"
          width="180">
        <template slot-scope="scope">
          <router-link :to="{path:`/eventreports/` + scope.row.id}" class="btn btn-default">
            <i class="ti-check-box"></i> Редагувати
          </router-link>
          <button class="btn btn-info pull-right" @click="showMedia(scope.row.id)">
            <i class="ti-search"></i>
          </button>
          <button class="btn btn-danger pull-right" v-if="($store.state.admin || $store.state.role==='Regional' || $store.state.role==='lawyer')" @click="removeEvent(scope.row.id)">
            Видалити
          </button>
        </template>
      </el-table-column>


    </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
        <p class="category">Показано від {{from + 1}} до {{to}} з {{total}}</p>
    </div>
    <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total">
        </p-pagination>
    </div>
    </template>
    <router-view></router-view>
</div>
</template>
<script>
    import Vue from 'vue'
    import {Table, TableColumn, Select, Option, Button, Tag} from 'element-ui'
    import PPagination from "../../../../UIComponents/Pagination.vue"
    import moment from "moment";
    import swal from "sweetalert2";
    //import event_types from "./event_types";
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Button)
    Vue.use(Tag)
    export default {
        name: 'Events',
        components: {
            PPagination
        },

        computed: {
          EventTypeFilter(){
            let list=[];
            this.event_types.forEach(function (r){
              list.push({text: r.name, value: r.id })
            })
            return list
          },
          regionFilters(){
            let list=[];
            this.regions.forEach(function (r){
              list.push({text: r.name, value: r.id })
            })
            return list
          },
          disrtrictFilters(){
            let list=[];
            if (this.region_id) {
              this.regions.filter(r =>  (this.region_id.toString().split(',')).includes(r.id.toString())).forEach(function (r){
                r.districts.forEach(function (d){
                  list.push({text: d.name, value: d.id })
                })
              })
            }
            return list
          },
          currentPage(){
            return this.pagination.currentPage
          },
          perPage(){
            return this.pagination.perPage
          },
          to () {
            let highBound = this.from + this.pagination.perPage
            if (this.total < highBound) {
              highBound = this.total
            }
            return highBound
          },
          from () {
            return this.pagination.perPage * (this.pagination.currentPage - 1)
          },
          total () {
            return this.pagination.total
          },
        },
      filters: {
        formatDate(value) {
          if (value) {
            return moment(String(value)).format('DD.MM.YYYY')
          }
        }
      },
        created() {
          this.getEvents()
          this.getElect()
        },
        mounted() {
          this.getRegions()
          this.getEventTypes()
        },

      watch: {
          $route: 'getEvents',
          perPage: 'getEvents',
          currentPage:  'getEvents',
      },
        methods: {
          getElect(){
            this.axios
                .get("/api/v1/election_types/" )
                .then(response => (this.election_type = response.data))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(() => (this.loading = false));
          },
          showMedia(event_id){
            this.axios
                .get('/api/v1/events/' + event_id )
                .then(response => (
                    this.event = response.data,
                        swal.fire({
                          title: '<strong><u>Вкладення</u></strong>',
                          width: '72rem',
                          html:
                              `<pre>${this.event.description }</pre>` +
                          this.event.event_files.map(cat => `<img src='${cat.file.url}'  style="width: 600px;"><pre>${cat.text_photo}</pre> ` )
                             ,
                          showCloseButton: true,
                          focusConfirm: false
                        })

                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          removeEvent(id){
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете видалити подію?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .delete('/api/v1/events/' +  id  )
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Видалено!',
                            'Дія виконана успішно.',
                            'success'
                        ),
                            this.getEvents()

                    ));
              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'збережено :)',
                    'error'
                )
              }
            })
          },
          getEventTypes(){
            this.axios
                .get('/api/v1/get_event_types')
                .then(response => (
                    this.event_types = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          filterChange(states){
            let property = Object.keys(states)[0]
            let values = Object.values(states).join(',')
            if (property==='region_id') {
              this.region_id = values
            } else if (property==='district_id'){
              this.district_id = values
            } else {
              this.event_type = values
            }
            this.getEvents()
          },
          setRegionId(){
            if ( this.regions.length===1 ){
              this.region_id = this.regions[0].id
            }
          },
          getRegions(){
            this.axios
                .get('/api/v1/regions')
                .then(response => (
                    this.regions = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(()=>(
                    this.setRegionId()
                ))
          },
          sortChange(sortProps){
            this.sort = sortProps.prop
            if (sortProps.order ==="ascending")
            {
              this.order= 'asc'
            } else {
              this.order= 'desc'
            }
            this.getEvents()
          },
          source(id){
            if (id===1){
              return 'Спостерігач'
            }
            else if (id===2){
              return 'Інет ЗМІ'
            }
            else if (id===3){
              return 'FB'
            }
            else if (id===4){
              return 'YouTube'
            }
            else if (id===5){
              return 'Telegram'
            }
            else if (id===6){
              return 'Instagram'
            }
            else if (id===7) {
              return 'VK'
            }
            else {
              return "традиційні ЗМІ"
            }
          },
          getEvents(){
            if (this.$route.path === '/eventreports') {
              this.axios
                  .get('/api/v1/events',  {params: {user_id: this.$store.state.user_id, limit: this.pagination.perPage, offset: this.from, sort: this.sort, order: this.order,
                      region: this.region_id,
                      dts_id: this.dts_id,
                      district: this.district_id,
                      event: this.event_type,}})
                  .then(response => (this.tableData = response.data.events,
                                     this.pagination.total = response.data.total
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(() => (this.loading = false));
            }
            },
            queriedData () {
                if (!this.searchQuery) {
                    this.pagination.total = this.tableData.length
                    return this.pagedData
                }
                let result = this.tableData
                    .filter((row) => {
                        let isIncluded = false
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString()
                            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                                isIncluded = true
                            }
                        }
                        return isIncluded
                    })
                this.pagination.total = result.length
                return result.slice(this.from, this.to)
            },
            resetDateFilter() {
                this.$refs.filterTable.clearFilter('date');
            },
            clearFilter() {
                this.$refs.filterTable.clearFilter();
              if  (this.regions.length > 1 ){
                this.region_id = ''
              }
              this.district_id = ''
              this.event_type = ''
              this.getEvents()
            },
            filterTag(value, row) {
                return row.tag === value;
            },
          filterRegionTag(value, row) {
            return row.region_id === value;
          },
          filterDistrictTag(value, row) {
            return row.district_id === value;
          },
          filterEventTag(value, row) {
            return row.event_type === value;
          },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            }
        },
        data(){
            return {
              event: null,
              order: 'desc',
              sort: 'id',
              regions: [],
              region_id:  this.$route.query.region_id || '' ,
              district_id: '',
              election_type: [],
              dts_id: this.$route.query.dts_id || '',
              event_type: this.$route.query.hard === 'hard' ? '5,6' : '' || this.$route.query.event ==="1" ? '5,6' : '' || '',
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                event_types: [],
                searchQuery: "",
                propsToSearch: ['name', 'email', 'age'],
                tableData: []
            }
        }
    }
</script>
<style>

</style>