<template>
  <div class="row">
    <div class="card-content row"  v-if="$route.path==='/admin/users'">
      <div class="col-sm-12">
        <router-link to="/admin/users/new" class="btn btn-danger btn-fill btn-wd">
          Додати
        </router-link>
      </div>
      <div class="col-sm-6">
        <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page">
          <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-6">
        <div class="pull-right">
          <label>
            <!--                <input type="search" class="form-control input-sm" placeholder="Search records" v-model="searchQuery" aria-controls="datatables">-->
          </label>
        </div>
      </div>
      <div class="col-sm-12">
        <!--    <el-button @click="resetDateFilter">Скинути фільтр дати</el-button>-->
        <el-button @click="clearFilter">Скинути фільтри</el-button>
        <el-table
            ref="filterTable"
            :data="tableData"
            @filter-change="filterChange"
            @sort-change="sortChange"
            :default-sort="{prop: 'users.id', order: 'ascending'}"
            style="width: 100%">
          <el-table-column
              prop="users.id"
              sortable
              label="Id"
              width="100">
            <template slot-scope="scope"><router-link :to="{path:`/admin/users/` + scope.row.id }" >
              <i class="ti-check-box"></i> {{scope.row.id }}
            </router-link></template>
          </el-table-column>
          <el-table-column
              v-if="regions.length>1"
              prop="region_id"
              column-key="region_id"
              sortable
              label="Область"
              width="230"
              :filters="regionFilters"
              :filter-method="filterRegionTag"
              filter-placement="bottom-end">
            <template slot-scope="scope">
              <p v-if="scope.row.region.length ===  regions.length">Усі</p>
              <p v-else v-for="(reg, i) in scope.row.region" :key="i">{{ regions[regions.findIndex(r => r.id === reg)].name }}</p>
            </template>
          </el-table-column>
          <el-table-column
              prop="email"
              label="Email"
              sortable
              width="250"
              column-key="report">
            <template slot-scope="scope">
              {{ scope.row.email }}
            </template>
          </el-table-column>
          <el-table-column
              prop="name"
              label="Прізвище Ім'я Побатькові"
              sortable
              width="250"
              column-key="report">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column
              prop="role"
              label="Роль"
              sortable
              width="120"
              column-key="role"
              :filters="RoleFilter"
              :filter-method="filterRole"
              filter-placement="bottom-end">
            <template slot-scope="scope">
                {{ RoleFilter[RoleFilter.findIndex(r => r.value === scope.row.role )].text }}
            </template>
          </el-table-column>
          <el-table-column
              prop="moderator"
              column-key="moderator"
              label="Модератор"
              width="120"
              :filters="[{text: 'Так', value: true}, {text: 'Ні', value: false}]"
              :filter-method="filterModerator"
              filter-placement="bottom-end">
            <template slot-scope="scope">
              <el-tag
                  :type="scope.row.moderator ? 'success' : 'warning'"
                  disable-transitions>
                <i v-if="scope.row.moderator" class="fa fa-check"></i>
                <i v-if="!scope.row.moderator" class="fa fa-times warning"></i>
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="admin"
              column-key="admin"
              label="Адміністратор"
              width="150"
              :filters="[{text: 'Так', value: true}, {text: 'Ні', value: false}]"
              :filter-method="filterAdmin"
              filter-placement="bottom-end"
          >
            <template slot-scope="scope" >
              <el-tag
                :type="scope.row.admin ? 'success' : 'warning'"
                disable-transitions>
              <i v-if="scope.row.admin" class="fa fa-check"></i>
              <i v-if="!scope.row.admin" class="fa fa-times warning"></i>
            </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="id"
              label="Редагувати"
              width="100">
            <template slot-scope="scope">
              <router-link :to="{path:`/admin/users/` + scope.row.id}" class="btn btn-default">
                <i class="ti-check-box"></i>
              </router-link>
            </template>
          </el-table-column>


        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Показано від {{from + 1}} до {{to}} з {{total}}</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total">
        </p-pagination>
      </div>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option, Button, Tag, Tooltip} from 'element-ui'
import PPagination from "../../../../UIComponents/Pagination.vue"
import moment from "moment";
//import event_types from "./event_types";
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Tag)
Vue.use(Tooltip)
export default {
  name: 'Users',
  components: {
    PPagination
  },

  computed: {
    RoleFilter(){
      let list=[
        {
          text: "Адміністартор",
          value: "Admin"
        },
        {
          text: "Пресслужба",
          value: "Public"
        },
        {
          text: "Аналітик",
          value: "analyst"
        },
        {
          text: "Юрист",
          value: "lawyer"
        },
        {
          text: "Регіональний",
          value: "Regional"
        },
        {
         text:"Обласний",
         value: "coordinator"
        },
        {
          text: "ДТС",
          value: "observ"
        }
      ];
      return list
    },
    regionFilters(){
      let list=[];
      this.regions.forEach(function (r){
        list.push({text: r.name, value: r.id })
      })
      return list
    },
    currentPage(){
      return this.pagination.currentPage
    },
    perPage(){
      return this.pagination.perPage
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.pagination.total
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:MM:ss')
      }
    }
  },
  created() {
    this.getUsers()
  },
  mounted() {
    this.getRegions()
  },

  watch: {
    $route: 'getUsers',
    perPage: 'getUsers',
    currentPage:  'getUsers',
  },
  methods: {
    filterChange(states){
      let property = Object.keys(states)[0]
      let values = Object.values(states).join(',')
      if (property==='region_id') {
        this.region_id = values
      } else if (property==='admin'){
        this.admin = values
      } else if (property==='moderator'){
        this.moderator = values
      } else {
        this.role = values
      }
      this.getUsers()
    },
    setRegionId(){
      if ( this.regions.length===1 ){
        this.region_id = this.regions[0].id
      }
    },
    getRegions(){
      this.axios
          .get('/api/v1/regions')
          .then(response => (
              this.regions = response.data
          ))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
          .finally(()=>(
              this.setRegionId()
          ))
    },
    sortChange(sortProps){
      this.sort = sortProps.prop
      if (sortProps.order ==="ascending")
      {
        this.order= 'asc'
      } else {
        this.order= 'desc'
      }
      this.getUsers()
    },
    getUsers(){
      this.axios
          .get('/api/v1/user',  {params: {user_id: this.$store.state.user_id, limit: this.pagination.perPage, offset: this.from, sort: this.sort, order: this.order,
              region: this.region_id,
              admin: this.admin,
              role: this.role,
              moderator: this.moderator
            }})
          .then(response => (this.tableData = response.data.users,
                  this.pagination.total = response.data.total
          ))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
          .finally(() => (this.loading = false));
    },
    queriedData () {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    resetDateFilter() {
      this.$refs.filterTable.clearFilter('date');
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
      if  (this.regions.length > 1 ){
        this.region_id = ''
      }
     this.moderator= ''
     this.role=''
      this.admin= ''
      this.getUsers()
    },
    filterTag(value, row) {
      return row.tag === value;
    },
    filterRegionTag(value, row) {
      return row.region.includes(value)
    },
    filterRole(value, row) {
      return row.role === value;
    },
    filterModerator(value, row) {
      return row.moderator === value;
    },
    filterAdmin(value, row) {
      return row.admin === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    }
  },
  data(){
    return {
      order: 'asc',
      sort: 'users.id',
      regions: [],
      region_id: '' ,
      moderator: '',
      role: '',
      admin: '',
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ['name', 'email', 'age'],
      tableData: []
    }
  }
}
</script>
<style>

</style>