<template>

    <div class="row" v-if="$store.state.role !=='Public'">
        <template v-if="$route.path === '/forms'">


        <div class="col-lg-10 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Звіти</h4>
                    <p class="category"></p>
                </div>

                    <vue-tabs class="card-content" v-model="tabSelect" @tab-change="handleTabChange" active-tab-color="#e74c3c"
                              active-text-color="white"
                              text-position="center"

                    >

                        <v-tab :title="rep.title" v-bind:color="rep.color" v-for="rep in all_reports" :key="rep.id" >
                            <template  v-if="tabSelect==='Нездані'">
                                <el-collapse class="panel-group">
                                    <el-collapse-item :title="i + 1 +' '+ report.name + ' ( '+ report.election + ' )'" :item="report.id" :name="report.id" v-for="(report,i) in reports" :key="report.id" >
                                        <div v-html="report.description"></div>
                                      <router-link v-for="answer in report.answers" :key="answer.id" :to="{path:`/forms/` + report.id,  query: { answer: answer.id }}"  class="btn btn-default">
                                        <i class="ti-check-box"></i> Продовжити заповнення {{ answer.created_at | formatDate}}
                                      </router-link>
                                      <br v-if="report.answers.length>0">
                                        <router-link v-if="report.answers.length===0" :to="`/forms/` + report.id"  class="btn btn-default">
                                            <i class="ti-check-box"></i> Заповнити звіт
                                        </router-link>
                                        <button v-if="report.answers.length>0" @click="openReport(report.id)" class="btn btn-default"><i class="ti-check-box"></i> Заповнити новий звіт</button>
                                    </el-collapse-item>
                                </el-collapse>
                            </template>
                            <template v-else>
                                <el-collapse class="panel-group">
                                    <el-collapse-item :title="i + 1 +' '+ report.name + ' ( '+ report.election + ' )'" :item="report.report_id" :answer="report.id" :name="report.id" v-for="(report,i) in reports" :key="report.id" >
                                        <div v-html="report.description"></div>
                                        <router-link :to="{path:`/forms/` + report.report_id,  query: { answer: report.id }}"  class="btn btn-default">
                                            <i class="ti-check-box"></i> Редагувати
                                        </router-link>
                                    </el-collapse-item>
                                </el-collapse>
                            </template>

                        </v-tab>
                    </vue-tabs>


            </div>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import Vue from 'vue'
    import {Collapse} from 'element-ui'
    import ElCollapseItem from './collapse-item'
    import {VueTabs, VTab} from './vue-nav-tabs'
    import 'vue-nav-tabs/themes/vue-tabs.css'
    import swal from "sweetalert2";
    import moment from "moment";
    Vue.use(Collapse)

    export default {
        name: "Forms",
        components: {
            VueTabs,
            VTab,
            ElCollapseItem
            },
        data () {
            return {
                tabSelect: 'Нездані',
                all_reports: [

                    {
                        id: 0,
                        title: 'Нездані'
                    },
                    {
                        id:1,
                        title:'В процесі',
                        color: false
                    },
                    {
                        id:2,
                        title:'Перевірка'
                    },
                    {
                        id:3,
                        title:'Здано'
                    }
                ],
                reports: null,
                loading: true,
                errored: false,

            }
        },
        created() {
            this.setTable()
            this.checkColor()
        },
        watch: {
            $route: ['getReports', 'setTable', 'checkColor']
        },
        filters: {
          formatDate(value){
            if (value) {
              return moment(String(value)).locale("uk").format('DD.MM.YYYY HH:MM')
            }
          }
        },
        methods: {
          openReport(report_id){
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете заповнити новий звіт?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.$router.push({ path: `/forms/` + report_id })
              }
            })
          },
          checkColor(){
            if (this.$route.path === '/forms') {
              this.axios
                  .get('/api/v1/answers',  {params: {user_id: this.$store.state.user_id, type: 'count_color'}})
                  .then(response => ( this.all_reports[1].color = response.data.count_color ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(() => (this.loading = false));
            }
          },
            setTable(){
                if (this.$route.path === '/forms') {
                    // console.log(this.tabSelect)
                    if (this.tabSelect==='Нездані'){
                        this.tabSelect = ''
                        this.getReports('no_answer')
                    } else if (this.tabSelect==='В процесі'){
                        this.tabSelect = 'В процесі'
                        this.getAnswers('process')
                    } else if (this.tabSelect==='Перевірка'){
                        this.tabSelect = 'Перевірка'
                        this.getAnswers('finish')
                    } else if (this.tabSelect==='Здано'){
                        this.tabSelect = 'Здано'
                        this.getAnswers('end')
                    } else {
                        this.reports = []
                    }
                }
            },
            handleTabChange(tabIndex, newTab){
                // console.log(tabIndex, newTab.title, oldTab.title)
                if (newTab.title=='Нездані') {
                    this.getReports('no_answer')
                } else if (newTab.title=='В процесі') {
                    this.getAnswers('process')
                } else if (newTab.title=='Перевірка') {
                    this.getAnswers('finish')
                } else if (newTab.title=='Здано') {
                    this.getAnswers('end')
                }else{
                    this.reports = []
                }
            },
            getAnswers(type){
                if (this.$route.path === '/forms') {
                    this.axios
                        .get('/api/v1/answers',  {params: {user_id: this.$store.state.user_id, type: type}})
                        .then(response => (this.reports = response.data))
                        .catch(error => {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch("logout")
                            } else {
                                console.log(error);
                                this.errored = true;
                            }
                        })
                        .finally(() => (this.loading = false));
                }
            },
            getReports(){
               if (this.$route.path === '/forms') {
                   this.axios
                       .get('/api/v1/reports', {params: { type: 'report'}})
                       .then(response => (this.reports = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }

                       })
                       .finally(() => (this.loading = false));
               }
            }
        }
    }
</script>
<style>

</style>