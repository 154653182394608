<template>
    <div class="row">
        <div class="col-md-12">
            <h2>Розділ: {{ chapter }}</h2>
        </div>
        <template v-if="$route.name === 'Запитання'">
        <div class="row">
            <div class="col-xs-3 col-xs-offset-7">
                <router-link class="btn btn-primary" :to="'/admin/reports/'+ $route.params.report_id +'/chapters/'+ $route.params.chapter_id +'/questions/new'"><i class="ti-plus"></i> Додати</router-link>
            </div>
        </div>
        <div class="col-md-6" v-for="question in questions" :key="question.id">
            <time-line type="simple">
                <time-line-item class="timeline-inverted" badge-type="success" badgeIcon="ti-gallery">
                    <span slot="header" class="label label-success">{{ question.number }}</span>
                    <span slot="header" class="label label-danger">{{ getType(question.type_field) }}</span>
                    <p slot="body" v-html="question.question">

                    </p>

                    <div slot="footer">
                        <div class="row">
                        <div class="ol-xs-12 col-md-6 col-lg-4" v-if="[4,5,6,11].includes(question.type_field)">
                            <router-link :to="`/admin/reports/` + $route.params.report_id + `/chapters/`+ $route.params.chapter_id +'/questions/' + question.id + '/answeroptions'"  class="btn btn-sm btn-default">
                                <i class="ti-check-box"></i> Опції
                            </router-link>
                        </div>
                        <div class="ol-xs-12 col-md-6 col-lg-4" v-if="question.type_field === 12">
                            <router-link :to="`/admin/reports/` + $route.params.report_id + `/chapters/`+ $route.params.chapter_id +'/questions/' + question.id + '/table_columns'"  class="btn btn-sm btn-default">
                                <i class="ti-check-box"></i> Колонки
                            </router-link>
                        </div>
<!--                        <div class="col-xs-12 col-md-2">-->
<!--                            <button class="btn btn-primary btn-rotate">-->
<!--                                <i class="ti-settings"></i> Кількість-->
<!--                            </button>-->
<!--                        </div>-->
                        <div class="ol-xs-12 col-md-6 col-lg-4">
                            <button class="btn btn-sm btn-warning btn-fill btn-magnify" @click="copyQuestion(question.id)">
                                <i class="ti-camera"></i> Копіювати
                            </button>
                        </div>
                        <div class="ol-xs-12 col-md-6 col-lg-4">
                            <router-link :to="`/admin/reports/` + $route.params.report_id + '/chapters/'+ $route.params.chapter_id +'/questions/' + question.id" class="btn btn-sm btn-success btn-fill btn-rotate">
                                <i class="ti-pencil"></i> Редагувати
                            </router-link>
                        </div>
                        <div class="ol-xs-12 col-md-6 col-lg-4">
                            <button class="btn btn-sm btn-danger" @click="removeQuestion(question.id)">
                                <i class="fa fa-times"></i> Видалити
                            </button>
                        </div>
                    </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        </template>
        <router-view></router-view>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    import TimeLine from './TimeLine.vue'
    import TimeLineItem from './TimeLineItem.vue'
    export default {
        name: "Questions",
        components: {
            TimeLine,
            TimeLineItem },
        data () {
            return {
                chapter: null,
                questions: null,
                loading: true,
                errored: false
            }
        },
        created() {
            this.getChapter()
            this.getQuestions()
        },
        watch: {
            $route: ['getChapter','getQuestions']
        },
        methods: {
            getType(type){
              console.log(type)
               const types = [
                    // [['Numeric', 0], ['String', 1], ['Text', 2], ['Date', 3], ['Selects', 4], ['Radio-buttons', 5],
                    // ['Check-boxes', 6], ['Regions', 7], ['OVK', 8], ['OVKs', 9], ['Description', 10], ['Select-nested', 11],  ['Table', 12], ['Party', 13]]
                    {value: 0, label: 'Цифра'},
                    {value: 1, label: 'Строка'},
                    {value: 2, label: 'Техт'},
                    {value: 3, label: 'Дата'},
                    {value: 4, label: 'Список'},
                    {value: 5, label: 'Радіо-Баттон'},
                    {value: 6, label: 'Чек Бокси'},
                    {value: 7, label: 'Регіон'},
                    {value: 8, label: 'ТВК'},
                    {value: 9, label: 'ОВК-декілька'},
                    {value: 10, label: 'Description'},
                    {value: 11, label: 'Список-декілька'},
                    {value: 12, label: 'Таблиця'},
                    {value: 13, label: 'Партії'},
                    {value: 14, label: 'Період з - до'},
                    {value: 15, label: 'Райони'},
                    {value: null, label: 'Не вибрано'  }
                ]
                  return  types.filter( t => t.value === type )[0].label
            },
            getChapter(){
                this.axios
                    .get("/api/v1/reports/" + this.$route.params.report_id + '/chapters/' + this.$route.params.chapter_id )
                    .then(response => (this.chapter = response.data.chapter_name))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
                console.log('Call Api')
            },
            getQuestions(){
               if (this.$route.name === 'Запитання') {
                   this.axios
                       .get('/api/v1/reports/'+ this.$route.params.report_id +'/chapters/'+ this.$route.params.chapter_id +'/questions')
                       .then(response => (this.questions = response.data))
                       .catch(error => {
                           if (error.response && error.response.status === 401) {
                               this.$store.dispatch("logout")
                           } else {
                               console.log(error);
                               this.errored = true;
                           }
                       })
                       .finally(() => (this.loading = false));
                       }
            },
            copyQuestion(id) {
                this.axios
                    .get('/api/v1/reports/'+ this.$route.params.report_id +'/chapters/'+  this.$route.params.chapters_id + '/questions/copy/' + id)
                    .then(response => (this.questions.unshift(response.data)))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            removeQuestion(id) {

                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Ви впевнені?',
                    text: "Ви хочете видалити запитання?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Так, видалити його!',
                    cancelButtonText: 'Ні, відміна!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.axios
                            .delete('/api/v1/reports/'+ this.$route.params.report_id +'/chapters/'+ this.$route.params.chapter_id +'/questions/' + id )
                            .then(this.questions = this.questions.filter( r => r.id !== id ))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                swalWithBootstrapButtons.fire(
                                    'Видалено!',
                                    'Запитання успішно видалено.',
                                    'success'
                                )

                            ));
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Відміна',
                            'Запитання збережено :)',
                            'error'
                        )
                    }
                })
            }
        }
    }
</script>
<style>

</style>