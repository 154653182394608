<template>
    <div class="row">
      <div class="col-xs-12">
        <button class="btn btn-danger" v-if="$route.params.id && ($store.state.admin || $store.state.role==='Regional' || $store.state.role==='lawyer')" @click="removeIncident()">
          {{ incident.remove ? "Відновити" : "Видалити"}}</button>
        <p class="alert alert-warning" v-if="$route.params.id && incident.user">Порушення було створене {{incident.user.name}}</p>
        <p class="alert alert-info" v-if="verefitication_first">{{ verefitication_first.updated_at | formatDate }} Порушшення було {{ verefitication_first.value === 1 ? "підтверджено" : "не підтверджено" }}
          {{ verefitication_first.user.role==="Admin" ? "адміністратором" :   verefitication_first.user.role==="Regional" ? "регіональним координатором" :  verefitication_first.user.role==="coordinator" ? "облсним координатором" : "юристом" }} {{ verefitication_first.user.name }}
        </p>
       <!-- <p class="alert alert-info" v-if="verefitication">{{ verefitication.updated_at | formatDate }} Порушшення було {{ verefitication.value === 1 ? "верифіковано" : "не верифіковано" }}
          {{ verefitication.user.role==="Admin" ? "адміністратором" :   verefitication.user.role==="Regional" ? "регіональним координатором" : "юристом" }} {{ verefitication.user.name }}
        </p>-->
        <p class="alert alert-info" v-if="verefiticationLawyer">{{ verefiticationLawyer.updated_at | formatDate }} Порушшення було {{ verefiticationLawyer.value === 1 ? "Юрифіковано" : "Доопрацювати" }}
          {{ verefiticationLawyer.user.role==="Admin" ? "адміністратором" : "юристом" }} {{ verefiticationLawyer.user.name }}
        </p>

      </div>
      <div class="well" v-if="verefiticationLawyer && verefiticationLawyer.value !== 1">
        <div class="row">
          <label class="col-sm-6 control-label">Доповнено</label>
          <p-switch :disabled="disableEdit()" v-model="incident.added_fix"  @input="changeValue()" type="primary"></p-switch>
        </div>
      </div>

      <div class="col-md-8 col-md-offset-2">
          <div class="card card-wizard" id="wizardCard">
                <form-wizard
                        shape="square"
                        stepSize="xs"
                        stepsClasses="center"
                        @on-complete="wizardComplete"
                        @on-change="scrollToTop()"
                        title=""
                        id="incident"
                        :start-index="0"
                        subtitle=""
                        centered
                >
                    <tab-content title="Область/Тип" :before-change="checkDateBeforeTabSwitch">

                        <div class="col-md-5 col-md-offset-4">
                            <div class="card form-horizontal">
                                <div class="card-content">
                                    <div class="form-group">
                                        <label class="control-label">Дата</label>
                                        <el-date-picker :disabled="disableEdit()" type="date" placeholder="Виберіть дату"
                                                        :picker-options="pickerOptions1"
                                                        v-model="incident.date"
                                                        @change="changeValue()"
                                                        aria-required="true"
                                        >
                                        </el-date-picker>
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label">Область</label>
                                        <el-select class="select-default"
                                                   :disabled="disableEdit()"
                                                   size="large"
                                                   placeholder="Single Select"
                                                   v-model="incident.region_id"
                                                   @change="changeValue()"
                                        >
                                            <el-option v-for="option in regions"
                                                       class="select-default"
                                                       :value="option.id"
                                                       :label="option.name"
                                                       :key="option.id">
                                            </el-option>
                                        </el-select>
                                    </div>
                                  <div class="form-group">
                                    <label class="control-label">Тип Виборів</label>
                                    <el-select class="select-default"
                                               size="large"
                                               clearable
                                               placeholder="Single Select"
                                               v-model="incident.election_type_id"
                                               @change="changeValue()"
                                    >
                                      <el-option v-for="option in election_type"
                                                 class="select-default"
                                                 :value="option.id"
                                                 :label="option.name"
                                                 :key="option.id">
                                      </el-option>
                                    </el-select>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-10 col-sm-offset-2">
                            <div class="card form-horizontal">
                                <div class="card-header">
                                    <h4 class="card-title">Район</h4>
                                </div>
                                <div class="card-content">
                                    <div class="well" v-if="incident.region_id && regions.length > 0" >
                                        <label class="btn btn-wd" :class="incident.district_id===d.id ? 'active' : ''" v-for="(d, i) in regions[regions.findIndex(r => r.id === incident.region_id )].districts" :key="d.id">
                                            <input type="radio"  :disabled="disableEdit()" :id="`option` + i" :value="d.id"  @change="changeValue()" v-model="incident.district_id" autocomplete="off"> {{d.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-10 col-sm-offset-2">
                            <div class="card form-horizontal">
                                <div class="card-header">
                                    <h4 class="card-title">Тип</h4>
                                </div>
                                <div class="card-content">
                                    <div class="well">
                                        <label class="btn btn-wd" :class="incident.incident_type===d.id ? 'active' : ''" v-for="(d, i) in incident_types" :key="d.id">
                                            <input type="radio" :disabled="disableEdit()" :id="`option` + i" :value="d.id"  @change="changeValue()" v-model.number="incident.incident_type" autocomplete="off"> {{d.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-10 col-sm-offset-2">
                            <div class="card form-horizontal">
                                <div class="card-header">
                                    <h4 class="card-title">Підтип</h4>
                                </div>
                                <div class="card-content">
                                    <div class="well">
                                        <ul class="list-unstyled">
                                          <li v-for="d in incident_sub_types" :key="d.id">
                                            <label class="btn btn-wd" :class="incident.incident_sub_type===d.id ? 'active' : ''">
                                              <input type="radio" :disabled="disableEdit()" :id="`option` + d.id" :value="d.id"  @change="changeValue()" v-model.number="incident.incident_sub_type" autocomplete="off"> {{d.name}}
                                            </label>
                                            <button class="btn btn-icon btn-info" @click="showDescription(d.name, d.description)"><i class="ti-help"></i></button>
                                          </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab-content>
                    <tab-content title="Опис" :before-change="beforeTabSwitch">
                        <div class="col-md-8 col-md-offset-2">
                            <div class="card form-horizontal">
                                <div class="card-header">
                                    <h4 class="card-title">Заголовок</h4>
                                </div>
                                <div class="card-content">
                                    <div class="form-group">
                                        <input type="text" :disabled="disableEdit()" v-model="incident.title"  @change="changeValue()" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 col-md-offset-2">
                            <div class="card form-horizontal">
                                <div class="card-header">
                                    <h4 class="card-title">На користь</h4>
                                </div>
                                <div class="card-content">
                                  <div class="form-group">
                                    <label class="control-label">Партія</label>
                                    <Select2
                                        :disabled="disableEdit()"
                                        @change="changeValue()"
                                        clearable
                                        v-model.number="incident.offender_id"
                                        :options="parties"
                                        :settings="{ width: '100%', allowClear: true}"
                                    />
                                  </div>
                                  <label class="control-label">Деталі (ПІБ і посада особи)</label>
                                    <div class="form-group">
                                        <input type="text" :disabled="disableEdit()" v-model="incident.offender"  @change="changeValue()" class="form-control">
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                        <div class="card form-horizontal">
                            <div class="card-header">
                                <h4 class="card-title">Опис</h4>
                            </div>
                            <div class="card-content">
                                <div class="form-group">
                                    <ckeditor :read-only="disableEdit()" v-model="incident.description"   @blur="changeValue()"  :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-12">
                            <el-collapse class="panel-group">
                                <el-collapse-item title="Проти кого"  name="chat" >
                                    <div class="card form-horizontal">
                                        <div class="card-content">
                                          <div class="form-group">
                                            <label class="col-sm-2 control-label">Партія</label>
                                            <Select2
                                                :disabled="disableEdit()"
                                                @change="changeValue()"
                                                v-model.number="incident.victim_id"
                                                :options="parties"
                                                :settings="{ width: '100%', allowClear: true}"
                                            />
                                          </div>
                                          <div class="form-group">
                                              <label class="col-sm-5 control-label">Деталі (ПІБ і посада особи)</label>
                                              <input :disabled="disableEdit()" v-model="incident.victim"  @change="changeValue()" type="text" class="form-control">
                                          </div>


                                        </div>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title"></h4>
                                </div>
                                <div class="card-content">
                                    <div class="well">
                                        <label class="btn btn-wd" :class="incident.picked===1 ? 'active' : '' " >
                                            <input type="radio" :disabled="disableEdit()" value="1" id="option1" autocomplete="off"  @change="changeValue()" v-model.number="incident.picked"> Порушення закону
                                        </label>
                                        <label class="btn btn-wd" :class="incident.picked===2 ? 'active' : '' ">
                                            <input type="radio" :disabled="disableEdit()" value="2" id="option2" autocomplete="off"  @change="changeValue()" v-model.number="incident.picked" > Недотримання стандрту
                                        </label>
                                    </div>

                                    <div class="well">
                                        <label class="col-sm-6 control-label">Підтвержене судомвим рішенням</label>
                                        <p-switch disabled="true" v-model="incident.court"  @input="changeValue()" type="primary"></p-switch>
                                        <div class="row" v-if="incident.court===true">
                                            <div class="col-sm-1">
                                                <label class="control-label">посилання ЄРСР</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input :disabled="disableEdit()" v-model="incident.court_url"  @change="changeValue()" type="text" class="form-control" >
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <label class="col-sm-6 control-label">Порушення з використанням адмін ресурсу</label>
                                        <p-switch :disabled="disableEdit()" v-model="incident.admin_resource"  @input="changeValue()" type="primary"></p-switch>
                                    </div>
                                  <div class="well">
                                  <div class="row">
                                    <label class="col-sm-6 control-label">Internet</label>
                                    <p-switch :disabled="disableEdit()" v-model="incident.internet"  @input="changeValue()" type="primary"></p-switch>
                                  </div>
                                  </div>
                                    <div class="row" v-if="($store.state.admin==='true') || ($store.state.role ==='lawyer')">
                                        <label class="col-sm-6 control-label">TK</label>
                                        <p-switch v-model="incident.tk"  @input="changeValue()" type="primary"></p-switch>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </tab-content>
                    <tab-content title="Файл/Координати" >

                        <fieldset>
                            <div class="form-group">
                                <l-map ref="map" v-if="showMap===true" style="height: 350px; width: 100%" :zoom="zoom" :center="center"  @click="addMarker">
                                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                                    <template v-for="(inci, i) in incident_points">
                                        <l-marker v-if="inci.lat && inci.lng" :key="i" :lat-lng="[inci.lat, inci.lng]" >
                                            <!--                                <l-icon-->
                                            <!--                                        :icon-anchor="staticAnchor"-->
                                            <!--                                        class-name="someExtraClass">-->
                                            <!--                                    <div class="headline">{{ customText }}</div>-->
                                            <!--                                    <img src="/images/layers.png">-->
                                            <!--                                </l-icon>-->
                                        </l-marker>
                                    </template>

                                </l-map>
                            </div>
                        </fieldset>
                        <el-collapse class="panel-group" v-model.number="activeName" accordion>
                        <div class="well" v-for="(inci, i) in incident_points" :key="i">
                            <el-collapse-item :title="`Point `+ (i+1)"  :name="i">
                              <div class="row">
                                <div class="col-xs-12">
                                  <p class="alert alert-danger">&nbsp; Увага! При заповнені або зміні даних в цьому блоці, не забудьте натискати кнопку Зберегти</p>
                                </div>
                                <div class="col-xs-3 col-xs-offset-9" v-if="checkLayer()">
                                  <label class="control-label">Публікація</label>
                                  <p-switch v-model="inci.public" @input="changeIncidentPoint(i)" type="primary" on-text="Так" off-text="Ні"></p-switch>
                                </div>
                                <div class="col-xs-1 col-xs-offset-11">
                                  <a :disabled="disableEdit()" v-if="!inci.incident_point_files || inci.incident_point_files.length===0" @click="removeIncidentPoint( i )"
                                     class="btn btn-simple btn-danger btn-xs btn-icon remove"><i class="ti-close"></i></a>
                                </div>
                              </div>
                        <fieldset>
                            <div class="form-group">
                                <label class="control-label">Адреса</label>
                              <p class="text-warning">Вкажіть населений пункт, якщо адресу не знайдено</p>

                                <input :disabled="disableEdit()" v-model="inci.address"
                                       @input="debouncedGetAnswer()"
                                       @change="changeIncidentPoint(i)"
                                       type="text" class="form-control">
                                <table
                                        v-show="results.length">
                                    <tbody>
                                    <tr v-for="(res, i) in results"
                                        :key="i"
                                        @click="complete(i, inci)">
                                        <td>{{ res.label }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <div class="col-sm-6">
                                    <label class="control-label">Lat</label>
                                    <input :disabled="disableEdit()" v-model.lazy="inci.lat" @change="changeIncidentPoint(i)" type="text" class="form-control">
                                </div>
                                <div class="col-sm-6">
                                    <label class="control-label">Lng</label>
                                    <input :disabled="disableEdit()" v-model.lazy="inci.lng" @change="changeIncidentPoint(i)" type="text" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <div class="card-content">
                            <div class="form-group">
                              <label class="control-label">Опис</label>
                                <textarea :disabled="disableEdit()" v-model.lazy="inci.description_point" @change="changeIncidentPoint(i)" class="form-control" ></textarea>
                            </div>
                        </div>
                        <br>
                        <fieldset>
                          <div class="form-group" v-if="inci.incident_point_files">
                            <div v-if="inci.incident_point_files.length>0">
                                <div class="demo-image__preview">
                                  <div class="col-sm-3 well" v-for="image in inci.incident_point_files" :key="image.id">
                                  <el-image
                                      v-if="image.file.url.split('.').pop().toLowerCase()==='jpg' ||
                                          image.file.url.split('.').pop().toLowerCase()==='png' ||
                                          image.file.url.split('.').pop().toLowerCase()==='jpeg' ||
                                          image.file.url.split('.').pop().toLowerCase()==='tiff' ||
                                          image.file.url.split('.').pop().toLowerCase()==='bmp' ||
                                          image.file.url.split('.').pop().toLowerCase()==='gif' ||
                                          image.file.url.split('.').pop().toLowerCase()==='webp' ||
                                          image.file.url.split('.').pop().toLowerCase()==='jfif'"

                                    style="width: 100px; height: 100px"
                                      :src="'..' + image.file.url"
                                      :preview-src-list="inci.colorbox">
                                  </el-image>
                                    <p><a :href="'..'+image.file.url" target="_blank">Переглянути</a></p>
                                  <template v-if="checkLayer()">
                                    <label class="control-label">Публікація</label>
                                    <p-switch v-model="image.public"  @input="changeIncidentPoint(i)" type="primary" on-text="Так" off-text="Ні"></p-switch>
                                  </template>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                              <div class="well">
                                <div class="card-content">

                                  <div class="el-upload-list el-upload-list--text">
                                    <div class="row">
                                      <div v-for="(file, key) in inci.files" :key="key" class="col-md-5"><i class="el-icon-document"></i> {{ file.name }}
                                        <button class="btn btn-danger btn-simple" v-on:click="removeFile( key )"><i class="el-icon ti-trash"></i></button>
                                      </div>
                                    </div>
                                  </div>
                                  <label class="btn btn-success btn-fill btn-wd"> <i class="ti-upload"> Завантажити файл</i>
                                    <input type="file" :disabled="disableEdit()" id="incident_files" ref="incident_files" multiple v-on:change="handleFilesUpload($event)"/>
                                  </label>
                                  <br>
                                  <progress v-if="inci.file_upload" max="100" :value.prop="inci.uploadPercentage"></progress>
                                  <br>
                                </div>
                              </div>
                              <button v-if="inci.change" class="btn btn-primary btn-fill btn-wd" v-on:click="submitFiles()"><i class="ti-save"> Зберегти</i></button>
                            </div>
                        </fieldset>
                            </el-collapse-item>
                        </div>
                        </el-collapse>
                        <fieldset>
                            <div class="form-group">

                                <div class="col-md-12 well">
                                    <button :disabled="disableEdit()" class="btn btn-info btn-fill btn-wd" @click="addIncidentPoint"> <i class="ti-plus"></i> Додати ще координату</button>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <div class="col-md-12 well">
                                    <template v-if="incident.incident_police">
                                      <button @click="police=!police" class="btn btn-danger btn-fill btn-wd">Заява в поліцію</button>
                                      <button class="btn btn-danger btn-simple" v-on:click="removeIncidentPolices( incident.incident_police.id )"><i class="el-icon ti-trash"></i> Відєднати заяву</button>
                                    </template>
                                    <template v-else>
                                      <label class="control-label">Додати заяву до поліції</label>
                                      <Select2
                                          ref="select2"
                                          @change="changeValue()"
                                          v-model.number="police_id"
                                          :options="polices"
                                          :settings="{ placeholder: 'Виберіть заяву' }"
                                      />
                                      <br>
                                      <button v-if="police_id" @click="addPolice(police_id)"  class="btn btn-info">Додати</button>
                                      <p>або створіть нову</p>
                                      <br>
                                      <button @click="police=!police" class="btn btn-danger btn-fill btn-wd">Створити Заява в поліцію</button>
                                    </template>

                                    <Police v-if="police" :date-incident="incident.date"  @closePolice="police=!police"
                                            :region-incident="incident.region_id" :district-incident="incident.district_id"
                                            :id-incident="incident.id" :id-police="incident.incident_police ? incident.incident_police.police_id : null" ></Police>
                                </div>

                                <div class="col-md-12 well" v-if="$route.params.id && $route.path.match('/incidents/') ">
                                    <button @click="event_new=!event_new" class="btn btn-warning btn-fill btn-wd">{{ incident.event_incident ? "Подія" : "Створити Подію" }}</button>
                                  <Event v-if="event_new" @closeEvent="event_new=!event_new" :date-incident="incident.date"
                                         :region-incident="incident.region_id" :district-incident="incident.district_id"
                                         :id-incident="incident.id" :id-event="incident.event_incident ? incident.event_incident.event_id : null"
                                  ></Event>
                                </div>
                            </div>
                        </fieldset>

                    </tab-content>
                    <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Назад</button>
                    <button slot="next" class="btn btn-info btn-fill btn-wd btn-next">Далі</button>
                    <button slot="finish" class="btn btn-info btn-fill btn-wd">Готово</button>
                </form-wizard>
            </div>
        </div>

      <div class="row" v-if="$store.state.role==='Admin' || $store.state.role ==='Regional' || $store.state.role ==='coordinator' || $store.state.role ==='lawyer'">
        <div class="col-xs-12">
          <div class="card-content">
            <div class="well">
              <h2>Підтвердження</h2>
              <label class="btn btn-wd" :class="verefitication_first ? verefitication_first.value===1 ? 'active' : '' : ''" >
                <input type="radio" :disabled="disableEdit()" value="1" id="option3" autocomplete="off"  @change="chengeIncidentFirstVerefitication()" v-model.number="val_verification_first"> Підтверджено
              </label>
              <label class="btn btn-wd" :class="verefitication_first ? verefitication_first.value===2 ? 'active' : '' : ''">
                <input type="radio" :disabled="disableEdit()" value="2" id="option4" autocomplete="off"  @change="chengeIncidentFirstVerefitication()" v-model.number="val_verification_first" > Не підтверджено
              </label>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="row" v-if="$store.state.role ==='Admin' || $store.state.role ==='Regional' || $store.state.role ==='lawyer'">
        <div class="col-xs-12">
          <div class="card-content">
            <div class="well">
              <h2>Верифікація</h2>
              <label class="btn btn-wd" :class="verefitication ? verefitication.value===1 ? 'active' : '' : ''" >
                <input type="radio" :disabled="disableEdit()" value="1" id="option1" autocomplete="off"  @change="chengeIncidentVerefitication()" v-model.number="val_verification"> Верифіковано
              </label>
              <label class="btn btn-wd" :class="verefitication ? verefitication.value===2 ? 'active' : '' : ''">
                <input type="radio" :disabled="disableEdit()" value="2" id="option2" autocomplete="off"  @change="chengeIncidentVerefitication()" v-model.number="val_verification" > Не верифіковано
              </label>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row" v-if="$store.state.role==='Admin' || $store.state.role ==='lawyer'">
        <div class="col-xs-12">
          <div class="card-content">
            <div class="well">
              <h2>Юрифікація</h2>
              <label class="btn btn-wd" :class="verefiticationLawyer ? verefiticationLawyer.value===1 ? 'active' : '' : ''" >
                <input type="radio" :disabled="disableEdit()" value="1"  autocomplete="off"  @change="changeIncidentLawyerVerefitication()" v-model.number="val_verificationLawyer"> Юрифіковано
              </label>
              <label class="btn btn-wd" :class="verefiticationLawyer ? verefiticationLawyer.value===2 ? 'active' : '' : ''">
                <input type="radio" :disabled="disableEdit()" value="2"  autocomplete="off"  @change="changeIncidentLawyerVerefitication()" v-model.number="val_verificationLawyer" > Доопрацювати
              </label>
            </div>
          </div>
        </div>
      </div>

        <div class="col-md-5 col-md-offset-2" v-if="$route.params.id && $route.path.match('/incidents/') ">
            <el-collapse class="panel-group"  v-model="showChat">
                <el-collapse-item title="Коментарі"  name="chat"  >
                    <div class="card card-chat ">
                        <div class="card-header">
                            <!--                            <h4 class="card-title">Коментарі</h4>-->
                            <!--                            <p class="category">відносно звіту</p>-->
                        </div>
                        <div class="card-content">
                            <ol class="chat">
                                <li :class="comment.user_id === $store.state.user_id ? 'self' : 'other'" v-for="comment in report.comments" :key="comment.id">
                                    <div class="avatar">
                                        <img v-if="comment.user_id === $store.state.user_id"  src="/static/img/default-avatar.png" alt="crash"/>
                                        <img v-else src="/static/img/faces/face-2.jpg" alt="crash"/>
                                    </div>
                                    <div class="msg">
                                        <p>
                                            {{ comment.message }}
                                        </p>
                                        <div class="card-footer">
                                            <i class="ti-check"></i>
                                            <h6>{{ comment.created_at | formatDate }}</h6>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            <hr>
                            <form  @submit.prevent="toSend">
                                <div class="send-message">
                                    <div class="avatar">
                                        <img src="/static/img/default-avatar.png" alt="crash"/>
                                    </div>

                                    <input v-model.trim="message" class="form-control textarea" type="text" placeholder="Друкуй тут!"/>
                                    <div class="send-button">
                                        <button class="btn btn-xs btn-primary btn-fill">Зберегти</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>

        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import {DatePicker, Select, Option, Collapse, CollapseItem, Image} from 'element-ui'
    import PSwitch from "../../../../UIComponents/Switch"
    import Select2 from 'v-select2-component';
    import 'leaflet-geosearch/assets/css/leaflet.css'
    import 'leaflet/dist/leaflet.css';
    import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
    // import L from 'leaflet';
    import { LMap, LTileLayer, LMarker} from 'vue2-leaflet';
    import { OpenStreetMapProvider } from 'leaflet-geosearch';
    import 'leaflet-defaulticon-compatibility';
    import Event from "../Events/Event"
    import Police from "../Police/Police";
    import swal from 'sweetalert2'
    import 'sweetalert2/dist/sweetalert2.css'
    //import incident_types  from './incident_type'
    import _ from 'lodash'

    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Vue.use(Collapse)
    Vue.use(CollapseItem)
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import moment from "moment";
    export default {
        name: 'Incident',
        components: {
            LMap,
            LTileLayer,
            LMarker,
            // LIcon,
            FormWizard,
            TabContent,
            [DatePicker.name]: DatePicker,
            [Option.name]: Option,
            [Select.name]: Select,
            [Image.name]: Image,
            Police,
            Select2,
            PSwitch,
            Event
        },
        created() {
          this.debouncedGetAnswer = _.debounce( this.getGeoResults, 600)
        },
        mounted() {
          this.getRegions()
          this.getElect()
          //this.getIncidentTypes()
          this.getParties()
          this.getIncident()
          this.getIncidentPoints()
          this.getIncidentVerefitication()
          this.getIncidentLawyerVerefitication()
          this.getIncidentFirstVerefitication()
          this.getComments()
          this.getPolices()

          this.$root.$once('createPolice',  ()=>(
              this.getIncident()
          ))
          this.$root.$once('createEvent',  ()=>(
              this.getIncident()
          ))
        },
        filters: {

            formatDate(value){
                if (value) {
                    return moment(String(value)).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        computed: {

          election_type_id(){
            return this.incident.election_type_id
          },
          incident_type(){
              return this.incident.incident_type
          },

        },
        props: [
          'dateEvent', 'regionEvent', 'districtEvent', 'idEvent'
        ],
        watch: {
          incident_type: 'getIncidentSubType',
          election_type_id: 'getIncidentTypes',
          regions: 'setRegions',
        },
        methods: {
          getElect(){
            this.axios
                .get("/api/v1/election_types/" )
                .then(response => (this.election_type = response.data))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(() => (this.loading = false));
          },
          scrollToTop(){
            document.getElementById('incident').scrollIntoView();
          },
          chengeIncidentVerefitication(){
            let incident_id = this.$route.params.id
            if (incident_id) {
              if (this.verefitication){
                this.axios
                    .patch('/api/v1/incident_verefication/'+ incident_id +'/update' , { verification:  this.val_verification })
                    .then(response => (
                        this.verefitication = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              } else {
                this.axios
                    .post('/api/v1/incident_verefication/'+ incident_id +'/create' , { verification:  this.val_verification })
                    .then(response => (
                        this.verefitication = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              }


            }
          },
          chengeIncidentFirstVerefitication(){
            let incident_id = this.$route.params.id
            if (incident_id) {
              if (this.verefitication_first){
                this.axios
                    .patch('/api/v1/incident_first_verefication/'+ incident_id +'/update'  , { verification:  this.val_verification_first })
                    .then(response => (
                        this.verefitication_first = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              } else {
                this.axios
                    .post('/api/v1/incident_first_verefication/'+ incident_id +'/create' , { verification:  this.val_verification_first })
                    .then(response => (
                        this.verefitication_first = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              }


            }
          },
          changeIncidentLawyerVerefitication(){
            let incident_id = this.$route.params.id
            if (incident_id) {
              if (this.verefiticationLawyer){
                this.axios
                    .patch('/api/v1/incident_ver_lawyers/'+ incident_id +'/update'  , { verification:  this.val_verificationLawyer })
                    .then(response => (
                        this.verefiticationLawyer = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              } else {
                this.axios
                    .post('/api/v1/incident_ver_lawyers/'+ incident_id +'/create' , { verification:  this.val_verificationLawyer })
                    .then(response => (
                        this.verefiticationLawyer = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              }


            }
          },
          getIncidentFirstVerefitication(){
            let incident_id = this.$route.params.id
            if (incident_id) {
              this.axios
                  .get('/api/v1/incident_first_verefication/'+ incident_id + '/show')
                  .then(response => (
                      this.verefitication_first = response.data
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(
                      // console.log($(this.$refs.select2)),
                      // $(this.$refs.select2).trigger("change")
                  )
            }
          },
          getIncidentLawyerVerefitication(){
            let incident_id = this.$route.params.id
            if (incident_id) {
              this.axios
                  .get('/api/v1/incident_ver_lawyers/'+ incident_id +'/show')
                  .then(response => (
                      this.verefiticationLawyer = response.data
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(
                      // console.log($(this.$refs.select2)),
                      // $(this.$refs.select2).trigger("change")
                  )
            }
          },
          getIncidentVerefitication(){
            let incident_id = this.$route.params.id
            if (incident_id) {
              this.axios
                  .get('/api/v1/incident_verefication/'+ incident_id +'/show')
                  .then(response => (
                      this.verefitication = response.data
                  ))
                  .catch(error => {
                    if (error.response && error.response.status === 401) {
                      this.$store.dispatch("logout")
                    } else {
                      console.log(error);
                      this.errored = true;
                    }
                  })
                  .finally(
                      // console.log($(this.$refs.select2)),
                      // $(this.$refs.select2).trigger("change")
                  )
            }
          },
          removeIncident(){
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете видалити/відновити проушення?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .patch('/api/v1/incidents/' +  this.$route.params.id  , { incident: { remove: !this.incident.remove} })
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Видалено!',
                            'Дія виконана успішно.',
                            'success'
                        )

                    ));
              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'збережено :)',
                    'error'
                )
              }
            })
          },
          disableEdit(){
            if (this.incident.user_id && this.$store.state.role==="observ" ){
              if ( Number(this.incident.user_id) !== Number(this.$store.state.user_id) ) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          },
          checkLayer(){

            if (this.$store.state.admin==="true" || (this.$store.state.role==='lawyer') || ( this.$store.state.role==='Public' )) {
              return true
            } else {
              return false
            }
          },
          getIncidentTypes(){
            this.axios
                .get('/api/v1/get_incident_types', {params: {election_type_id: this.incident.election_type_id}})
                .then(response => (
                    this.incident_types = response.data,
                    this.getIncidentSubType()
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          getPolices(){
            this.axios
                .get('/api/v1/get_polices')
                .then(response => (
                    this.polices = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
                .finally(
                    // console.log($(this.$refs.select2)),
                    // $(this.$refs.select2).trigger("change")
                )
          },
          addPolice(id){
            this.axios
                .post('/api/v1/incident_polices', { incident_police: { incident_id: this.incident.id, police_id: id} })
                .then(response => (
                    Vue.set(this.incident, 'incident_police', response.data)
                    //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                    //Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
            // .finally(() => (
            // this.getIncidents()
            // ))

            this.police_id = null
          },
          removeIncidentPolices(id){
            const swalWithBootstrapButtons = swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
              title: 'Ви впевнені?',
              text: "Ви хочете відєднати проушення?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Так!',
              cancelButtonText: 'Ні, відміна!',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.axios
                    .delete('/api/v1/incident_polices/' + id )
                    .then(this.incident.incident_police= null)
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
                    .finally(() => (
                        swalWithBootstrapButtons.fire(
                            'Відєднано!',
                            'Порушення успішно відєднано.',
                            'success'
                        )

                    ));
              } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                    'Відміна',
                    'збережено :)',
                    'error'
                )
              }
            })
          },
          changeIncidentPoint(item){
            this.incident_points[item].change=true
          },
            setRegions(){
              if (this.regions.length===1){
                this.incident.region_id = this.regions[0].id
              }
            },
            getIncident(){
              let incident_id = this.$route.params.id
              if (incident_id) {
                this.axios
                    .get('/api/v1/incidents/' + incident_id )
                    .then(response => (
                        this.incident = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              }
            },
            getIncidentPoints(){
              let incident_id = this.$route.params.id
              if (incident_id) {
                this.axios
                    .get('/api/v1/incident_points?incident_id=' + incident_id )
                    .then(response => (
                        this.incident_points = response.data
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              }
            },
            changeValue(){
              let incident_id = this.$route.params.id
              // console.log(this.incident)
              if (this.incident.date){
                if (incident_id) {
                  this.axios
                      .patch('/api/v1/incidents/' + incident_id  , { incident: this.incident})
                      // .then(response => (
                      //     console.log(response)
                      // //     //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                      // //     Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value })
                      // ))
                      .catch(error => {
                        if (error.response && error.response.status === 401) {
                          this.$store.dispatch("logout")
                        } else {
                          console.log(error);
                          this.errored = true;
                        }
                      })
                } else {
                  this.axios
                      .post('/api/v1/incidents', { incident: this.incident})
                      .then(response => (
                          this.incident = response.data
                          //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                          //Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
                      ))
                      .catch(error => {
                        if (error.response && error.response.status === 401) {
                          this.$store.dispatch("logout")
                        } else {
                          console.log(error);
                          this.errored = true;
                        }
                      })
                      .finally(() => ( this.$router.push({ path: '/incidents/' + this.incident.id })))
                }
              } else {
                swalWithBootstrapButtons.fire(
                    'Помилка',
                    'Заповніть будь ласка коректно Дату',
                    'error'
                )
              }

            },
            addIncidentPoint(){
                this.incident_points.push({address: '', lng:null, lat:null, description_point: '', files: [], public: false,  incident_point_files: [] })
                this.activeName = this.incident_points.length - 1
            },
            complete(i, incident) {
                incident.address = this.results[i].label;
                incident.lng =  this.results[i].x  // lon,
                incident.lat =  this.results[i].y // lat,
                this.results = []
                this.$refs.map.setZoom(13)
                this.$refs.map.setCenter([incident.lat, incident.lng])
            },
            getGeoResults(){
                if (this.incident_points.length){
                  let address = this.incident_points[this.activeName].address
                  this.provider.search({ query: address }).then( (result) => (
                      this.setGeoResults(result)
                  ) );
                }
            },
            setGeoResults(result){
                this.results = result
            },
          handleFilesUpload(event){
                let uploadedFiles = event.target.files;
                for( var i = 0; i < uploadedFiles.length; i++ ){
                  this.incident_points[this.activeName].files.push( uploadedFiles[i] );
                }
                this.incident_points[this.activeName].change=true
          },
          removeFile( key ){
            this.incident_points[this.activeName].files.splice( key, 1 );
          },
          removeIncidentPoint( key ){
              if (this.incident_points[key].id){
                const swalWithBootstrapButtons = swal.mixin({
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                  },
                  buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                  title: 'Ви впевнені?',
                  text: "Ви хочете видалити координату?",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Так!',
                  cancelButtonText: 'Ні, відміна!',
                  reverseButtons: true
                }).then((result) => {
                  if (result.value) {
                    this.axios
                        .delete('/api/v1/incident_points/' + this.incident_points[key].id )
                        .then(() => (
                            this.incident_points.splice( key, 1 )
                        ))
                        .catch(error => {
                          if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                          } else {
                            console.log(error);
                            this.errored = true;
                          }
                        })
                        .finally(() => (
                            swalWithBootstrapButtons.fire(
                                'Видалено!',
                                'Координату успішно видалено.',
                                'success'
                            )

                        ));
                  } else if (
                      /* Read more about handling dismissals below */
                      result.dismiss === swal.DismissReason.cancel
                  ) {
                    swalWithBootstrapButtons.fire(
                        'Відміна',
                        'Координату збережено :)',
                        'error'
                    )
                  }
                })

              } else {
                this.incident_points.splice( key, 1 )
              }

          },
          submitFiles( ){
            let formData = new FormData();

            Vue.set(this.incident_points[this.activeName], 'file_upload', true )
            Vue.set(this.incident_points[this.activeName], 'uploadPercentage', 0 )

            formData.append('incident_point[incident_id]',  this.incident.id);
            formData.append('incident_point[address]',   this.incident_points[this.activeName].address);
            formData.append('incident_point[description_point]', this.incident_points[this.activeName].description_point);
            formData.append('incident_point[lng]',  this.incident_points[this.activeName].lng);
            formData.append('incident_point[lat]',  this.incident_points[this.activeName].lat);
            formData.append('incident_point[public]',  this.incident_points[this.activeName].public);
            // if ()
            for( var k = 0; k <  this.incident_points[this.activeName].incident_point_files.length; k++ ){
              let public_image =  this.incident_points[this.activeName].incident_point_files[k].public;
              let id =  this.incident_points[this.activeName].incident_point_files[k].id;
              formData.append('incident_point[incident_point_files_attributes[' + k + '][id]]', id);
              formData.append('incident_point[incident_point_files_attributes[' + k + '][public]]', public_image);
            }
            for( var i = 0; i <  this.incident_points[this.activeName].files.length; i++ ){
              let file =  this.incident_points[this.activeName].files[i];
              let fileExtension = file.name.split('.').pop()
              formData.append('incident_point[incident_point_files_attributes[' + (i+k) + '][file]]', file, 'incident_' + Date.now() + '.' + fileExtension );
              formData.append('incident_point[incident_point_files_attributes[' + (i+k) + '][public]]', false);
            }
            if ( this.incident_points[this.activeName].id){
              this.axios.patch( '/api/v1/incident_points/' + this.incident_points[this.activeName].id,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                      this.incident_points[this.activeName].uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))
                    }.bind(this)
                  }
              ).then(response => (
                  Vue.set( this.incident_points, this.activeName,  response.data)
              ))
                  .catch(function(){
                    console.log('FAILURE!!');
                  })
                  .finally(()=> (Vue.set(this.incident_points[this.activeName], 'file_upload', false ) ))
            } else {
              this.axios.post( '/api/v1/incident_points',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                      this.incident_points[this.activeName].uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))
                    }.bind(this)
                  }
              ).then(response => (
                  Vue.set( this.incident_points, this.activeName,  response.data)
              ))
                  .catch(function(){
                    console.log('FAILURE!!');
                  })
                  .finally(()=> (  Vue.set(this.incident_points[this.activeName], 'file_upload', false ) ))

            }
          },
            beforeTabSwitch(){
                this.showMap = true
                if (this.incident_points.length===0){
                  this.addIncidentPoint()
                }
                if (this.incident.id){
                  return true
                } else {
                  swalWithBootstrapButtons.fire(
                      'Помилка',
                      'Для переходу до останього кроку треба заповнити поля з 1 та 2 кроку',
                      'error'
                  )
                  return false
                }
            },
          checkDateBeforeTabSwitch(){
            if (this.incident.date){
              return true
            } else {
              swalWithBootstrapButtons.fire(
                  'Помилка',
                  'Заповніть будь ласка коректно Дату',
                  'error'
              )
              return false
            }
          },
            getIncidentSubType(){
               if (this.incident_types.length>0 && this.incident_type) {
                 this.incident_sub_types = this.incident_types[this.incident_types.findIndex(r => r.id === this.incident_type)].sub_types
               }
            },
            addMarker(event) {
                if (this.incident_points.length){
                    this.incident_points[this.activeName].lat = event.latlng.lat
                    this.incident_points[this.activeName].lng = event.latlng.lng
                    this.incident_points[this.activeName].change=true
                } else {
                  this.addIncidentPoint()
                  this.incident_points[0].lat = event.latlng.lat
                  this.incident_points[0].lng = event.latlng.lng
                  this.incident_points[0].change=true
                }

            },
            toSend(){
                this.axios
                    .post('/api/v1/comment_incidents', {comment_incident: {incident_id: this.$route.params.id, user_id: this.$store.state.user_id, message: this.message}} )
                    .then(response => ( this.report.comments.push(response.data) ))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (
                        this.message = null)
                    );
            },
          getComments(){
              if ( this.$route.params.id )
              {
                this.axios
                    .get('/api/v1/comment_incidents?incident_id=' +  this.$route.params.id  )
                    .then(response => ( this.report.comments = response.data,
                            this.report.comments.length > 0 ? this.showChat = "chat" : ""
                    ))
                    .catch(error => {
                      if (error.response && error.response.status === 401) {
                        this.$store.dispatch("logout")
                      } else {
                        console.log(error);
                        this.errored = true;
                      }
                    })
              }
          },
          getParties(){
            this.axios
                .get('/api/v1/parties')
                .then(response => (
                    this.parties = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          getRegions(){
            this.axios
                .get('/api/v1/regions')
                .then(response => (
                    this.regions = response.data
                ))
                .catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.$store.dispatch("logout")
                  } else {
                    console.log(error);
                    this.errored = true;
                  }
                })
          },
          wizardComplete(){
            this.$router.push({ path: `/incidents` })
          },
          showDescription(title, text){
            swal.fire({
              title: title,
              icon: 'info',
              html: text,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText:
                  '<i class="fa fa-close"></i> Закрити!',
              confirmButtonAriaLabel: 'Thumbs up, great!',
              cancelButtonText:
                  '<i class="fa fa-thumbs-down"></i>',
              cancelButtonAriaLabel: 'Thumbs down'
            })
          }
        },
        data(){
          return {
              activeName: 0,
              results: [],
              files: [],
              election_type: [],
              provider: new OpenStreetMapProvider({
                  params: {
                      'accept-language': 'ua', // render results in Dutch
                      countrycodes: 'ua', // limit search results to the Netherlands
                      addressdetails: 1, // include additional address detail parts
                  },
              }),
              polices: [],
              police_id: null,
              // address: '',
              incident: {
                  date: null,
                  region_id: null,
                  district_id: null,
                  user_id: this.$store.state.user_id,
                  picked: null,
                  incident_type: null,
                  incident_sub_type: null,
                  description:'',
                  court: false,
                  court_url: '',
                  admin_resource: false,
                  title: '',
                  victim: '',
                  victim_id: null,
                  offender: '',
                  offender_id: null,
                  tk: false,
                  internet: false,
                  added_fix: false
              },
              verefitication_first: null,
              verefitication: null,
              verefiticationLawyer: null,
              val_verificationLawyer: '',
              val_verification_first: '',
              val_verification: '',
              incident_points: [
              ],

              showMap: false,
              report: {comments: [] },
              message: null,

              police: false,
              event_new: false,
              url:'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
              attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
              zoom: 6,
              center: [48.8358, 31.5854],
              //markerLatLng: [this.incident.lat, this.incident.lng],
              // icon: L.icon({
              //     iconUrl: '/images/baseball-marker.png',
              //     iconSize: [32, 37],
              //     iconAnchor: [16, 37]
              // }),
              // staticAnchor: [16, 37],
              // customText: 'Foobar',
              // iconSize: 64,

              button: '',
              showChat: "",
              regions: [],
              parties: [],
              incident_types: [],
              incident_sub_types: [],
              pickerOptions1: {
                  shortcuts: [{
                      text: 'Сьогодні',
                      onClick (picker) {
                          picker.$emit('pick', new Date())
                      }
                  },
                      {
                          text: 'Вчора',
                          onClick (picker) {
                              const date = new Date()
                              date.setTime(date.getTime() - 3600 * 1000 * 24)
                              picker.$emit('pick', date)
                          }
                      },
                      {
                          text: 'Тиждень тому',
                          onClick (picker) {
                              const date = new Date()
                              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                              picker.$emit('pick', date)
                          }
                      }]
              },
              editorConfig: {
                // toolbar: [ [ 'Bold' ] ],
                toolbarGroups:[

                  { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                  { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                  { name: 'forms', groups: [ 'forms' ] },
                  { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                  { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                  { name: 'links', groups: [ 'links' ] },
                  { name: 'insert', groups: [ 'insert' ] },
                  { name: 'styles', groups: [ 'styles' ] },
                  { name: 'colors', groups: [ 'colors' ] },
                  { name: 'tools', groups: [ 'tools' ] },
                  { name: 'others', groups: [ 'others' ] },
                  { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                  { name: 'about', groups: [ 'about' ] }
                ],
                disableNativeSpellChecker: false,
                removePlugins: 'wsc,scayt',
                removeButtons: 'Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Anchor,Format,Styles,About,Blockquote,Indent,Outdent,SpecialChar,/'
                  // The configuration of the editor.
              }

          }
        }
    }
</script>
<style>

</style>