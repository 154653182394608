<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
<!--                <div class="card-header">-->
<!--                    <h4 class="card-title">{{ report.name }}</h4>-->
<!--                </div>-->
                <div class="card-content">
                    <form class="form-horizontal"  @submit.prevent="toSave">
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Номер</label>
                                <div class="col-sm-10">
                                    <input type="number" v-model="answeroption.number" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Назва оції</label>
                                <div class="col-sm-10">
                                    <input type="text" v-model="answeroption.name" class="form-control">
                                </div>
                            </div>
                        </fieldset>

                        <div class="card-footer">
                            <div class="form-group">
                                <div class="col-md-3">
                                    <router-link :to="`/admin/reports/`+ $route.params.report_id +`/chapters/`+ $route.params.chapter_id +`/questions/`+ $route.params.question_id +`/answeroptions`" class="btn btn-default">Назад</router-link>
                                </div>
                                <div class="col-md-9">
                                    <button type="submit" class="btn btn-fill btn-info">
                                        Зберегти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

    export default {
        name: "Answeroption",
        components: {
        },
        created() {
            if (this.$route.params.id){
                this.getAnsweroption(this.$route.params.id)
            }
        },
        methods:{
            getAnsweroption(id){
                this.axios
                    .get("/api/v1/reports/" + this.$route.params.report_id  + "/chapters/" +  this.$route.params.chapter_id +`/questions/`+ this.$route.params.question_id +`/answeroptions/` + id)
                    .then(response => (this.answeroption = response.data))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
                console.log('Call Api')
            },
            toSave(){

                    if (this.$route.params.id) {
                        this.axios
                            .patch('/api/v1/reports/' + this.$route.params.report_id  + '/chapters/' + this.$route.params.id + '/questions/'+ this.$route.params.question_id +`/answeroptions/` + this.$route.params.id  , {answeroption: this.answeroption} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: `/admin/reports/` + this.$route.params.report_id  + '/chapters/' + this.$route.params.chapter_id + `/questions/`+ this.$route.params.question_id +`/answeroptions`})
                            ));


                    } else {
                        this.axios
                            .post('/api/v1/reports/' + this.$route.params.report_id  + '/chapters/' + this.$route.params.id + '/questions/'+ this.$route.params.question_id +`/answeroptions`, {answeroption: this.answeroption} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: `/admin/reports/` + this.$route.params.report_id  + '/chapters/' + this.$route.params.chapter_id + `/questions/`+ this.$route.params.question_id +`/answeroptions`})
                            ));
                    }

            }
        } ,
        data () {
            return {
                answeroption: {
                    name: null,
                    number: null,
                    question_id: this.$route.params.question_id
                },
                loading: true,
                errored: false,
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        }
    }
</script>
<style>

</style>