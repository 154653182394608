import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import router from "../router";

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    client: localStorage.getItem('client') || '',
    uid: localStorage.getItem('uid') || '',
    role : localStorage.getItem('role') || '',
    user_id : localStorage.getItem('user_id') || '',
    admin: localStorage.getItem('admin') || false,
  },
  mutations: {
      auth_request(state){
          state.status = 'loading'
      },
      auth_success(state, item){
          state.status = 'success'
          state.token = item.token
          state.client = item.client
          state.uid = item.uid
          if (!item.admin){
              state.admin = false
          } else {
              state.admin = item.admin
          }
          state.role = item.role
          state.user_id = item.user_id
      },
      auth_error(state){
          state.status = 'error'
      },
      logout(state){
          state.status = ''
          state.token = ''
          state.client = ''
          state.uid = ''
          state.role = ''
          state.user_id = ''
          state.admin = ''
      },
  },
  actions: {
      ResetPassword({commit}, user){
          return new Promise((resolve, reject) => {
              commit('auth_request')
              axios.defaults.headers.common['access-token'] = user.token
              axios.defaults.headers.common['client'] = user.client
              axios.defaults.headers.common['uid'] = user.uid
              axios({url: '/api/v1/auth/password', data: user, method: 'PUT' })
                  .then(resp => {
                      const token = resp.headers["access-token"]
                      const client = resp.headers["client"]
                      const uid = resp.headers["uid"]
                      const user_id = resp.data.data.id
                      const admin = resp.data.data.admin
                      const role = resp.data.data.role
                      localStorage.setItem('token', token)
                      localStorage.setItem('client', client)
                      localStorage.setItem('uid', uid)
                      localStorage.setItem('role', role)
                      localStorage.setItem('admin', admin)
                      localStorage.setItem('user_id', user_id )
                      axios.defaults.headers.common['access-token'] = token
                      axios.defaults.headers.common['client'] = client
                      axios.defaults.headers.common['uid'] = uid
                      commit('auth_success', {token:token, client: client, uid: uid,  role: role, admin: admin, user_id:user_id })
                      resolve(resp)
                  })
                  .catch(err => {
                      commit('auth_error')
                      localStorage.removeItem('token')
                      localStorage.removeItem('client')
                      localStorage.removeItem('uid')
                      localStorage.removeItem('role')
                      localStorage.removeItem('admin')
                      localStorage.removeItem('user_id')
                      reject(err)
                  })
          })
      },
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: '/api/v1/auth/sign_in', data: user, method: 'POST' })
            .then(resp => {
              const token = resp.headers["access-token"]
              const client = resp.headers["client"]
              const uid = resp.headers["uid"]
              const user_id = resp.data.data.id
              const admin = resp.data.data.admin
              const role = resp.data.data.role
              localStorage.setItem('token', token)
              localStorage.setItem('client', client)
              localStorage.setItem('uid', uid)
              localStorage.setItem('role', role)
              localStorage.setItem('admin', admin)
              localStorage.setItem('user_id', user_id )
              axios.defaults.headers.common['access-token'] = token
              axios.defaults.headers.common['client'] = client
              axios.defaults.headers.common['uid'] = uid
              commit('auth_success', {token:token, client: client, uid: uid,  role: role, admin: admin, user_id:user_id })
              resolve(resp)
            })
            .catch(err => {
              commit('auth_error')
                localStorage.removeItem('token')
                localStorage.removeItem('client')
                localStorage.removeItem('uid')
                localStorage.removeItem('role')
                localStorage.removeItem('admin')
                localStorage.removeItem('user_id')
              reject(err)
            })
      })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
          localStorage.removeItem('client')
          localStorage.removeItem('uid')
        localStorage.removeItem('role')
        localStorage.removeItem('admin')
        localStorage.removeItem('user_id')
        delete axios.defaults.headers.common['access-token']
        delete axios.defaults.headers.common['client']
        delete axios.defaults.headers.common['uid']
        // router.push('/')
        resolve()
      })
    }
  },
  modules: {
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  }
})
