<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
<!--                <div class="card-header">-->
<!--                    <h4 class="card-title">{{ report.name }}</h4>-->
<!--                </div>-->
                <div class="card-content">
                    <form class="form-horizontal"  @submit.prevent="toSave">
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Номер запитання</label>
                                <div class="col-sm-10">
                                    <input type="number" v-model="question.number" class="form-control">
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Запиттання</label>
                                <div class="col-sm-10">
                                    <ckeditor v-model="question.question" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Описовий текст</label>
                                <div class="col-sm-10">
                                    <ckeditor v-model="question.answer_text" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Тип питання</label>
                                <div class="col-sm-6">
                                    <el-select class="select-primary"
                                                size="large"
                                                placeholder="Виберіть тип питання"
                                                v-model="question.type_field">
                                    <el-option v-for="option in selects.type"
                                               class="select-danger"
                                               :value="option.value"
                                               :label="option.label"
                                               :key="option.value">
                                    </el-option>
                                </el-select>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">Номероване питання</label>
                                <div class="col-sm-10">
                                    <p class="category"> </p>
                                    <p-switch v-model="question.number_option" type="primary" on-text="Так" off-text="Ні"></p-switch>
                                </div>
                            </div>
                        </fieldset>
                        <div class="card-footer">
                            <div class="form-group">
                                <div class="col-md-3">
                                    <router-link :to="'/admin/reports/'+ this.$route.params.report_id + '/chapters/'+ this.$route.params.chapter_id + '/questions'" class="btn btn-default">Назад</router-link>
                                </div>
                                <div class="col-md-9">
                                    <button type="submit" class="btn btn-fill btn-info">
                                        Зберегти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import PSwitch from '../../../../../components/UIComponents/Switch.vue'
    import {Select, Option} from 'element-ui'
    export default {
        name: "Question",
        components: {
            PSwitch,
            [Option.name]: Option,
            [Select.name]: Select,
        },
        created() {
            if (this.$route.params.id){
                this.getQuestion(this.$route.params.id)
            }
        },
        methods:{
            getQuestion(id){
                this.axios
                    .get('/api/v1/reports/'+ this.$route.params.report_id + "/chapters/"+ this.$route.params.chapter_id +"/questions/" + id )
                    .then(response => (this.question = response.data))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch("logout")
                        } else {
                            console.log(error);
                            this.errored = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            toSave(){

                    if (this.$route.params.id) {
                        this.axios
                            .patch('/api/v1/reports/'+ this.$route.params.report_id + "/chapters/"+ this.$route.params.chapter_id +"/questions/" + this.$route.params.id , {question: this.question} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: '/admin/reports/'+ this.$route.params.report_id + "/chapters/"+ this.$route.params.chapter_id +"/questions" })
                            ));


                    } else {
                        this.axios
                            .post('/api/v1/reports/'+ this.$route.params.report_id + "/chapters/"+ this.$route.params.chapter_id +"/questions", {question: this.question} )
                            // .then(response => ( return null))
                            .catch(error => {
                                if (error.response && error.response.status === 401) {
                                    this.$store.dispatch("logout")
                                } else {
                                    console.log(error);
                                    this.errored = true;
                                }
                            })
                            .finally(() => (
                                this.$router.push({ path: '/admin/reports/'+ this.$route.params.report_id + "/chapters/"+ this.$route.params.chapter_id +"/questions" })
                            ));
                    }

            }
        } ,
        data () {
            return {
                selects: {
                    type: [
                       // [['Numeric', 0], ['String', 1], ['Text', 2], ['Date', 3], ['Selects', 4], ['Radio-buttons', 5],
                        // ['Check-boxes', 6], ['Regions', 7], ['OVK', 8], ['OVKs', 9], ['Description', 10], ['Select-nested', 11],  ['Table', 12], ['Party', 13]]
                        {value: 0, label: 'Цифра'},
                        {value: 1, label: 'Строка'},
                        {value: 2, label: 'Техт'},
                        {value: 3, label: 'Дата'},
                        {value: 4, label: 'Список'},
                        {value: 5, label: 'Радіо-Баттон'},
                        {value: 6, label: 'Чек Бокси'},
                        {value: 7, label: 'Регіон'},
                        {value: 8, label: 'ТВК'},
                        {value: 9, label: 'ОВК-декілька'},
                        {value: 10, label: 'Description'},
                        {value: 11, label: 'Список-декілька'},
                        {value: 12, label: 'Таблиця'},
                        {value: 13, label: 'Партії'},
                        {value: 14, label: 'Період з - до'},
                        {value: 15, label: 'Район'}
                    ]
                },
                question: {
                    question: null,
                    number: null,
                    type_field: null,
                    answer_text: null,
                    coordinator: false,
                    number_option: true,
                    chapter_id: this.$route.params.chapter_id,
                    report_id:  this.$route.params.report_id
                },
                loading: true,
                errored: false,
                editorConfig: {
                    // The configuration of the editor.
                }
            }
        }
    }
</script>
<style>

</style>