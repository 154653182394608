<template>
    <div class="col-md-12 well">
      <button class="btn btn-danger" v-if="$route.params.id && ($store.state.admin || $store.state.role==='Regional' || $store.state.role==='lawyer')" @click="removeEvent()">
         Видалити</button>
        <div class="card card-wizard" id="wizardCard">
            <form-wizard
                    shape="square"
                    stepSize="xs"
                    stepsClasses="center"
                    @on-complete="wizardComplete"
                    @on-change="scrollToTop()"
                    id="event"
                    title=""
                    :start-index="0"
                    subtitle=""
                    centered
            >
                <tab-content title="Область/Тип" :before-change="checkDateBeforeTabSwitch">
                    <div class="col-md-5 col-md-offset-4">
                        <div class="card form-horizontal">
                            <div class="card-content">
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Дата</label>
                                    <el-date-picker :disabled="disableEdit()" type="date" placeholder="Виберіть дату"
                                                    :picker-options="pickerOptions1" v-model="event.date"
                                                    @change="changeValue()">
                                    </el-date-picker>
                                </div>
                              <div class="form-group">
                                <label class="col-sm-2 control-label">Тип Виборів</label>
                                <el-select class="select-default"
                                           size="large"
                                           clearable
                                           placeholder="Single Select"
                                           v-model="event.election_type_id"
                                           @change="changeValue()"
                                >
                                  <el-option v-for="option in election_type"
                                             class="select-default"
                                             :value="option.id"
                                             :label="option.name"
                                             :key="option.id">
                                  </el-option>
                                </el-select>
                              </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Область</label>
                                    <el-select class="select-default"
                                               :disabled="disableEdit()"
                                               size="large"
                                               placeholder="Single Select"
                                               v-model="event.region_id"  @change="changeValue()"
                                    >
                                        <el-option v-for="option in regions"
                                                   class="select-default"
                                                   :value="option.id"
                                                   :label="option.name"
                                                   :key="option.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-10 col-sm-offset-2">
                        <div class="card form-horizontal">
                            <div class="card-header">
                                <h4 class="card-title">Район</h4>
                            </div>
                            <div class="card-content">
                                <div class="well"  v-if="event.region_id && regions.length > 0" >
                                    <label class="btn btn-wd" :class="event.district_id===d.id ? 'active' : ''" v-for="(d, i) in regions[regions.findIndex(r => r.id === event.region_id )].districts" :key="d.id">
                                        <input :disabled="disableEdit()" type="radio" :id="`option` + i" :value="d.id" v-model="event.district_id"  @change="changeValue()" autocomplete="off"> {{d.name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-10 col-sm-offset-2">
                        <div class="card form-horizontal">
                            <div class="card-header">
                                <h4 class="card-title">Тип</h4>
                            </div>
                            <div class="card-content">
                                <div class="well">
                                  <ul class="list-unstyled">
                                    <li v-for="(d, i) in event_types" :key="d.id">
                                      <label class="btn btn-wd" :class="event.event_type===d.id ? 'active' : ''" >
                                        <input :disabled="disableEdit()" type="radio" :id="`option` + i" :value="d.id" v-model="event.event_type"  @change="changeValue()" autocomplete="off"> {{d.name}}
                                      </label>
                                      <button class="btn btn-icon btn-info" @click="showDescription(d.name, d.description)"><i class="ti-help"></i></button>
                                    </li>
                                  </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </tab-content>
                <tab-content title="Опис" :before-change="beforeTabSwitch">
                  <div class="well">
                    <h3>Вимоги до опису події</h3>
                    <ol>
                      <li>
                        Вказувати повні назви населених пунктів та назви органів влади, підприємницьких організацій, місцевих організацій партій, ЗМІ інших організацій та установ;
                      </li>
                      <li>
                        Чітко вказувати прізвище та ім’я усіх фігурантів подій, а також зазначити повні назви їхніх посад, партійної приналежності чи інших офіційний статусів особи;
                      </li>
                      <li>
                        Вказувати дати усіх етапів розвитку ситуації, які згадуються під час внесення події у форму;
                      </li>
                    </ol>
                  </div>
                  <div class="card-content">
                        <label class="control-label">Опис:</label> <textarea :disabled="disableEdit()" style="resize: 'yes'"   class="form-control" v-model="event.description"  @change="changeValue()" ></textarea>
                    </div>
                    <div class="card-content">
                        <label class="control-label">Джерело (посилання)</label> <input type="text" :disabled="disableEdit()"  v-model="event.source"  @change="changeValue()" class="form-control">
                    </div>
                    <div class="card-content">
                        <div class="well">
                            <label class="btn btn-wd" :class="event.picked===1 ? 'active' : '' " >
                                <input type="radio" :disabled="disableEdit()"  value=1 id="option1" autocomplete="off"  v-model.number="event.picked"  @change="changeValue()"> Спостерігач
                            </label>
                            <label class="btn btn-wd" :class="event.picked===2 ? 'active' : '' ">
                                <input type="radio" value=2 id="option2" autocomplete="off" v-model.number="event.picked"  @change="changeValue()" > Інет ЗМІ
                            </label>
                            <label class="btn btn-wd" :class="event.picked===3 ? 'active' : '' " >
                                <input type="radio" :disabled="disableEdit()"  value=3 id="option3" autocomplete="off"  v-model.number="event.picked"  @change="changeValue()" > FB
                            </label>
                            <label class="btn btn-wd" :class="event.picked===4 ? 'active' : '' ">
                                <input type="radio" :disabled="disableEdit()"  value=4 id="option4" autocomplete="off" v-model.number="event.picked"  @change="changeValue()" > YouTube
                            </label>
                            <label class="btn btn-wd" :class="event.picked===5 ? 'active' : '' " >
                                <input type="radio" :disabled="disableEdit()"  value=5 id="option5" autocomplete="off"  v-model.number="event.picked"  @change="changeValue()"> Telegram
                            </label>
                            <label class="btn btn-wd" :class="event.picked===6 ? 'active' : '' ">
                                <input type="radio" :disabled="disableEdit()"  value=6 id="option6" autocomplete="off" v-model.number="event.picked"  @change="changeValue()" > Instagram
                            </label>
                            <label class="btn btn-wd" :class="event.picked===7 ? 'active' : '' ">
                                <input type="radio" :disabled="disableEdit()"  value=7 id="option7" autocomplete="off" v-model.number="event.picked"  @change="changeValue()" > VK
                            </label>
                          <label class="btn btn-wd" :class="event.picked===8 ? 'active' : '' ">
                            <input type="radio" :disabled="disableEdit()"  value=8 id="option8" autocomplete="off" v-model.number="event.picked"  @change="changeValue()" > традиційні ЗМІ
                          </label>
                        </div>
                    </div>
                </tab-content>
                <tab-content title="Файл/Адреса">
                    <div class="col-sm-2"></div>
                    <div class="card-content">
                        <label class="control-label">Адреса</label> <input type="text" :disabled="disableEdit()"  v-model="event.address"  @change="changeValue()"  class="form-control">
                    </div>
                  <fieldset v-if="event.event_files">
                    <div class="form-group" >
                      <div class="well" v-if="event.event_files.length > 0">

                        <div class="demo-image__preview">
                          <div class="col-sm-3 well" v-for="image in event.event_files" :key="image.id">
                          <el-image v-if="image.file.url.split('.').pop().toLowerCase()==='jpg' ||
                                          image.file.url.split('.').pop().toLowerCase()==='png' ||
                                          image.file.url.split('.').pop().toLowerCase()==='jpeg' ||
                                          image.file.url.split('.').pop().toLowerCase()==='tiff' ||
                                          image.file.url.split('.').pop().toLowerCase()==='bmp' ||
                                          image.file.url.split('.').pop().toLowerCase()==='gif' ||
                                          image.file.url.split('.').pop().toLowerCase()==='webp' ||
                                          image.file.url.split('.').pop().toLowerCase()==='jfif'"
                                    style="width: 100px; height: 100px"
                                    :src="'..' + image.file.url"
                                    :preview-src-list="urls"
                                    >
                          </el-image>
                            <pre>{{image.text_photo}}</pre>
                            <p><a :href="'..'+image.file.url" target="_blank">Переглянути</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div class="well">
                    <div class="card-content">

                      <div class="el-upload-list el-upload-list--text">
                        <div class="row">
                          <div v-for="(file, key) in files" :key="key" class="col-md-5"><i class="el-icon-document"></i> {{ file.name }}
                            <button class="btn btn-danger btn-simple" v-on:click="removeFile( key )"><i class="el-icon ti-trash"></i></button>
                          </div>
                        </div>
                      </div>
                      <label class="btn btn-success btn-fill btn-wd"> <i class="ti-upload">Вибрати файли</i>
                        <input type="file" id="event_files" ref="event_files" multiple v-on:change="handleFilesUpload()"/>
                      </label>
                      <br>
                      <progress v-if="file_upload" max="100" :value.prop="uploadPercentage"></progress>
                      <br>
                      <button class="btn btn-primary btn-fill btn-wd" v-if="files.length" v-on:click="submitFiles()"><i class="ti-save"> Завантажити</i></button>
                    </div>
                  </div>
                    <fieldset v-if="$route.path.match('/eventreports/')">
                        <div class="form-group">

                            <button @click="incident_new=!incident_new" class="btn btn-warning btn-fill btn-wd">{{ event.event_incident ? "Порушення" : "Створити Порушення" }}</button>
                            <Incident v-if="incident_new" @closeIncident="incident_new=!incident_new"
                                       :dateEvent="event.date" :regionEvent="event.region_id"
                                       :districtEvent="event.district_id" :idEvent="event.id" :id-incident="event.event_incident ? event.event_incident.incident_id : null">
                            </Incident>
                        </div>
                    </fieldset>

                </tab-content>
                <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Назад</button>
                <button slot="next" class="btn btn-info btn-fill btn-wd btn-next">Далі</button>
                <button slot="finish" class="btn btn-info btn-fill btn-wd">Готово</button>
            </form-wizard>
        </div>
      <div class="col-md-5 col-md-offset-2" v-if="$route.params.id && $route.path.match('/eventreports/') ">
        <el-collapse class="panel-group" v-model="showChat">
          <el-collapse-item title="Коментарі"  name="chat"  >
            <div class="card card-chat ">
              <div class="card-header">
                <!--                            <h4 class="card-title">Коментарі</h4>-->
                <!--                            <p class="category">відносно звіту</p>-->
              </div>
              <div class="card-content">
                <ol class="chat">
                  <li :class="comment.user_id === $store.state.user_id ? 'self' : 'other'" v-for="comment in report.comments" :key="comment.id">
                    <div class="avatar">
                      <img v-if="comment.user_id === $store.state.user_id"  src="/static/img/default-avatar.png" alt="crash"/>
                      <img v-else src="/static/img/faces/face-2.jpg" alt="crash"/>
                    </div>
                    <div class="msg">
                      <p>
                        {{ comment.message }}
                      </p>
                      <div class="card-footer">
                        <i class="ti-check"></i>
                        <h6>{{ comment.created_at | formatDate }}</h6>
                      </div>
                    </div>
                  </li>
                </ol>
                <hr>
                <form  @submit.prevent="toSend">
                  <div class="send-message">
                    <div class="avatar">
                      <img src="/static/img/default-avatar.png" alt="crash"/>
                    </div>

                    <input v-model.trim="message" class="form-control textarea" type="text" placeholder="Друкуй тут!"/>
                    <div class="send-button">
                      <button class="btn btn-xs btn-primary btn-fill">Зберегти</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>

      </div>
    </div>

</template>
<script>
import Vue from 'vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import {DatePicker, Select, Option, Collapse, CollapseItem, Image} from 'element-ui'
//import event_types from "./event_types"
import Incident from "./Incident";


Vue.use(Collapse)
Vue.use(CollapseItem)

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import swal from "sweetalert2";
import moment from "moment";
const swalWithBootstrapButtons = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
})
export default {
name: 'Event',
components: {
// LMap,
// LTileLayer,
// LMarker,
//  LIcon,

FormWizard,
TabContent,
[DatePicker.name]: DatePicker,
[Option.name]: Option,
[Select.name]: Select,
[Image.name]: Image,
  Incident


//PSwitch
},
created() {

  this.getEvent()
  this.getComments()
},
mounted() {
  this.getRegions()
  this.getEventTypes()
  this.getElect()
  this.$root.$once('createIncident',  ()=>(
      this.getEvent()
  ))
},
  filters: {

    formatDate(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY hh:mm')
      }
    }
  },
  computed: {
    event_new_id(){
      return this.event.id
    },
    files_event(){
      return this.event.event_files
    }

  },
watch: {
   event_new_id: 'createEventIncident',
   regions: 'setRegions',
   files_event: 'loadUrl'
},
methods: {
  getElect(){
    this.axios
        .get("/api/v1/election_types/" )
        .then(response => (this.election_type = response.data))
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.$store.dispatch("logout")
          } else {
            console.log(error);
            this.errored = true;
          }
        })
        .finally(() => (this.loading = false));
  },
  scrollToTop(){
    document.getElementById('event').scrollIntoView();
  },
  removeEvent(){
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Ви впевнені?',
      text: "Ви хочете видалити подію?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Так!',
      cancelButtonText: 'Ні, відміна!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.axios
            .delete('/api/v1/events/' +  this.$route.params.id )
            .catch(error => {
              if (error.response && error.response.status === 401) {
                this.$store.dispatch("logout")
              } else {
                console.log(error);
                this.errored = true;
              }
            })
            .finally(() => (
                swalWithBootstrapButtons.fire(
                    'Видалено!',
                    'Дія виконана успішно.',
                    'success'
                )

            ));
      } else if (
          /* Read more about handling dismissals below */
          result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
            'Відміна',
            'збережено :)',
            'error'
        )
      }
    })
  },
  disableEdit(){
    if (this.event.user_id && this.$store.state.role==="observ" ){
      if ( Number(this.event.user_id) !== Number(this.$store.state.user_id) ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
  getEventTypes(){
    this.axios
        .get('/api/v1/get_event_types')
        .then(response => (
            this.event_types = response.data
        ))
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.$store.dispatch("logout")
          } else {
            console.log(error);
            this.errored = true;
          }
        })
  },
  loadUrl(){
    let eventFiles = this.event.event_files;
    for( var i = 0; i < eventFiles.length; i++ ){
      this.urls.push( eventFiles[i].file.url );
    }
  },
  setRegions(){
    if (this.regions.length===1){
      this.event.region_id = this.regions[0].id
    }
  },
  createEventIncident(){
    if (this.idIncident && ! this.idEvent) {
      this.axios
          .post('/api/v1/event_incidents', {event_incident: {event_id: this.event.id, incident_id: this.idIncident, user_id: this.$store.state.user_id}})
           .then(response => (
                   this.$root.$emit('createEvent', response.data.event_id)
          //     this.idEvent = response.data.event_id
          //     //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
          //     //Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
           ))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
    }
  },
  handleFilesUpload(){
    let uploadedFiles = this.$refs.event_files.files;
    for( var i = 0; i < uploadedFiles.length; i++ ){
      this.files.push( uploadedFiles[i] );
    }  },
  removeFile( key ){
    this.files.splice( key, 1 );
  },
  submitFiles(){
    let formData = new FormData();
    //formData.append('incident_point[incident_id]',  this.incident.id);
    this.file_upload = true
    for( var i = 0; i <  this.files.length; i++ ){
      let file =  this.files[i];
      let fileExtension = file.name.split('.').pop()
      formData.append('event[event_files_attributes[' + i + '][file]]', file, 'event_' + Date.now() + '.' + fileExtension );
    }
    this.axios.patch( '/api/v1/events/' + this.event.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))
          }.bind(this)
        }
    ).then(response => (
        this.event = response.data
    )).then(()=> (this.files = []))
        .catch(function(){
          console.log('FAILURE!!');
        })
    .finally(() => (this.uploadPercentage=0, this.file_upload = false))
  },
  getEvent(){
    let event_id;
    if (this.$route.path.match('/eventreports/')) {
      event_id = this.$route.params.id
    } else {
      event_id = this.idEvent
    }

    if (event_id) {
      this.axios
          .get('/api/v1/events/' + event_id )
          .then(response => (
              this.event = response.data
          ))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
    }
  },
  changeValue(){
    let event_id;
    if (this.$route.path.match('/eventreports/')) {
      event_id = this.$route.params.id
    } else {
      event_id = this.event.id || this.idEvent
    }
    if (event_id) {
      this.axios
          .patch('/api/v1/events/' + event_id  , { event: this.event})
          // .then(response => (
          //     console.log(response)
              //     //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
              //     Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value })
          // ))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
    } else {
      if (this.$route.path.match('/eventreports/')) {
        this.axios
            .post('/api/v1/events', {event: this.event})
            .then(response => (
                this.event = response.data
                //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                //Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
            ))
            .catch(error => {
              if (error.response && error.response.status === 401) {
                this.$store.dispatch("logout")
              } else {
                console.log(error);
                this.errored = true;
              }
            })
            .finally(() => (this.$router.push({path: '/eventreports/' + this.event.id})))
      } else {
        this.axios
            .post('/api/v1/events', {event: this.event})
            .then(response => (
                this.event = response.data
                //this.answers.splice(this.answers.findIndex(a => a.question_id === item.question_id), 1, response.data)
                //Vue.set(this.answers, this.answers.findIndex(a => a.question_id === item.question_id), {id: response.data.id, question_id: item.question_id, value: item.value }  )
            ))
            .catch(error => {
              if (error.response && error.response.status === 401) {
                this.$store.dispatch("logout")
              } else {
                console.log(error);
                this.errored = true;
              }
            })
      }
    }
  },
  beforeTabSwitch(){
    if (this.event.id){
      return true
    } else {
      swalWithBootstrapButtons.fire(
          'Помилка',
          'Для переходу до останього кроку треба заповнити поля з 1 та 2 кроку',
          'error'
      )
      return false
    }
  },
  checkDateBeforeTabSwitch(){
    if (this.event.date){
      return true
    } else {
      swalWithBootstrapButtons.fire(
          'Помилка',
          'Заповніть будь ласка коректно Дату',
          'error'
      )
      return false
    }
  },
  toSend(){
    this.axios
        .post('/api/v1/comment_events', {comment_event: {event_id: this.$route.params.id, user_id: this.$store.state.user_id, message: this.message}} )
        .then(response => ( this.report.comments.push(response.data) ))
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.$store.dispatch("logout")
          } else {
            console.log(error);
            this.errored = true;
          }
        })
        .finally(() => (
            this.message = null)
        );
  },
  getComments(){
    if ( this.$route.params.id )
    {
      this.axios
          .get('/api/v1/comment_events?event_id=' +  this.$route.params.id  )
          .then(response => ( this.report.comments = response.data,
              this.report.comments.length > 0 ? this.showChat = "chat" : ""))
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("logout")
            } else {
              console.log(error);
              this.errored = true;
            }
          })
    }
  },
  getRegions(){
    this.axios
        .get('/api/v1/regions')
        .then(response => (
            this.regions = response.data
        ))
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.$store.dispatch("logout")
          } else {
            console.log(error);
            this.errored = true;
          }
        })
  },
  wizardComplete(){
    if (this.$route.path.match('/eventreports/')) {
      this.$router.push({ path: `/eventreports` })
    } else {
     this.$emit('closeEvent')
    }
  },
  showDescription(title, text){
    swal.fire({
      title: title,
      icon: 'info',
      html: text,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:
          '<i class="fa fa-close"></i> Закрити!',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down'
    })
  }

},
  props: [
    'dateIncident', 'regionIncident', 'districtIncident', 'idIncident', 'idEvent'
  ],
data(){
return {
  showChat: "",
  incident_new: false,
  files: [],
  file_upload: false,
  uploadPercentage: 0,
  urls: [],
  election_type: [],
  event: {
    date: this.dateIncident || null,
    region_id: this.regionIncident || null,
    district_id: this.districtIncident || null,
    description: null,
    source: null,
    picked: null,
    event_type: null,
    address: null,
    user_id: this.$store.state.user_id
  },
  report: {comments: [] },
  message: null,
event_types: [],


button: '',
regions: [],
pickerOptions1: {
shortcuts: [{
text: 'Сьогодні',
onClick (picker) {
picker.$emit('pick', new Date())
}
},
{
text: 'Вчора',
onClick (picker) {
const date = new Date()
date.setTime(date.getTime() - 3600 * 1000 * 24)
picker.$emit('pick', date)
}
},
{
text: 'Тиждень тому',
onClick (picker) {
const date = new Date()
date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
picker.$emit('pick', date)
}
}]
},
editorConfig: {
// The configuration of the editor.
}

}
}
}
</script>
<style>


 textarea.form-control{
   resize: vertical!important;
 }
</style>